<template>
	<div style="background-color: #fff;padding: 20px;
				background: #fff;
				width: 1000px;">
		<div v-if="slected==0">
			<div style="color: #333333;font-size: 24px;padding-left: 10px;border-left: 4px solid #FF6A00;">
				维护发票
			</div>
			<!-- 	<el-button round @click="add"
				style=" background-color:#FF6A00;margin-top: 20px;margin-bottom: 40px;color: #fff;">添加发票</el-button> -->
			<div class="btn1" style="width: 120px;margin-top: 20px;margin-bottom: 40px" @click="add">添加发票</div>
			<div>
				<div class="list">
					<div class="listitem" v-for="(item,index) in list" :key="index">
						<div class="top">
							<div v-if="item.header_type==2" class="toptitle">
								抬头类型：个人/非企业单位
							</div>
							<div v-if="item.header_type==1" class="toptitle">
								抬头类型：企业单位
							</div>
							<div>
								<!-- <el-icon color="#FF6A00" class="no-inherit"> -->
								<el-icon color="#FF6A00" @click="edit(item)" style="margin-right: 20px;">
									<EditPen />
								</el-icon>
								<el-icon @click="dele(item)" color="#FF6A00">
									<Delete />
								</el-icon>
								<!-- </el-icon> -->
								<!-- <i style="color: #FF6A00;" class="el-icon-delete"></i> -->
							</div>
						</div>
						<div class="desc">
							发票名称：{{item.name}}
						</div>
						<div class="desc">
							税号：{{item.code}}
						</div>
						<div class="desc">
							电话号：{{item.mobile}}
						</div>
						<div class="desc">
							企业地址：{{item.address}}
						</div>
						<div class="desc">
							开户银行：{{item.bank_name}}
						</div>
						<div class="desc">
							银行账号：{{item.bank_account}}
						</div>

						<div class="desc">
							是否默认：
							<el-switch :active-value="1" :inactive-value="2" v-model="item.is_default"
								active-color="#EBEBEB" inactive-color="#FF6A00" @change="changefapiao(item)">
							</el-switch>
						</div>
					</div>

				</div>
			</div>
		</div>
		<myinvoiceEdit :invoiceid='invoiceid' v-if="slected==1"></myinvoiceEdit>
	</div>
</template>

<script>
	import myinvoiceEdit from '@/components/myinvoiceEdit'

	import {
		invoiceDetailLoce,
		invoiceList,
		invoiceDel,
		invoiceAddEdit
	} from "@/utils/Myapi.js"
	import {
		ElMessage,
		ElMessageBox
	} from 'element-plus'
	export default {
		// 组件名
		name: 'heAd',
		// 注册组件
		components: {
			myinvoiceEdit
		},
		// 接收父组件数据
		props: {},
		// 组件数据
		data() {
			return {
				slected: 0,
				activeName: '',
				FullShow: true,
				dialogVisible: false,
				status: '',
				lang: '',
				invoiceid: '',
				input3: "",
				titles: [],
				list: [],
				form: {}
			}
		},
		// 计算属性login
		computed: {},
		// 监听属性
		watch: {},
		// 生命周期钩子函数
		created() {


		},
		mounted() {
			this.getdata()
		},
		// 组件方法
		methods: {
			changefapiao(item) {
				if (item.is_default == 1) {

					item.is_default = 1
					console.log("item=====111")
				} else {
					console.log("item=====222")
					item.is_default = 2

				}
				console.log('zi1111111');
				this.form = item;

				invoiceAddEdit(this.form).then(res => {
					if (res.code == 200) {
						ElMessage({
							message: '操作成功',
							type: 'success',
						})
						this.getdata()
					} else {
						ElMessage({
							message: res.message,
							type: 'error',
						})
					}
				})
			},
			dele(item) {
				console.log(11111111)
				ElMessageBox.confirm(
						'您确定要删除发票', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							confirmButtonClass: 'btnConfirm',
							center: true,
						}
					)
					.then(() => {
						this.invoiceDelclick(item.id)
					})
					.catch(() => {})
			},
			invoiceDelclick(id) {

				invoiceDel({
					'id': id
				}).then(res => {
					if (res.code == 200) {
						ElMessage({
							message: '删除成功',
							type: 'success',
						})
						this.getdata()
					}
				})

			},
			fatherMethod() {
				this.slected = 0
				console.log('父组件方法');
				this.getdata()
			},
			goback() {
				this.$parent.mychange();
			},
			getdata() {
				invoiceList().then(res => {
					if (res.code == 200) {
						this.list = res.data.data
					}
				})
			},
			add() {
				this.invoiceid = ''
				this.slected = 1
			},
			edit(data) {
				this.invoiceid = data.id
				this.slected = 1
			},
			handleClick(index) {
				for (let var1 in this.titles) {
					this.titles[var1].ishow = false
				}
				this.titles[index].ishow = true

				switch (index) {}
			},
		},
	}
</script>
<style lang='scss'>
	.btnConfirm {
		background: #FF6A00 !important;
		color: #fff !important;
		border: 1px solid #FF6A00 !important;
	}

	.btnCancel {
		background: #009BF4 !important;
		color: #FFFFFF !important;
	}

	.el-button:first-child:hover {
		color: #333 !important;
		background-color: #fff !important;
		border-color: #999 !important;
	}

	.el-button:nth-child(2):hover {
		color: #FFFFFF !important;
		border-color: #FF6A00 !important;
		background-color: #FF6A00 !important;
	}
</style>
<style scoped lang="scss">
	.list {
		display: flex;
		flex-wrap: wrap;

		// flex-warp:
		.listitem {
			width: 400px;
			padding: 20px;
			height: 330px;
			border: 1px solid #EEEEEE;
			margin-right: 20px;
			margin-top: 20px;

			.top {
				display: flex;
				justify-content: space-between;

				.toptitle {
					color: #333333;
					font-size: 16px;
				}
			}

			.desc {
				margin-top: 20px;

				color: #333333;
				font-size: 16px;
			}
		}
	}

	.btn1 {
		width: 92px;
		height: 42px;
		border-radius: 26px;
		color: #fff;
		background-color: #FF6A00;
		text-align: center;
		line-height: 42px;
		margin-right: 20px;
	}
</style>