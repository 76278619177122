<template>
	<div style="background-color: #fff;padding: 20px;
				background: #fff;
				width: 1000px;">
		<div v-show="slected==0">
			<div style="display: flex;justify-content: space-between;">

				<div style="color: #333333;font-size: 24px;padding-left: 10px;border-left: 4px solid #FF6A00;">
					商城订单
				</div>
				<div style="display: flex;">
					<div class="btn" @click="goback">
						返回
					</div>
				</div>

			</div>

			<div class="ordertype">
				<div class="top">
					<img class="icon" src="../assets/my/ordertype.png" alt="" />
					<!-- 1.待付款 2.待审核（支付审核） 3.代发货 4.待收货 5.退款（售后）6.已完成 7.已取消（已关闭） -->
					<div v-show="goodinfo.my_order_status==1" class="typename">待支付</div>
					<div v-show="goodinfo.my_order_status==2" class="typename">付款待审核</div>
					<div v-show="goodinfo.my_order_status==3" class="typename">待发货</div>
					<div v-show="goodinfo.my_order_status==4" class="typename">待收货</div>
					<div v-show="goodinfo.my_order_status==5" class="typename">退款详情</div>
					<div v-show="goodinfo.my_order_status==6" class="typename">已完成</div>
					<div v-show="goodinfo.my_order_status==7" class="typename">售后中</div>

				</div>
				<div class="roomitem">
					<div v-show="goodinfo.my_order_status==1" class="roomdesc"
						style="display: flex;align-items: center;">
						该订单尚未支付，请您尽快完成支付
					</div>
					<div v-show="goodinfo.my_order_status==2" class="roomdesc"
						style="display: flex;align-items: center;">
						请耐心等待管理员审核
					</div>
					<div v-show="goodinfo.my_order_status==3" class="roomdesc"
						style="display: flex;align-items: center;">
						等待卖家发货
					</div>
					<div v-show="goodinfo.my_order_status==4" class="roomdesc"
						style="display: flex;align-items: center;">
						请收到货后，记得确认收货
					</div>
					<div v-show="goodinfo.my_order_status==6" class="roomdesc"
						style="display: flex;align-items: center;">
						交易成功，快去评价一下吧
					</div>
					<div v-show="goodinfo.my_order_status==5" class="roomdesc"
						style="display: flex;align-items: center;">

					</div>
					<div v-show="goodinfo.my_order_status==7" class="roomdesc"
						style="display: flex;align-items: center;">

					</div>
					<div class="roomdesc" style="display: flex;align-items: center;">
						<div class="roomdesctitle">下单时间：</div>
						<div class="roomdesccon">{{goodinfo.create_time}}</div>
					</div>
					<div class="roomdesc" style="display: flex;align-items: center;">
						<div class="roomdesctitle">支付积分：</div>
						<div class="roomdesccon" style="display: flex;align-items: center;">
							<img class="jifen" src="../assets/my/jifen.png" alt="" />
							<div style="color: #FF6A00;">{{goodinfo.point}}</div>
						</div>
					</div>
					<div class="roomdesc" style="display: flex;align-items: center;">
						<div class="roomdesctitle">支付金额：</div>
						<div class="roomdesccon" style="color: #FF6A00;font-size: 24px;">
							<span style="font-size: 14px;">￥</span>{{goodinfo.price}}
						</div>
					</div>
					<div class="roomdesc" style="display: flex;align-items: center;">
						<div class="roomdesctitle">订单编号：</div>
						<div class="roomdesccon">{{goodinfo.orderno}}</div>
					</div>
					<div v-if="goodinfo.my_order_status>1" class="roomdesc" style="display: flex;align-items: center;">
						<div class="roomdesctitle">支付时间：</div>
						<div class="roomdesccon">{{goodinfo.pay_time_str}}</div>
					</div>
				</div>

				<div style="display: flex;justify-content: space-between;">
					<div>

					</div>
					<div style="display: flex;">
						<!-- 1.待付款 2.待审核（支付审核） 3.代发货 4.待收货 5.退款（售后）6.已完成 7.已取消（已关闭） -->
						<div @click="chanelOrder" v-show="goodinfo.my_order_status==1||goodinfo.my_order_status==3"
							class="btn" style="margin-right: 20px;">取消订单</div>
						<!-- <div class="btn1" style="margin-right: 20px;">去支付</div> -->
						<div v-show="goodinfo.pay_status==2&&goodinfo.my_order_status==1" class="btn"
							style="margin-right: 20px;">凭证详情</div>
						<div v-show="goodinfo.my_order_status==4" class="btn" style="margin-right: 20px;" @click="look">
							查看物流
						</div>

						<div v-show="goodinfo.my_order_status==6&&goodinfo.is_evaluate==0" class="btn"
							style="margin-right: 20px;" @click="pingjia">
							评价</div>

						<div v-show="goodinfo.my_order_status==6" class="btn" style="margin-right: 20px;"
							@click="tuikuang">
							退款</div>

						<div v-show="goodinfo.my_order_status==7" class="btn" style="margin-right: 20px;">删除</div>

						<div v-show="goodinfo.my_order_status==5" class="btn" style="margin-right: 20px;"
							@click="lishi">
							协商历史</div>

						<div v-show="goodinfo.my_order_status==5" class="btn" style="margin-right: 20px;"
							@click="addwuliu">
							填写物流单号</div>



						<div @click="revokeRefundone" v-show="goodinfo.my_order_status==5" class="btn"
							style="margin-right: 20px;">撤销</div>

						<div v-show="goodinfo.my_order_status==4" class="btn" style="margin-right: 20px;"
							@click="shouhuo">
							确认收货</div>

						<div v-show="goodinfo.my_order_status==1" @click="payclick" class="btn1">去支付</div>
					</div>
				</div>
			</div>
			<div class="info">商品信息</div>

			<div class="goodlist">
				<div class="good">
					<div style="display: flex;">
						<div>
							<img style="width: 120px;height: 120px;margin-right: 20px;" :src="goodinfo.cover_img"
								alt="" />
						</div>
						<div>
							<div class="goodname">
								{{goodinfo.goods_name}}
							</div>
							<div class="goodguige" style="margin-top: 10px;margin-bottom: 10px;">
								{{goodinfo.goods_spec}}
							</div>
							<div class="goodnumber">
								x{{goodinfo.goods_num}}
							</div>
						</div>
					</div>
					<div class="goodtype">

					</div>
				</div>

			</div>


			<div class="info">退款信息</div>

			<div class="roomitem" style="padding: 0;">
				<div class="roomdesc" style="display: flex;align-items: center;">
					<div class="roomdesctitle">退款方式：</div>
					<div v-show="Aftersales.from==1" class="roomdesccon">仅退款</div>
					<div v-show="Aftersales.from==2" class="roomdesccon">退货退款</div>

				</div>
				<!-- <div>{{goodinfo.money_type}}</div> -->
				<div class="roomdesc" style="display: flex;align-items: center;">
					<div class="roomdesctitle">退款金额：</div>
					<div v-show="goodinfo.money_type==1" class="roomdesccon" style="display: flex;align-items: center;">
						<img class="jifen" src="../assets/my/jifen.png" alt="" />
						<div style="color: #FF6A00;">{{Aftersales.point}}</div>
					</div>
					<div v-show="goodinfo.money_type==2" class="roomdesccon" style="display: flex;align-items: center;">
						<div style="color: #FF6A00;">￥{{Aftersales.price}}</div>
					</div>
					<div v-show="goodinfo.money_type==3" class="roomdesccon" style="display: flex;align-items: center;">
						<img class="jifen" src="../assets/my/jifen.png" alt="" />
						<div style="color: #FF6A00;">{{Aftersales.point}}+{{Aftersales.price}}</div>
					</div>
				</div>

				<div class="roomdesc" style="display: flex;align-items: center;">
					<div class="roomdesctitle">申请时间：</div>
					<div class="roomdesccon">{{Aftersales.create_time}}</div>
				</div>
				<div class="roomdesc" style="display: flex;align-items: center;">
					<div class="roomdesctitle">退款描述：</div>
					<div class="roomdesccon">{{Aftersales.content}}</div>
				</div>
				<div class="roomdesc" style="display: flex;align-items: center;" v-show="Aftersales.from==2">
					<div class="roomdesctitle">退款物流单号：</div>
					<div class="roomdesccon">{{Aftersales.express_delivery_number}}</div>
				</div>

			</div>
		</div>
		<el-dialog v-model="dialogVisible2" title="协商历史" width="500" :before-close="handleClose">
			<div class="peoplelist">
				<div v-for="(item,index) in log">
					<div class="people">
						<!-- <img class="peopleicon" :src="usericon" alt="" /> -->
						<div v-if="item.from==1">
							<img v-if="userinfo.avater_str" :src="userinfo.avater_str" class="peopleicon" mode="">
							<img v-if="!userinfo.avater_str" class="peopleicon" src="../assets/touxiang.png" alt="" />
						</div>
						<div v-if="item.from==2">
							<img class="peopleicon" src="../assets/touxiang.png" alt="" />
						</div>
						<div>
							<div class="peoplename">{{item.title}}</div>
							<div class="peopletime">{{item.create_time}}</div>
						</div>
					</div>
					<div v-if="item.from==1" class="neirong">
						<p>退款金额：￥{{item.price}}</p>
						<p>问题描述：{{item.content}}</p>
					</div>
					<div v-if="item.from==2" class="neirong">
						原因：{{item.content}}
					</div>
				</div>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button @click="handleClose">返回</el-button>

				</div>
			</template>
		</el-dialog>








		<el-dialog v-model="dialogVisible3" title="填写物流单号" width="510" :before-close="handleClose">
			<div style="margin-top: 20px;">
				<el-form :model="formInline" class="demo-form-inline" label-width="100">
					<el-form-item label="物流单号：">
						<el-input class="adw" v-model="formInline.express_delivery_number" placeholder="请输入物流单号"
							clearable />
					</el-form-item>
				</el-form>
			</div>
			<template #footer>
				<div class="dialog-footer" style="display: flex;justify-content: space-between;">
					<div></div>
					<div style="display: flex;">
						<div class="btn" @click="dialogVisible3 = false">取消</div>
						<div class="btn1" @click="completeLogclick()">
							提交
						</div>
					</div>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		addressList,
		addressAddEdit,
		deliveryList,
		confirmGoodsOrder,
		goodsOrderAftersales,
		myGoodsDetail,
		completeLogisticsCode,
		changeAddress,
		revokeRefund
	} from "@/utils/Myapi.js"

	import mapdata from '@/utils/city.json'
	import {
		newfileUpload,
		newfileUploadvideo
	} from '@/utils/upfile.js'
	import {
		ElMessage,
		ElMessageBox
	} from 'element-plus'
	import {
		Action
	} from 'element-plus'

	import {
		mymyGoodsList,
		cancelGoodsOrder,
		goodsEvaluate
	} from "@/utils/Myapi.js"
	import mygoodordertuikuan from '@/components/mygoodordertuikuan'
	import mygoodorderTuiDesc from '@/components/mygoodorderTuiDesc'
	import {url_config} from "@/utils/baseUrl"
	// mygoodordertuikuan
	export default {
		// 组件名
		name: 'heAd',
		// 注册组件
		components: {
			mygoodordertuikuan,
			mygoodorderTuiDesc
		},
		// 接收父组件数据
		props: {
			goodId: String
		},
		// 组件数据
		data() {
			return {
				slected: 0,
				props: {
					label: "name",
					value: "name"
				},
				addlist: [],
				dialogVisible4: false,
				mapJson: mapdata,
				formInline: {},
				activeName: '',
				textarea: '',
				dialogVisible1: false,
				FullShow: true,
				dialogVisible: false,
				dialogVisible2: false,
				status: '',
				lang: '',
				input3: "",
				titles: [],
				goodinfo: {},
				traces: [],
				wuliuinfo: {},
				slideshowurls: [],
				fileList: [],
				dialogImageUrl: '',
				videoinfo: {},
				videoaddress: '',
				video_id: '',
				log: [],
				Aftersales: {},
				dialogVisible3: false,
				express_delivery_number: '',
				dialogVisible5: false,
				slectedinfo: {},
				usericon: url_config+'/static/api/img/userimg.png',
				userinfo: {}
			}
		},
		// 计算属性login
		computed: {},
		// 监听属性
		watch: {},
		// 生命周期钩子函数
		created() {


		},
		mounted() {
			var info = localStorage.getItem("user");
			console.log('info==', info);
			this.userinfo = JSON.parse(info);
			this.getdata()
		},
		// 组件方法
		methods: {
			completeLogclick() {
				var formData = {
					'express_delivery_number': this.formInline.express_delivery_number,
					'id': this.goodinfo.id,
				}
				completeLogisticsCode(formData).then((res) => {
					if (res.code == 200) {

						ElMessage({
							message: '提交成功',
							type: 'success',
						})
						this.handleClose()
					}
				})
			},
			addwuliu() {
				this.dialogVisible3 = true;
			},
			goodsEvaluateclick() {},
			handleClose() {

				this.fileList = []

				this.slideshowurls = []

				this.videoaddress = []

				this.textarea = ''

				this.formInline.express_delivery_number = ''
				this.dialogVisible = false;
				this.dialogVisible1 = false;

				this.dialogVisible2 = false

				this.dialogVisible5 = false
				this.dialogVisible3 = false
			},
			tuikuang() {
				console.log('1111111')
				this.slected = 2

			},
			beforeAvatarUpload(file) {},
			upload(e, name) {},
			upload2(e, name) {},
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url
				this.dialogVisible4 = true
			},
			handleRemove(file, fileList) {},


			shouhuo() {},
			receivegood() {},
			revokeRefundone() {
				ElMessageBox.confirm(
						'您确定要撤销退款吗', {
							confirmButtonText: '确定',
							confirmButtonClass: 'ExitConfirmButton',
							cancelButtonText: '取消',
							// type: 'warning',
							center: true,
						}
					)
					.then(() => {

						this.revokeRefundtwo()
					})
					.catch(() => {

					})
			},
			revokeRefundtwo() {
				revokeRefund({
					"id": this.goodinfo.id
				}).then(res => {
					if (res.code == 200) {
						ElMessage({
							message: '撤销成功',
							type: 'success',
						})

						this.getdata()
						this.goback()
					} else {
						ElMessage({
							message: res.message,
							type: 'error',
						})
					}
				})
			},




			payclick() {},
			chanelOrder() {},
			quexiao() {},
			goback() {
				console.log('zi组件方法');
				this.$parent.fatherMethod();
				this.getdata()
			},
			edit() {
				this.dialogVisible5 = true
			},
			fatherMethod() {
				this.slected = false
			},
			getdata() {
				myGoodsDetail({
					'id': this.goodId
				}).then(res => {
					if (res.code == 200) {
						this.goodinfo = res.data
					}
				})
				goodsOrderAftersales({
					'id': this.goodId
				}).then(res => {
					if (res.code == 200) {
						this.log = res.data.log
						this.Aftersales = res.data.log[0]

					}
				})
				//我的地址
				addressList().then(res => {
					if (res.code == 200) {
						this.addlist = res.data.data
					}
				})
			},
			pingjia() {
				this.dialogVisible1 = true
			},
			look() {
				deliveryList({
					'id': this.goodId
				}).then(res => {
					if (res.code == 200) {
						this.traces = res.data.traces
						this.wuliuinfo = res.data
					}
				})
				this.dialogVisible = true


			},
			lishi() {
				this.dialogVisible2 = true
			},
			handleClick(index) {
				for (let var1 in this.titles) {
					this.titles[var1].ishow = false
				}
				this.titles[index].ishow = true

				switch (index) {}
			},
			btnclick(index) {
				console.log(index, "index");
				for (let var1 in this.addlist) {
					this.addlist[var1].ishow = false
				}

				this.addlist[index].ishow = true

				this.slectedinfo = this.addlist[index];

			},
			slectedAdd() {
				console.log(111111111)
				// this.formInline = this.slectedinfo
				// this.formInline.citys = this.slectedinfo.area.split('-');
				this.dialogVisible5 = false

				changeAddress({
					'order_id': this.goodId,
					'address_id': this.slectedinfo.id
				}).then(res => {
					if (res.code == 200) {
						ElMessage({
							message: '修改成功',
							type: 'success',
						})
						this.getdata()
					} else {
						ElMessage({
							message: res.message,
							type: 'error',
						})
					}
				})
			},
		},
	}
</script>

<style scoped lang="scss">
	.ordertype {
		margin-top: 20px;
		border: 1px solid #eee;
		padding: 20px;
		// width: 960px;
		// height: 315px;
	}

	.btn {
		width: 112px;
		height: 42px;
		border-radius: 26px;
		border: 1px solid #FF6A00;
		color: #FF6A00;
		text-align: center;
		font-size: 16px;
		line-height: 42px;
		margin-right: 20px;
	}

	.btn1 {
		width: 112px;
		height: 42px;
		font-size: 16px;
		border-radius: 26px;
		color: #fff;
		background-color: #FF6A00;
		text-align: center;
		line-height: 42px;
		margin-right: 20px;
	}

	.top {
		display: flex;
		justify-items: center;
		align-items: center;

		.typename {
			color: #FF6A00;
			font-size: 24px;
			font-weight: bold;
		}

		.icon {
			width: 40px;
			height: 40px;
			margin-right: 10px;
		}
	}

	.con {
		margin-top: 20px;
		font-size: 16px;
		color: #333333;
	}

	.hotellist {
		margin-top: 40px;
		// padding: 30rpx;
		background-color: #fff;
		font-size: 24px;
		// font-family: PingFang SC, PingFang SC;
		font-weight: 400;
		color: #333333;
		// line-height: 32rpx;
		// font-weight: bold;

		.hotelitem {
			display: flex;
			margin-top: 20px;
			// width: 690rpx;

			.right {
				margin-left: 30rpx;
				width: 500rpx;

				.imgs {
					display: flex;
					margin-bottom: 30rpx;

					.imgsitem {
						display: flex;
						flex-direction: column;
						justify-items: center;
						align-items: center;
						font-size: 28rpx;
						margin-right: 40px;
					}
				}

				.hotelbtm {
					display: flex;
					justify-content: flex-end;
					align-items: center;
					font-size: 32rpx;
					font-family: PingFang SC, PingFang SC;
					font-weight: 400;
					color: #FF6A00;
				}
			}

		}
	}

	.ruzhu {
		margin-top: 40px;
		// padding: 30rpx;
		background-color: #fff;
		font-size: 24px;
		// font-family: PingFang SC, PingFang SC;
		font-weight: 400;
		color: #333333;
		// font-weight: bold;
	}

	.timeicon {
		width: 40px;
		height: 48px;
	}

	.info {
		margin-top: 40px;
		margin-bottom: 20px;
		color: #333333;
		font-size: 24px;
	}

	.info1 {
		// margin-top: 40px;
		margin-bottom: 20px;
		color: #333333;
		font-size: 24px;
	}

	.info2 {
		// margin-top: 40px;
		margin-bottom: 20px;
		color: #333333;
		font-size: 20px;
	}

	.info3 {
		margin-bottom: 20px;
		margin-top: 20px;
		color: #333333;
		font-size: 20px;
	}

	.infoicon {
		width: 20px;
		height: 20px;
		margin-right: 10px;
	}

	.roomitem {
		width: 430px;
		// height: 160px;
		padding: 20px;
		padding-left: 0;
		// border: 1px solid #eeeeee;
		margin-right: 20px;

		.roomname {
			color: #333333;
			font-size: 20px;
			margin-bottom: 20px;
			font-weight: bold;
		}

		.roomdesc {
			margin-top: 20px;
			display: flex;
			align-items: center;

			.roomdesctitle {
				color: #666666;
				font-size: 16px;
				// width: 154px;
				margin-right: 20px;

			}

			.roomdesccon {
				color: #333333;
				font-size: 16px;
			}
		}
	}

	.jifen {
		width: 20px;
		height: 20px;
		margin-right: 5px;
	}

	.goodlist {
		.good {
			display: flex;
			padding: 20px;
			margin-top: 20px;
			border: 1px solid #EEEEEE;
			justify-content: space-between;

			.goodname {
				font-size: 20px;
				color: #333333;
			}

			.goodguige {
				font-size: 16px;
				color: #333333;
			}

			.goodgnumber {
				font-size: 16px;
				color: #666;
			}

			.goodtype {

				color: #FF6A00;
				font-size: 16px;
			}
		}
	}

	.adresitem {
		width: 360px;
		padding: 20px;
		height: 80px;
		border: 1px solid #EEEEEE;
	}

	.wuliuicon {
		width: 40px;
		height: 40px;
		margin-right: 10px;
	}

	.list {
		margin-top: 20px;

		.jiedian {
			display: flex;
			align-items: center;

			// justify-content: center;
		}

		.neirong {
			padding-bottom: 40px;
			padding-left: 30px;
			border-left: 1px dashed #FF6A00;
			margin-left: 20px;
		}
	}

	.pingjiatitle {
		margin-top: 20px;
		color: #333333;
		font-size: 16px;
		margin-left: 20px;
		margin-bottom: 10px;
	}

	.pingjiaIcon {
		width: 120px;
		height: 120px;
		margin-right: 10px;
	}

	.peoplelist {
		overflow-y: auto;
		height: 350px;
		.people {
			display: flex;

			.peoplename {
				font-size: 20px;
				color: #333333;
			}

			.peopletime {
				margin-top: 10px;
				color: #666666;
				font-size: 16px;
			}

			.peopleicon {
				width: 60px;
				height: 60px;
				margin-right: 20px;

			}

			.peoplebiaoti {}
		}

		.neirong {
			
			padding-bottom: 40px;
			padding-left: 50px;
			border-left: 1px dashed #FF6A00;
			margin-left: 30px;
		}
	}

	.ExitConfirmButton {
		background: #FF6A00 !important;
		color: #fff !important;
	}

	.video {
		width: 120px;
		height: 120px;
	}

	.addlist {
		height: 484px;
		overflow-y: auto;
	}

	.user_for {
		display: flex;
		height: 106px;
		margin-bottom: 20px;
		width: 400px;
		border: 1px solid #EEEEEE;
		background-color: #fff;
		text-align: center;
		align-items: center;
	}


	.right_box {
		margin: 0rpx 20rpx 20rpx 20rpx;
		background-color: #fff;

		.title {
			font-size: 16px;
			color: #333333;
		}

		.desc {
			font-size: 16px;
			color: #333333;
			display: flex;
			margin-top: 20px;
		}
	}

	.icon {
		width: 20px;
		height: 20px;
		margin-right: 20px;
		margin-left: 20px
	}

	::v-deep .el-upload--picture-card {
		background-color: #fff !important;
		border: 0px !important;
	}

	::v-deep .el-upload-list--picture-card .el-upload-list__item-actions span+span {
		margin-left: 4px !important;
	}
</style>