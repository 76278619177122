<template>
	<div style="background-color: #fff;padding: 20px;
				background: #fff;
				width: 1000px;">
		<div v-if="!slected">


			<div style="color: #333333;font-size: 24px;padding-left: 10px;border-left: 4px solid #FF6A00;">
				签到记录
			</div>

			<div>
				<div class="list">
					<div @click="descclick(item)" class="listitem"
						style="display: flex;align-items: center;justify-content: space-between;" v-for="(item,index) in list">
						<div>
							<div class="top">

								活动名称：{{item.meeting_name}}

							</div>
							<div class="desc">
								会议名称：{{item.meeting_name}}
							</div>
							<div class="desc">
								签到时间：{{item.create_time}}
							</div>
							<div class="desc">
								签到方式：{{item.sign_type_str}}
							</div>
						</div>
						<div style="display: flex;align-items: center;justify-items: center;">
							<div class="jifen">
								+{{item.point}}
							</div>
							<img class="icon" src="../../src/assets/my/jifen.png" alt="" />
						</div>

					</div>

				</div>
			</div>
		</div>
		<myqiandaoDesc :signLogId="slectedinfo.id" v-if="slected"></myqiandaoDesc>
	</div>
</template>

<script>
	import {
		signLog
	} from "@/utils/Myapi.js"
	import myqiandaoDesc from '@/components/myqiandaoDesc'
	export default {
		// 组件名
		name: 'heAd',
		// 注册组件
		components: {
			myqiandaoDesc
		},
		// 接收父组件数据
		props: {},
		// 组件数据
		data() {
			return {
				slected: false,
				activeName: '',
				FullShow: true,
				dialogVisible: false,
				status: '',
				lang: '',
				input3: "",
				titles: [],
				slectedinfo:{},
				list:[]
			}
		},
		// 计算属性login
		computed: {},
		// 监听属性
		watch: {},
		// 生命周期钩子函数
		created() {


		},
		mounted() {
			this.getdata()
		},
		// 组件方法
		methods: {
			fatherMethod(){
				this.slected = false
			},
			getdata() {
				signLog().then(res => {
					if (res.code == 200) {
						this.list = res.data.data
					}
				})
			},
			descclick(item) {
				this.slectedinfo = item;
				this.slected = true
				
			},
			handleClick(index) {
				for (let var1 in this.titles) {
					this.titles[var1].ishow = false
				}
				this.titles[index].ishow = true

				switch (index) {}
			},
		},
	}
</script>

<style scoped lang="scss">
	.list {
		margin-top: 20px;

		.listitem {
			width: 920px;
			padding: 20px;
			margin-top:20px;
			// height: 290px;

			border: 1px solid #EEEEEE;

			.top {
				display: flex;
				justify-content: space-between;
			}

			.desc {
				margin-top: 20px;
				color: #333333;
				font-size: 16px;
			}

			.jifen {
				font-size: 24px;
				color: #FF6A00;
			}

			.icon {
				width: 30px;
				height: 30px;
				margin-left: 10px;
			}
		}
	}

	.btn {
		width: 112px;
		height: 42px;
		border-radius: 26px;
		border: 1px solid #FF6A00;
		color: #FF6A00;
		text-align: center;
		line-height: 42px;
	}
</style>