<template>
	<div>
		<el-dialog v-model="start" title="登录" width="590">
			<div v-show="logintype==1" style="display: flex;align-items: center;flex-direction: column;">
				<div class="searview">
					<el-input style="width: 360px;" v-model="from.mobile" placeholder="请输入手机号" clearable />
				</div>
				<div class="searview" style="margin-top: 20px;">
					<el-input style="width: 360px;" v-model="from.code" placeholder="输入验证码">
						<template #suffix>
							<div @click="getcode" class="codebtn">{{buttonText}}</div>
						</template>
					</el-input>
				</div>
				<div @click="loginclick" class="btn">登录</div>
				<img class="loginIcon" src="../../src/assets/wxlogin.png" alt="" />
				<div @click="logintype=2" class="tabL">切换微信登录</div>
			</div>
			<div v-show="logintype==2" style="display: flex;align-items: center;flex-direction: column;">
				<div class="searview">
					<div style="display: flex;align-items: center;">
						<img style="width: 24px;height: 24px;margin-right: 10px;" src="../../src/assets/wxS.png"
							alt="" />
						<div>使用微信扫一扫登录</div>
					</div>
				</div>
				<img class="loginIcon" src="../../src/assets/wxlogin.png" alt="" />
				<img class="loginIcon" src="../../src/assets/phonelogin.png" alt="" />
				<div @click="logintype=1" class="tabL">切换手机号验证码登录</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import stringUtil from '../../src/utils/stringUtil.js'
	import {
		mapMutations
	} from 'vuex'
	import {
		ElMessage
	} from 'element-plus'

	// import {
	// 	Message
	// } from 'element-ui'

	import {
		login,
		sendcode,
	} from "@/utils/api"
	export default {
		// 组件名
		name: 'login',
		// 接收父组件数据
		props: {
			// dialogVisible: {
			// 	type: Boolean,
			// 	default: false // popup(弹窗)
			// }
		},
		// 注册组件
		components: {

		},

		// 组件数据
		data() {
			return {
				logintype: 1,
				slected: 1,
				activeName: '',
				FullShow: true,
				status: '',
				lang: '',
				input3: "",
				titles: [],
				dialogVisible: true,
				from: {
					mobile: '',
					code: '',
				},
				start: true,
				disabled: false,
				buttonText: '获取验证码',
				count: 60 // 倒计时时间，单位为秒
			}
		},
		watch: {
			trigger(star) {
				if (star) {
					this.start = star
					this.CHANGE_TXT(false)
				}
			}
		},
		// 计算属性login
		computed: {
			trigger() {
				return this.$store.state.status;
			},
		},
		// 监听属性
		watch: {},
		// 生命周期钩子函数
		created() {


		},
		mounted() {
		},
		// 组件方法
		methods: {
			loginclick() {
				login({
					"mobile": this.from.mobile,
					"code": this.from.code,
				}).then(res => {
					if (res.code == 200) {
						ElMessage({
							message: '登录成功',
							type: 'success',
						})
						localStorage.setItem("token", res.data.token)

						localStorage.setItem("user", JSON.stringify(res.data.user))

						location.reload();
						
						console.log('res=====', res);
						this.start = false
					} else {
						ElMessage({
							message: res.message,
							type: 'error',
						})
					}


				})
			},
			getcode() {
				if (this.disabled) {
					return
				}

				sendcode({
					"mobile": this.from.mobile
				}).then(res => {
					ElMessage({
						message: '发送成功',
						type: 'success',
					})
					this.disabled = true;
					this.buttonText = '重新发送 (' + this.count + 's)';
					const timer = setInterval(() => {
						this.count--;
						this.buttonText = '重新发送 (' + this.count + 's)';

						if (this.count <= 0) {
							clearInterval(timer);
							this.buttonText = '获取验证码';
							this.disabled = false;
							this.count = 60; // 重置倒计时时间
						}
					}, 1000);
				})
			},
			open() {
				console.log(2222222222);

			},
			get_wx_qrcode(iphone) {
				//从微信的开发文档不难看出回调域名需要经过encodeURIComponent处理
				let call_back_domain_name = window.location.href,
					domain_name_length = call_back_domain_name.indexOf("#") - 1;
				// DDNS = call_back_domain_name.substring(0, domain_name_length),
				let DDNS = "";
				if (iphone) {
					DDNS = `https://edu.bjxraq.com/safety_business/#/login?name=pcUser&userId=${iphone}`;
				} else {
					DDNS = call_back_domain_name;
				}
			
				//   let DDNS_resolution = encodeURIComponent(DDNS);
				let DDNS_resolution = encodeURIComponent(this.openUrl);
				console.log("DDNS_resolution", DDNS_resolution);
				var obj = new WxLogin({
					self_redirect: false,
					id: "login_containers", //需要承载二维码的容器id
					appid: "wx0e345a2763b2accf",
					scope: "snsapi_login", //网页授权，目前网页只有这一种方式，静默授权
					redirect_uri: DDNS_resolution, //回调域名
					state: Math.random(),
					style: "black",
					href: "",
				});
			},
		},
	}
</script>

<style scoped lang="scss">
	.shuru {
		width: 350px;
	}

	.codebtn {
		font-size: 14px;
		color: #FF6A00;
	}

	.btn {
		margin-top: 20px;
		width: 360px;
		height: 40px;
		border-radius: 5px;
		color: #fff;
		background-color: #FF6A00;
		text-align: center;
		line-height: 40px;
		/* margin-right: 20px; */
	}

	.loginIcon {
		margin-top: 40px;
		width: 60px;
		height: 60px;
	}

	.tabL {
		margin-top: 10px;
		font-size: 14px;
		color: #333333;
		text-align: center;
		padding-bottom: 40px;
	}
</style>