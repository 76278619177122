<template>
	<div style="background-color: #fff;padding-left: 20px;width: 1000px;">
		<div v-if="slected==1">
			<div style="color: #333333;font-size: 24px;padding-left: 10px;border-left: 4px solid #FF6A00;">
				我的报名
			</div>
			<el-tabs v-model="activeName" @tab-click="handleClick">
				<el-tab-pane label="全部" name="first"></el-tab-pane>
				<el-tab-pane label="待开始" name="second"></el-tab-pane>
				<el-tab-pane label="进行中" name="third"></el-tab-pane>
				<el-tab-pane label="已结束" name="fourth"></el-tab-pane>
				<el-tab-pane label="已取消" name="five"></el-tab-pane>
			</el-tabs>
			<div class="list" style="display: flex;">
				<div class="user_for" v-for="(item,id) in tableData" :key="id" style="margin-right: 20px;">
					<div class="right_box" style="color: #666;">
						<div class="title" style="display: flex;align-items: center;justify-items: center;">
							<img v-show="item.order_type==2" src="../assets/my/look1.png"
								style="width: 64px;height: 28px;" mode="">
							</img>
							<img v-show="item.order_type==1" src="../assets/my/look2.png"
								style="width: 64px;height: 28px;" mode="">
							</img>
							<span
								style="margin-left: 10px;color: #333333;font-size: 20px;font-weight: bold;">所属活动：{{item.activity_name}}
							</span>
						</div>
						<div class="title" style="margin-top: 20px;">
							会议名称：{{item.meeting.name}}
						</div>
						<div v-show="item.meeting.type==1" class="desc">
							会议类型：{{item.meeting.main_type_name}}
						</div>
						<div v-show="item.meeting.type==2" class="desc">
							会议类型：{{item.meeting.sub_type_name}}
						</div>
						<div class="desc">
							会议时间：{{item.meeting.start_time_str}}到{{item.meeting.end_time_str}}
						</div>
						<div class="desc" style="display: flex;justify-content: space-between;">

							<div class="">
								姓 名：{{userinfo.real_name}}
							</div>
							<div class=""
								style="height: 30px;width: 68px;line-height: 30px; color:#FF6A00 ; background-color:#FFF0E5;border-radius: 15px;font-size: 16px;text-align: center;">
								{{item.type_str}}
							</div>

						</div>
						<el-divider></el-divider>
						<div style="display: flex;justify-content: space-between;">
							<div>

							</div>
							<div style="display: flex;justify-content: space-between;">
								<div v-if="(item.type == 1 || item.type == 2) && item.sign_in == 1" class="btn" style="margin-right: 10px;" @click="look(item)">
									查看签到码
								</div>
								<div v-if="item.order_type==1" v-show="item.type==3&&item.is_invoice==0" class="btn"
									@click="openfapiao(item)">
									申请发票
								</div>
								<!-- <div class="btn" v-show="item.type==1" style="margin-right: 10px;"
									@click="chanel(item)">
									取消报名
								</div> -->
								<!-- <div class="btn" v-show="item.type==3" style="margin-right: 10px;" @click="dele(item)">
									删除
								</div> -->
							</div>

						</div>
					</div>
				</div>
			</div>
			<!-- <el-pagination v-model:current-page="pageSize.page" :page-size="10" :small="small" :background="background"
				layout="total, prev, pager, next" :total="pageSize.count" @size-change="handleSizeChange"
				@current-change="handleCurrentChange"></el-pagination> -->

		</div>
		<el-dialog v-model="dialogVisible" title="查看签到码" width="590" :before-close="handleClose">
			<div style="display: flex;align-items: center;flex-direction: column;">
				<div class="user">
					<!-- <img class="touxiang" :src="userinfo.avater_str" alt="" /> -->
					<img v-if="userinfo.avater_str" :src="usericon" class="touxiang" mode=""></img>
					<img v-if="!userinfo.avater_str"  class="touxiang"  src="../assets/touxiang.png" alt="" />
					<div>{{userinfo.real_name}}</div>
				</div>
				<!-- <img class="erweima" src="../assets/my/test.png" alt="" /> -->
				<div v-if="dialogVisible" class="erweima">
					<div ref="qrcode" id="qrcode">

					</div>
				</div>

				<div>请将二维码让管理员扫描</div>
			</div>
			<template #footer>
			</template>
		</el-dialog>
		<myRegistrationDesc :orderid='meetinginfo.order_id' v-if="slected==2"></myRegistrationDesc>
	</div>
</template>

<script>
	import {
		hotelroom,
		meetingList,
		myMeetingApplication,
		hotelraddOrder,
		meetingCancelApply,
		meetingChildDel
	} from "@/utils/api"
	import {
		ElMessage,
		ElMessageBox
	} from 'element-plus'
	import QRCode from "qrcodejs2-fix";
	import myRegistrationDesc from '@/components/myRegistrationDesc'
	import {url_config} from "@/utils/baseUrl"
	export default {
		// 组件名
		name: 'heAd',
		// 注册组件
		components: {
			myRegistrationDesc
		},
		// 接收父组件数据
		props: {},
		// 组件数据
		data() {
			return {
				usericon: url_config+'/static/api/img/userimg.png',
				slected: 1,
				activeName: 'first',
				FullShow: true,
				dialogVisible: false,
				tableData: [],
				status: '',
				lang: '',
				input3: "",
				titles: [],
				userinfo: {},
				my_auth_info: {},
				meetinginfo: {},
				pagesize: {
					status: '',
					count: 0,
					limit: 0,
					page: 1,
					type: ''
				}
			}
		},
		// 计算属性login
		computed: {},
		// 监听属性
		watch: {},
		// 生命周期钩子函数
		created() {


		},
		mounted() {
			this.getdata()
		},
		// 组件方法
		methods: {
			look(item) {
				this.dialogVisible = true;
				let _this = this;
				setTimeout(function() {
					_this.getapp(item);
				}, 100);
			},
			getapp(item) {
				console.log("item========", item);
				var data = {
					'meeting_order_child_id': item.id,
					'user_auth_id':this.my_auth_info.id
				}
				var url = JSON.stringify(data);
				console.log("url========", url);
				new QRCode(document.getElementById("qrcode"), {
					text: url,
					// width: 270,
					height: 270,
					colorDark: "#000000",
					colorLight: "#ffffff",
					correctLevel: QRCode.CorrectLevel.H,
				});
			},
			chanel(item) {

				ElMessageBox.confirm(
						'您确定要，取消报名', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							center: true,
						}
					)
					.then(() => {
						this.cancelOrder(item)
					})
					.catch(() => {})

			},
			cancelOrder(item) {
				meetingCancelApply({
					'order_id': item.order_id,
					'child_id': item.id
				}).then(res => {
					if (res.code == 200) {
						ElMessage({
							message: '取消成功',
							type: 'success',
						})
						this.getdata()
					} else {
						ElMessage({
							message: res.message,
							type: 'error',
						})
					}
				})
			},
			dele(item) {

				ElMessageBox.confirm(
						'您确定要删除', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							confirmButtonClass: 'btnConfirm',
							center: true,
						}
					)
					.then(() => {
						this.deleOrder(item)
					})
					.catch(() => {})

			},
			deleOrder(item) {
				meetingChildDel({
					'order_id': item.order_id,
					'child_id': item.id
				}).then(res => {
					if (res.code == 200) {
						ElMessage({
							message: '删除成功',
							type: 'success',
						})
						this.getdata()
					} else {
						ElMessage({
							message: res.message,
							type: 'error',
						})
					}
				})
			},
			getdata() {
				var info = localStorage.getItem("user");
				console.log('info==', info);
				this.userinfo = JSON.parse(info);

				var my_auth_info = localStorage.getItem("my_auth_info");

				console.log('my_auth_info==', my_auth_info);

				this.my_auth_info = JSON.parse(my_auth_info);
				//我的报名
				myMeetingApplication(this.pagesize).then(res => {
					if (res.code == 200) {
						
						this.tableData = res.data.data
						
					}
				})
			},
			fatherMethod() {
				this.slected = 1
				console.log('父组件方法');
				this.getdata()
			},
			handleSizeChange() {
				this.getdata()
			},
			clickitem() {
				this.$router.push({
					name: 'registration'
				})
			},
			openfapiao(item) {
				this.meetinginfo = item
				this.slected = 2;
			},
			handleClick(e) {
				console.log('e', e.index);
				this.pagesize.type = e.index;
				this.getdata()
			},
		},
	}
</script>
<style lang='scss'>
	.btnConfirm {
		background: #FF6A00 !important;
		color: #fff !important;
		border: 1px solid #FF6A00 !important;
	}

	.btnCancel {
		background: #009BF4 !important;
		color: #FFFFFF !important;
	}

	.el-button:first-child:hover {
		color: #333 !important;
		background-color: #fff !important;
		border-color: #999 !important;
	}

	.el-button:nth-child(2):hover {
		color: #FFFFFF !important;
		border-color: #FF6A00 !important;
		background-color: #FF6A00 !important;
	}
</style>
<style scoped lang="scss">
	.list {
		display: flex;
		flex-wrap: wrap;

		.user_for {
			margin-right: 30px;
			width: 430px;
			margin-top: 20px;
			// height: 247px;

			border: 1px solid #EEEEEE;

			.right_box {
				padding: 20px;

				.title {
					font-size: 20px;
					color: #333333;
				}

				.desc {
					margin-top: 20px;
					font-size: 16px;
					color: #666666;
				}
			}
		}

	}

	::v-deep .el-tabs__item.is-active {
		color: #FF6A00; //选中
		opacity: 1;
	}

	::v-deep .el-tabs__item:hover {
		color: #FF6A00; //悬浮
		cursor: pointer;
		opacity: 1;
	}

	/* 下划线颜色 */
	::v-deep .el-tabs__active-bar {
		background-color: #FF6A00;
	}

	::v-deep .el-tabs__nav-wrap::after {

		position: static !important;

	}

	.btn {
		width: 112px;
		height: 42px;
		border-radius: 26px;
		border: 1px solid #FF6A00;
		color: #FF6A00;
		text-align: center;
		line-height: 42px;
		margin-right: 20px;
	}

	.user {
		display: flex;
		align-items: center;
		justify-content: center;

		.touxiang {
			width: 80px;
			height: 80px;
			border-radius: 40px;
			margin-right: 20px;
		}

	}

	.erweima {
		margin-top: 40px;
		margin-bottom: 20px;
		margin-left: auto;
		margin-right: auto;
		width: 300px;
		height: 300px;
	}
</style>