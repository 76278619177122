<template>
	<div style="background-color: #fff;padding: 20px;
				background: #fff;">
		<div style="display: flex;justify-content: space-between;">
			<div style="color: #333333;font-size: 24px;padding-left: 10px;border-left: 4px solid #FF6A00;"
				v-show="invoiceid">
				编辑发票
			</div>
			<div style="color: #333333;font-size: 24px;padding-left: 10px;border-left: 4px solid #FF6A00;"
				v-show="!invoiceid">
				添加发票
			</div>
			<div style="display: flex;">
				<div class="btn" @click="goback">
					返回
				</div>
				<div class="btn1" @click="changefapiao">
					提交
				</div>
			</div>

		</div>
		<div>
			<div class="list">
				<el-form :model="form" class="demo-form-inline" label-width="100">
					<el-form-item label="发票类型：">
						<el-radio-group v-model="form.inv_type">
							<el-radio :label="1">普通发票</el-radio>
							<el-radio :label="2">增值税专已发票</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="抬头类型：">
						<el-radio-group v-model="form.header_type">
							<el-radio :label='1'>企业单位</el-radio>
							<el-radio :label='2'>个人/非企业单位</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="企业名称：">
						<el-input v-model="form.name" placeholder="请输入" clearable />
					</el-form-item>
					<el-form-item label="税号：">
						<el-input v-model="form.code" placeholder="请输入" clearable />
					</el-form-item>
					<el-form-item label="电话号码：：">
						<el-input v-model="form.mobile" placeholder="请输入" clearable />
					</el-form-item>
					<el-form-item label="企业地址：">
						<el-input v-model="form.address" placeholder="请输入" clearable />
					</el-form-item>

					<el-form-item label="开户银行：">
						<el-input v-model="form.bank_name" placeholder="请输入" clearable />
					</el-form-item>

					<el-form-item label="银行账号：">
						<el-input v-model="form.bank_account" placeholder="请输入" clearable />
					</el-form-item>
					<el-form-item label="是否默认：">
						<!-- <el-switch v-model="form.is_default" active-color="#EBEBEB" inactive-color="#FF6A00">
						</el-switch> -->
						<el-switch :active-value="1" :inactive-value="2" v-model="form.is_default"
							active-color="#EBEBEB" inactive-color="#FF6A00">
						</el-switch>
					</el-form-item>
				</el-form>
				<!-- <div class="desc">
					是否默认：
					
				</div> -->
			</div>
		</div>

	</div>
</template>

<script>
	import {
		invoiceList,
		invoiceDetailLoce,
		invoiceAddEdit
	} from "@/utils/Myapi.js"
	import {
		ElMessage,
		ElMessageBox
	} from 'element-plus'
	export default {
		// 组件名
		name: 'heAd',
		// 注册组件
		components: {},
		// 接收父组件数据
		props: {
			invoiceid: String
		},
		// 组件数据
		data() {
			return {
				form: {},
				activeName: '',
				FullShow: true,
				dialogVisible: false,
				status: '',
				lang: '',
				input3: "",
				titles: [],
			}
		},
		// 计算属性login
		computed: {},
		// 监听属性
		watch: {},
		// 生命周期钩子函数
		created() {


		},
		mounted() {
				this.form={}
			if (this.invoiceid) {
				this.getdata()
			}

		},
		// 组件方法
		methods: {
			changefapiao() {
				console.log('zi1111111');
				invoiceAddEdit(this.form).then(res => {
					if (res.code == 200) {
						ElMessage({
							message: '操作成功',
							type: 'success',
						})
						this.goback()
					}else{
						ElMessage({
							message:res.message,
							type: 'error',
						})
					}
				})
			},
			goback() {
				console.log('zi组件方法');
				this.$parent.fatherMethod();
				this.getdata()
			},
			getdata() {
				invoiceDetailLoce({
					'id': this.invoiceid
				}).then(res => {
					if (res.code == 200) {
						this.form = res.data
					}
				})
			},
		},
	}
</script>

<style scoped lang="scss">
	.list {
		margin-top: 40px;

		.demo-form-inline .el-input {
			--el-input-width: 320px;
		}

		.demo-form-inline .el-select {
			--el-select-width: 320px;
		}
	}

	.btn {
		width: 112px;
		height: 42px;
		border-radius: 26px;
		border: 1px solid #FF6A00;
		color: #FF6A00;
		text-align: center;
		line-height: 42px;
		margin-right: 20px;
	}

	.btn1 {
		width: 112px;
		height: 42px;
		border-radius: 26px;
		color: #fff;
		background-color: #FF6A00;
		text-align: center;
		line-height: 42px;
		margin-right: 20px;
	}

	/* 选中后的字体颜色 */
	::v-deep .el-radio__input.is-checked+.el-radio__label {
		color: #FF6A00 !important;
	}

	/* 选中后小圆点的颜色 */
	::v-deep .el-radio__input.is-checked .el-radio__inner {
		background: #FF6A00 !important;
		border-color: #FF6A00 !important;
	}
</style>