<template>
	<div style="background-color: #fff;padding: 20px;
				background: #fff;
				width: 1000px;">


		<div v-show="slected==0">
			<div style="color: #333333;font-size: 24px;padding-left: 10px;border-left: 4px solid #FF6A00;">
				我的奖品
			</div>
			<div class="list">
				<div v-for="item in list">
					<div class="listitem" v-if="item.point!=0">
						<div class="top">
							<div style="display: flex;align-items: center;">
								<img style="margin-right: 5px;width: 16px;height: 16px;"
									src="../assets/my/luckjifen.png" alt="" />
								<div>积分</div>
							</div>
							<div v-show="item.status==2" style="color: #FF6A00;">
								已到账
							</div>
							<div v-show="item.status==1" style="color: #FF6A00;">
								待领取
							</div>
						</div>
						<div class="number">
							积分数量：{{item.drawPrize.num}}
						</div>
						<div class="desc">
							中奖时间：{{item.create_time}}
						</div>
						<div class="desc">
							到账时间：{{item.create_time}}
						</div>

						<!-- <el-divider></el-divider> -->
						<div style="display: flex;justify-content: space-between;">
							<div>

							</div>
							<!-- <div class="btn" @click="jifenclick(item)">
								领取
							</div> -->
						</div>
					</div>


					<div class="listitem" v-if="item.point==0">
						<div class="top">
							<div style="display: flex;align-items: center;">
								<img style="margin-right: 5px;width: 16px;height: 16px;"
									src="../assets/my/luckjiangping.png" alt="" />
								<div>奖品</div>
							</div>
							<div v-show="item.status==2" style="color: #FF6A00;">
								已到账
							</div>
							<div v-show="item.status==1" style="color: #FF6A00;">
								待领取
							</div>
						</div>
						<div class="number">
							奖项：{{item.drawPrize.level}}
						</div>
						<div class="desc">
							中奖时间：{{item.create_time}}
						</div>
						<div style="display: flex;justify-content: space-between;margin-top: 20px;">
							<div style="display: flex;">
								<div>
									<img class="goodicon" style="margin-right: 20px;" :src="item.goods.cover_img"
										alt="" />
								</div>
								<div>
									<div class="goodname">
										{{item.goods.name}}
									</div>
									<div class="goodguige" style="margin-top: 10px;margin-bottom: 10px;">
										{{item.goods.name}}
									</div>
									<div class="goodnumber">
										x{{item.num}}
									</div>
								</div>
							</div>
						</div>
						<el-divider></el-divider>
						<div style="display: flex;justify-content: space-between;">
							<div>

							</div>
							<div v-show="item.status ==1" class="btn" @click="goodsclick(item)">
								领取
							</div>
							<div @click="lookdes(item)" v-show="item.status ==2" class="btn">
								查看详情
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<el-pagination v-model:current-page="pagesize.page" :page-size="pagesize.limit" :background="background"
			layout="total, prev, pager, next" :total="pagesize.count" @size-change="getdata"
			@current-change="getdata" />
		<el-dialog v-model="dialogVisible" title="选择收货地址" width="620" :before-close="handleClose">
			<div class="caddress">
				<div class="title"
					style="color: #333333;font-size: 24px;padding-left: 10px;border-left: 4px solid #FF6A00;">
					收货地址
				</div>
				<div class="adr" @click="chooseAddress">
					选择地址
				</div>
			</div>

			<div class="addaddress">
				<el-form :model="formInline" class="demo-form-inline" label-width="150">
					<el-form-item label="收货人姓名：">
						<el-input disabled class="adw" v-model="formInline.name" placeholder="请输入" clearable />
					</el-form-item>
					<el-form-item label="收货人手机号：">
						<el-input disabled class="adw" v-model="formInline.mobile" placeholder="请输入" clearable />
					</el-form-item>
					<el-form-item label="收货地区：">
						<el-cascader disabled style="width: 420px;" ref="articleOrgRef" placeholder="请选择地址"
							v-model="formInline.citys" :props='props' :options="mapJson.data" />
					</el-form-item>
					<el-form-item label="详细地址：">
						<el-input disabled class="adw" v-model="formInline.address" type="textarea" placeholder="请输入"
							clearable />
					</el-form-item>
				</el-form>
			</div>
			<el-dialog v-model="dialogVisible1" title="选择收货地址" width="510" :before-close="handleClose" append-to-body>
				<div class="addlist">
					<div class="user_for" v-for="(item,index) in addlist" :key="id">
						<div @click.stop="btnclick(index)" class="">
							<img v-show="!item.ishow" class="icon" src="../assets/chooseNO.png" alt="">
							<img v-show="item.ishow" class="icon" src="../assets/choose.png" alt="">
						</div>
						<div class="right_box">
							<div class="title" style="display: flex;margin-top: 20px;align-items: center;">
								<span style="color: #333333;">{{item.name}} {{item.mobile}}
								</span>
								<div v-show="item.is_default==2" class="moren">
									默认
								</div>
							</div>
							<div class="desc" style="margin-top: 20px;">
								{{item.area}}{{item.address}}
							</div>
						</div>
					</div>
				</div>
				<template #footer>
					<div class="dialog-footer" style="display: flex;justify-content: space-between;">
						<div></div>
						<div style="display: flex;">
							<div class="btn" @click="dialogVisible1 = false">取消</div>
							<div class="btn1" @click="slectedAdd()">
								保存
							</div>
						</div>
					</div>
				</template>
			</el-dialog>
			<template #footer>
				<div class="dialog-footer" style="display: flex;justify-content: space-between;">
					<div></div>
					<div style="display: flex;">
						<div class="btn" @click="dialogVisible = false">取消</div>
						<div class="btn1" @click="goodsubmit">
							提交
						</div>
					</div>

				</div>
			</template>
		</el-dialog>
		<mygoodorderDesc :goodId='slectedInfo.order_id' v-if="slected==1"></mygoodorderDesc>
	</div>
</template>


<script>
	import {
		myPrize,
		drawList,
		reveivePrize
	} from "@/utils/Myapi.js"
	import {
		addressList,
		addressAddEdit
	} from "@/utils/Myapi.js"
	import {
		ElMessage
	} from 'element-plus'

	import mygoodorderDesc from '@/components/mygoodorderDesc'

	import img1 from "@/assets/my/t1.png";
	import img2 from "@/assets/my/t2.png";
	import img3 from "@/assets/my/t3.png";
	import img4 from "@/assets/my/t4.png";
	import img5 from "@/assets/my/t5.png";
	import img6 from "@/assets/my/t6.png";
	import img7 from "@/assets/my/t7.png";
	import mapdata from '@/utils/city.json'
	export default {
		// 组件名
		name: 'heAd',
		// 注册组件
		components: {
			mygoodorderDesc
		},
		// 接收父组件数据
		props: {},
		// 组件数据
		data() {
			return {
				props: {
					label: "name",
					value: "name"
				},
				mapJson: mapdata,
				activeName: '',
				FullShow: true,
				dialogVisible: false,
				dialogVisible1: false,
				status: '',
				lang: '',
				input3: "",
				list: [],
				formInline: {},
				addlist: [],
				slectedinfo: {},
				goodinfo: {},
				slectedInfo: {},
				slected: 0,
				pagesize: {
					count: 0,
					limit: 5,
					page: 1,
					status:''
				},
			}
		},
		// 计算属性login
		computed: {},
		// 监听属性
		watch: {},
		// 生命周期钩子函数
		created() {


		},
		mounted() {
			this.getdata()
		},
		// 组件方法
		methods: {
			lookdes(item) {
				this.slectedInfo = item
				this.slected = 1
			},
			fatherMethod() {
				this.slected = 0
			},
			jifenclick(item) {
				reveivePrize({
					id: item.id
				}).then(res => {
					if (res.code == 200) {
						ElMessage({
							message: '领取成功',
							type: 'success',
						})
					}
				})
			},
			goodsubmit() {

				reveivePrize({
					id: this.goodinfo.id,
					address_id: this.formInline.id
				}).then(res => {
					if (res.code == 200) {
						ElMessage({
							message: '领取成功',
							type: 'success',
						})
						this.dialogVisible1 = false
						this.dialogVisible = false
					}
				})
			},
			slectedAdd() {
				console.log(111111111)

				this.formInline = this.slectedinfo

				this.formInline.citys = this.slectedinfo.area.split('-');

				this.dialogVisible1 = false

			},
			goodsclick(item) {

				this.goodinfo = item

				this.dialogVisible = true
			},
			chooseAddress() {
				this.dialogVisible1 = true
			},
			btnclick(index) {
				for (let var1 in this.addlist) {
					this.addlist[var1].ishow = false
				}

				this.addlist[index].ishow = true

				this.slectedinfo = this.addlist[index];

			},
			getdata() {
				myPrize(this.pagesize).then(res => {
					if (res.code == 200) {
						this.list = res.data.data
						this.pagesize.count = res.data.count
					}
				})
				//我的地址
				addressList().then(res => {
					if (res.code == 200) {
						this.addlist = res.data.data
					}
				})
			},
			handleClick(index) {
				for (let var1 in this.titles) {
					this.titles[var1].ishow = false
				}
				this.titles[index].ishow = true

				switch (index) {}
			},
		},
	}
</script>

<style scoped lang="scss">
	.list {
		margin-top: 20px;

		.listitem {
			width: 920px;
			padding: 20px;
			// height: 290px;
			margin-top: 20px;
			border: 1px solid #EEEEEE;

			.top {
				display: flex;
				justify-content: space-between;
				align-items: center;
				justify-items: center;
				margin-bottom: 20px;
			}

			.desc {
				margin-top: 20px;
				color: #333333;
				font-size: 16px;
			}
		}
	}

	.btn {
		width: 112px;
		height: 42px;
		border-radius: 26px;
		border: 1px solid #FF6A00;
		color: #FF6A00;
		text-align: center;
		line-height: 42px;
	}

	.grop {
		display: flex;
		flex-wrap: wrap;

		.gropitem {
			width: 240px;
			height: 70px;
			padding: 20px;
			display: flex;

			border-radius: 10px 10px 10px 10px;
			border: 1px solid #EEEEEE;

			.icon {
				width: 70px;
				height: 70px;
				margin-right: 20px;
				// margin-right: 20px;

			}

			.number {

				font-size: 24px;
				color: #333333;
			}

			.title {
				margin-top: 12px;
				color: #666666;

				font-size: 16px;
			}
		}
	}

	.btn1 {
		width: 112px;
		height: 42px;
		border-radius: 26px;
		color: #fff;
		background-color: #FF6A00;
		text-align: center;
		line-height: 42px;
		margin-right: 20px;
	}

	.icon {
		width: 20px;
		height: 20px;
		margin-right: 20px;
		margin-left: 20px
	}

	.addlist {
		height: 484px;
		overflow-y: auto;
	}

	.user_for {
		display: flex;
		height: 106px;
		margin-bottom: 20px;
		width: 400px;
		border: 1px solid #EEEEEE;
		background-color: #fff;
		text-align: center;
		align-items: center;
	}


	.right_box {
		margin: 0rpx 20rpx 20rpx 20rpx;
		background-color: #fff;

		.title {
			font-size: 16px;
			color: #333333;
		}

		.desc {
			font-size: 16px;
			color: #333333;
			display: flex;
			margin-top: 20px;
		}
	}

	.caddress {
		display: flex;

		justify-content: space-between;
		margin-bottom: 10px;

		.adr {
			// margin-top: 40px;
			// margin-left: 300px;
			color: #FF6A00;
			font-size: 20px;
		}

	}

	.addaddress {
		margin-top: 20px;

		/* width: 300px; */
		.adw {
			width: 420px;
		}
	}

	.moren {
		border: 1px solid #FF6A00;
		padding: 5px;
		color: #FF6A00;
		border-radius: 5px;
	}

	.goodicon {
		width: 120px;
		border-radius: 10px;
		height: 120px;
	}
	/* 修改前后箭头未点击时的背景颜色 */
	::v-deep .el-pagination .btn-prev,
	::v-deep .el-pagination .btn-next {
		/* background-color: #0e3a90 !important; */
	}
	
	/* 修改未点击时的数字方块背景颜色 */
	:v-deep .el-pagination .el-pager li:not(.active):not(.disabled):hover {
		/* background-color: #75fbff !important; */
	}
	
	/* 未点击时的数字方块背景颜色 */
	::v-deep .el-pager li.is-active {
		/* background-color: #0e3a90 !important; */
		color: #FF6A00;
	}
	
	::v-deep .el-pagination.is-background .el-pager li:not(.is-disabled).is-active {
		/* background-color: #75fbff !important; //修改默认的背景色 */
		color: #01133c;
	}
</style>