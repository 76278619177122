<template>
	<div style="background-color: #fff;padding: 20px;
				background: #fff;
				width: 1000px;">
		<div style="display: flex;justify-content: space-between;">
			<div style="color: #333333;font-size: 24px;padding-left: 10px;border-left: 4px solid #FF6A00;">
				酒店订单
			</div>
			<div style="display: flex;">
				<div class="btn" @click="goback">
					返回
				</div>
			</div>

		</div>

		<div class="ordertype">
			<div class="top">
				<img class="icon" src="../assets/my/ordertype.png" alt="" />
				<div class="typename">{{info.status_str}}</div>
			</div>
			<div v-show="info.pay_status==1" class="con">请尽快支付，订单超时未支付将自动取消</div>
			<div v-show="info.pay_status==1" class="con">请在{{info.auto_cancel_time}}前完成支付，超期后自动取消</div>
			<div class="con">支付金额：¥{{info.pay_price}}</div>
			<div class="con">订单编号：{{info.orderno}}</div>
			<div style="display: flex;justify-content: space-between;">
				<div>

				</div>
				<div style="display: flex;">
					<div v-show="info.pay_status==1" class="btn" style="margin-right: 20px;">取消订单</div>
					<div v-show="info.pay_status==1" @click="payclick" class="btn1">去支付</div>
				</div>
			</div>
		</div>
		<div style="display: flex;">
			<div class="left">
				<div class="hotellist">
					<div style=" font-weight: bold;">
						预订信息
					</div>

					<div class="hotelitem" @click="descclick">
						<div class="left">
							<img style="width: 240px;height: 270px;margin-right: 20px;" :src="hotelRoom.cover_str"
								mode=""></img>
						</div>
						<div class="right">
							<div class="" style="display: flex;align-items: center;">
								{{hotelRoom.name}}
								<img style="width: 15px;height: 24px;margin-left: 10px;" src="../assets/myh4.png"
									alt="" />
							</div>
							<div class="" style="margin-top: 20px;margin-bottom: 20px;font-size: 28rpx;">
								{{hotelRoom.configuration}}
							</div>
							<div class="imgs">
								<div class="imgsitem" v-for="data in hotelRoom.label">
									<img style="width: 30px;height: 30px;margin-bottom: 5px;" :src="data.img_str"
										mode=""></img>
									<div class="" style="font-size: 20px;">
										{{data.name}}
									</div>
								</div>
							</div>
							<div class="" style="font-size: 20px;color: #333333;margin-top: 20px;">
								<!-- 剩余：2间 -->
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="ruzhu">
				<div style="font-weight: bold;">入住周期</div>
				<div style="display: flex;align-items: center;margin-top: 20px;">
					<img class="timeicon" src="../assets/myh5.png" alt="" />
					<div>{{info.start_time_str}}</div>
				</div>
				<el-divider border-style="dashed" direction="vertical"
					style="margin-left: 20px;color: #FF6A00;height: 60px;" />

				<div style="display: flex;align-items: center;">
					<img class="timeicon" src="../assets/myh6.png" alt="" />
					<div>{{info.end_time_str}}</div>
				</div>
			</div>
		</div>
		<div class="info">酒店信息</div>
		<div class="info1">{{hotel.name}}</div>
		<div class="info2">酒店地址</div>
		<div class="info2">{{hotel.place}}</div>
		<div style="display: flex;align-items: center;margin-top: 20px;">
			<img class="infoicon" src="../assets/myh7.png" alt="" />
			<div>距离会议{{hotel.distance}}公里</div>
		</div>
		<div class="info3">联系电话</div>
		<div style="display: flex;align-items: center;margin-top: 20px;">
			<img class="infoicon" src="../assets/myh8.png" alt="" />
			<div>{{hotel.mobile}}</div>
		</div>
		<div class="info">入住人信息</div>
		<div class="peoplelist">
			<div class="peopleitem" v-for="(item,index) in rooms" :key="index">
				<div class="itemtop">
					<div style="display: flex;align-items: center;">
						<div>房间{{index+1}}</div>
					</div>

				</div>
				<div class="itemdesc">
					姓名：{{item.authentication_name}}
				</div>
				<div class="itemdesc">
					身份证号码：{{item.authentication_idnumber}}
				</div>
				<div class="itemdesc">
					下单时间：{{item.create_time}}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		hotelOrderList,
		hotelOrderDetail,
		hotelOrdercancel,
		hotelOrderdelete
	} from "@/utils/Myapi.js"
	import {
		ElMessage,
		ElMessageBox
	} from 'element-plus'
	export default {
		// 组件名
		name: 'heAd',
		// 注册组件
		components: {},
		// 接收父组件数据
		props: {
			id: String
		},
		// 组件数据
		data() {
			return {
				formInline: {},
				activeName: '',
				FullShow: true,
				dialogVisible: false,
				status: '',
				lang: '',
				input3: "",
				titles: [],
				info: {},
				hotel: {},
				roominfo: {},
				rooms: [],
				hotelRoom: {}
			}
		},
		// 计算属性login
		computed: {},
		// 监听属性
		watch: {},
		// 生命周期钩子函数
		created() {
			this.getdata()

		},
		mounted() {

		},
		// 组件方法
		methods: {
			dele() {
				ElMessageBox.confirm(
						'您确定要，删除订单', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							confirmButtonClass: 'btnConfirm',
							center: true,
						}
					)
					.then(() => {
						this.deleOrder()
					})
					.catch(() => {})
			
			},
			deleOrder(){
				hotelOrderdelete({
					'id': this.info.id,
				}).then(res => {
					if (res.code == 200) {
						ElMessage({
							message: '删除成功',
							type: 'success',
						})
						this.getdata()
					}
				})
			},
			chanel() {
				ElMessageBox.confirm(
						'您确定要，取消订单', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							confirmButtonClass: 'btnConfirm',
							center: true,
						}
					)
					.then(() => {
						this.cancelOrder(item.id)
					})
					.catch(() => {})
			
			},
			cancelOrder() {
				hotelOrdercancel({
					'id': this.info.id,
				}).then(res => {
					if (res.code == 200) {
						ElMessage({
							message: '取消成功',
							type: 'success',
						})
						this.getdata()
					}
				})
			},
			goback() {
				this.$parent.fatherMethod();
			},
			payclick(item) {
				this.$router.push({
					name: 'orderpay',
					query: {
						'order_id': this.info.id,
						"order_no": this.info.orderno,
						"pay_price": this.info.pay_price,
						"from": '3',
						offline:JSON.stringify({
							offline_payname:this.info.hotel.payee,
							offline_bankname:this.info.hotel.bank_name,
							offline_payaccount:this.info.hotel.bank_account
						})
					}
				})
			},
			getdata() {
				hotelOrderDetail({
					'id': this.id
				}).then(res => {
					if (res.code == 200) {
						this.info = res.data
						this.hotel = res.data.hotel
						this.roominfo = res.data.child[0];
						this.rooms = res.data.child;
						this.hotelRoom = res.data.hotelRoom;
					}
				})
			},

			handleClick(index) {
				for (let var1 in this.titles) {
					this.titles[var1].ishow = false
				}
				this.titles[index].ishow = true

				switch (index) {}
			},
		},
	}
</script>
<style lang='scss'>
	.btnConfirm {
		background: #FF6A00 !important;
		color: #fff !important;
		border: 1px solid #FF6A00 !important;
	}

	.btnCancel {
		background: #009BF4 !important;
		color: #FFFFFF !important;
	}

	.el-button:first-child:hover {
		color: #333 !important;
		background-color: #fff !important;
		border-color: #999 !important;
	}

	.el-button:nth-child(2):hover {
		color: #FFFFFF !important;
		border-color: #FF6A00 !important;
		background-color: #FF6A00 !important;
	}
</style>
<style scoped lang="scss">
	.ordertype {
		margin-top: 20px;
		border: 1px solid #eee;
		padding: 20px;
		// width: 960px;
		// height: 315px;
	}

	.btn {
		width: 112px;
		height: 42px;
		border-radius: 26px;
		border: 1px solid #FF6A00;
		color: #FF6A00;
		text-align: center;
		font-size: 16px;
		line-height: 42px;
		margin-right: 20px;
	}

	.btn1 {
		width: 112px;
		height: 42px;
		font-size: 16px;
		border-radius: 26px;
		color: #fff;
		background-color: #FF6A00;
		text-align: center;
		line-height: 42px;
		margin-right: 20px;
	}

	.top {
		display: flex;
		justify-items: center;
		align-items: center;

		.typename {
			color: #FF6A00;
			font-size: 24px;
			font-weight: bold;
		}

		.icon {
			width: 40px;
			height: 40px;
			margin-right: 10px;
		}
	}

	.con {
		margin-top: 20px;
		font-size: 16px;
		color: #333333;
	}

	.hotellist {
		margin-top: 40px;
		// padding: 30rpx;
		background-color: #fff;
		font-size: 24px;
		// font-family: PingFang SC, PingFang SC;
		font-weight: 400;
		color: #333333;
		// line-height: 32rpx;
		// font-weight: bold;

		.hotelitem {
			display: flex;
			margin-top: 20px;
			// width: 690rpx;

			.right {
				margin-left: 30rpx;
				width: 500rpx;

				.imgs {
					display: flex;
					margin-bottom: 30rpx;

					.imgsitem {
						display: flex;
						flex-direction: column;
						justify-items: center;
						align-items: center;
						font-size: 28rpx;
						margin-right: 40px;
					}
				}

				.hotelbtm {
					display: flex;
					justify-content: flex-end;
					align-items: center;
					font-size: 32rpx;
					font-family: PingFang SC, PingFang SC;
					font-weight: 400;
					color: #FF6A00;
				}
			}

		}
	}

	.ruzhu {
		margin-top: 40px;
		// padding: 30rpx;
		background-color: #fff;
		font-size: 24px;
		// font-family: PingFang SC, PingFang SC;
		font-weight: 400;
		color: #333333;
		// font-weight: bold;
	}

	.timeicon {
		width: 40px;
		height: 48px;
	}

	.info {
		margin-top: 40px;
		margin-bottom: 20px;
		color: #333333;
		font-size: 24px;
	}

	.info1 {
		// margin-top: 40px;
		margin-bottom: 20px;
		color: #333333;
		font-size: 24px;
	}

	.info2 {
		// margin-top: 40px;
		margin-bottom: 20px;
		color: #333333;
		font-size: 20px;
	}

	.info3 {
		margin-bottom: 20px;
		margin-top: 20px;
		color: #333333;
		font-size: 20px;
	}

	.infoicon {
		width: 20px;
		height: 20px;
		margin-right: 10px;
	}

	.roomitem {
		width: 430px;
		height: 160px;
		padding: 20px;
		border: 1px solid #eeeeee;
		margin-right: 20px;

		.roomname {
			color: #333333;
			font-size: 20px;
			margin-bottom: 20px;
			font-weight: bold;
		}

		.roomdesc {
			margin-top: 20px;
			display: flex;
			align-items: center;

			.roomdesctitle {
				color: #666666;
				font-size: 16px;
				width: 64px;
				margin-right: 20px;

			}

			.roomdesccon {
				color: #333333;
				font-size: 16px;
			}
		}
	}

	.peoplelist {
		margin-top: 20px;
		padding-left: 20px;
		display: flex;
		flex-wrap: wrap;

		.peopleitem {
			width: 400px;
			margin-bottom: 20px;
			margin-right: 40px;
			/* height: 150px; */
			padding: 20px;
			border: 1px solid #eee;

			.itemtop {
				display: flex;
				justify-content: space-between;
				font-size: 16px;
				// color: #FF6A00;
			}

			.itemdesc {
				margin-top: 20px;
				font-size: 16px;
				color: #333333;
			}
		}
	}
</style>