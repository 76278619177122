import Vue from 'vue'
import {
	createRouter,
	createWebHistory,
	RouteRecordRaw,
	createWebHashHistory
} from 'vue-router'
import Home from '@/view/index'
import my from '@/view/My/index'

const routes = [

	{
		path: '/',
		name: 'home',
		meta: {
			title: '首页'
		},
		component: () => import('@/view/index')
	},
	{
		path: '/my',
		name: 'my',
		meta: {
			title: '我的'
		},
		component: () => import('@/view/My/index')
	},
	{
		path: '/login',
		name: 'login',
		meta: {
			title: '登陆'
		},
		component: () => import('@/view/login/index')
	},
	{
		path: '/activities',
		name: 'activities',
		meta: {
			title: '活动'
		},
		component: () => import('@/view/activities/index')
	},
	{
		path: '/activitiesDesc',
		name: 'activitiesDesc',
		meta: {
			title: '活动详情'
		},
		component: () => import('@/view/activities/activitiesDesc')
	},

	{
		path: '/registration',
		name: 'registration',
		meta: {
			title: '报名'
		},
		component: () => import('@/view/My/registration')
	},
	{
		path: '/viplist',
		name: 'viplist',
		meta: {
			title: '客户级别'
		},
		component: () => import('@/view/activities/viplist')
	},
	{
		path: '/guestlist',
		name: 'guestlist',
		meta: {
			title: '嘉宾列表'
		},
		component: () => import('@/view/activities/guestlist')
	},
	{
		path: '/meetingguestlist',
		name: 'meetingguestlist',
		meta: {
			title: '会议嘉宾列表'
		},
		component: () => import('@/view/activities/meetingguestlist')
	},
	{
		path: '/meetingguestdesc',
		name: 'meetingguestdesc',
		meta: {
			title: '会议嘉宾列表'
		},
		component: () => import('@/view/activities/meetingguestdesc')
	},
	{
		path: '/guestdesc',
		name: 'guestdesc',
		meta: {
			title: '嘉宾介绍'
		},
		component: () => import('@/view/activities/guestdesc')
	},
	{
		path: '/activitiesimgs',
		name: 'activitiesimgs',
		meta: {
			title: '活动图片'
		},
		component: () => import('@/view/activities/activitiesimgs')
	},
	{
		path: '/beforemeeting',
		name: 'beforemeeting',
		meta: {
			title: '同期活动'
		},
		component: () => import('@/view/activities/beforemeeting')
	},
	{
		path: '/subregistration',
		name: 'subregistration',
		meta: {
			title: '报名'
		},
		component: () => import('@/view/activities/subregistration')
	},
	{
		path: '/hotelList',
		name: 'hotelList',
		meta: {
			title: '酒店列表'
		},
		component: () => import('@/view/activities/hotelList')
	},

	{
		path: '/hotelDesc',
		name: 'hotelDesc',
		meta: {
			title: '酒店详情'
		},
		component: () => import('@/view/activities/hotelDesc')
	},
	{
		path: '/hotelorder',
		name: 'hotelorder',
		meta: {
			title: '酒店详情'
		},
		component: () => import('@/view/activities/hotelorder')
	},
	{
		path: '/orderpay',
		name: 'orderpay',
		meta: {
			title: '支付'
		},
		component: () => import('@/view/activities/orderpay')
	},
	{
		path: '/uppingzheng',
		name: 'uppingzheng',
		meta: {
			title: '上传凭证'
		},
		component: () => import('@/view/activities/uppingzheng')
	},
	{
		path: '/pingzheng',
		name: 'pingzheng',
		meta: {
			title: '查看凭证'
		},
		component: () => import('@/view/My/pingzheng')
	},
	{
		path: '/shop',
		name: 'shop',
		meta: {
			title: '商城'
		},
		component: () => import('@/view/shop/index')
	},
	{
		path: '/shopdesc',
		name: 'shopdesc',
		meta: {
			title: '商品详情'
		},
		component: () => import('@/view/shop/shopdesc')
	},
	{
		path: '/submitorder',
		name: 'submitorder',
		meta: {
			title: '提交订单'
		},
		component: () => import('@/view/shop/submitorder')
	},
	{
		path: '/meeting',
		name: 'meeting',
		meta: {
			title: '会议服务'
		},
		component: () => import('@/view/meeting/index')
	},
	{
		path: '/announcement',
		name: 'announcement',
		meta: {
			title: '公告列表'
		},
		component: () => import('@/view/announcement/index')
	},
	{
		path: '/site',
		name: 'site',
		meta: {
			title: '精选场地'
		},
		component: () => import('@/view/site/index')
	},
	{
		path: '/meetingDesc',
		name: 'meetingDesc',
		meta: {
			title: '会议详情'
		},
		component: () => import('@/view/activities/meetingDesc')
	},
	{
		path: '/actiConfDetail',
		name: 'actiConfDetail',
		meta: {
			title: '会议配置详情'
		},
		component: () => import('@/view/activities/actiConfDetail')
	},

	{
		path: '/announDesc',
		name: 'announDesc',
		meta: {
			title: '公告详情'
		},
		component: () => import('@/view/announcement/announDesc')
	},
	{
		path: '/confDesc',
		name: 'confDesc',
		meta: {
			title: '活动配置详情'
		},
		component: () => import('@/view/activities/confDesc')
	},

	{
		path: '/siteDesc',
		name: 'siteDesc',
		meta: {
			title: '场地详情'
		},
		component: () => import('@/view/site/siteDesc')
	},
	{
		path: '/meetingConf',
		name: 'meetingConf',
		meta: {
			title: '会议配置详情'
		},
		component: () => import('@/view/activities/meetingConf')
	},


]



const router = createRouter({
	history: createWebHashHistory(),
	routes
});
// router.beforeEach((to, from, next) => {
//   /* 路由发生变化修改页面title */
//   if (to.meta.title) {
//     document.title = to.meta.title
//   }
//   next()
// })
export default router