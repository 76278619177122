<template>
	<div style="background-color: #fff;padding: 20px;
				background: #fff;
				width: 1000px;">
		<div v-if="!slected">


			<div style="display: flex;justify-content: space-between;">
				<div style="color: #333333;font-size: 24px;padding-left: 10px;border-left: 4px solid #FF6A00;">
					团队人员
				</div>
				<div class="btn" @click="goback">
					返回
				</div>
			</div>
			<div style="display: flex;margin-top: 20px;">
				<div class="addbtn" @click="addpeople">
					<img class="icon"  src="../assets/my/t11.png" alt="" /> 添加
				</div>
				<div class="addbtn">


					<el-upload class="avatar-uploader" action="#" :show-file-list="false"
						:on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload"
						:http-request="($event) => upload2($event)">
						<img class="icon" src="../assets/my/t12.png" alt="" />
						<span style="color: #FF6A00;">导入</span>
					</el-upload>

				</div>

				<div class="addbtn" style="width: 166px;" @click="down">
					<img class="icon" src="../assets/my/t13.png" alt="" /> 下载导入模板
				</div>
				<div class="addbtn"  @click="teamexport" >
					<img class="icon" src="../assets/my/t14.png" alt="" /> 导出
				</div>

			</div>
			<div style="margin-top: 20px;">
				<el-table :data="tableData" style="width: 100%">
					<el-table-column prop="real_name" label="姓名" width="180">
					</el-table-column>
					<el-table-column prop="mobile" label="手机号" width="180">
					</el-table-column>
					<el-table-column prop="email" label="邮箱">
					</el-table-column>
					<el-table-column prop="state" label="状态">
						<!-- "status": 1, // 1.未审核 2.通过 3.驳回 -->
						<template #default="scope">
							<img v-show="scope.row.status==1" style="width: 87px;height: 30px;" src="../assets/my/t01.png" alt="" />
							<img  v-show="scope.row.status==2"style="width: 87px;height: 30px;" src="../assets/my/t04.png" alt="" />
							<img v-show="scope.row.status==3" style="width: 87px;height: 30px;" src="../assets/my/t03.png" alt="" />
						</template>
					</el-table-column>
					<el-table-column label="操作">
						<template #default="scope">
							<el-icon v-show="scope.row.status==3" color="#FF6A00" @click="descclick(scope.row)">
								<Edit />
							</el-icon>
							<el-icon v-show="scope.row.status==2" color="#FF6A00" @click="look(scope.row)">
								<Document />
							</el-icon>
							<el-icon v-show="scope.row.status==1" color="#FF6A00" @click="look(scope.row)">
								<Document />
							</el-icon>
							<!-- <el-icon><Document /></el-icon> -->
							<!-- <i class="el-icon-tickets"></i> -->
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
		<myteamDesc :userid="userid" v-if="slected==1"></myteamDesc>
		<myteamadd :userid="userid" v-if="slected==2"></myteamadd>
	</div>
</template>

<script>
	import myteamDesc from '@/components/myteamDesc'
	import myteamadd from '@/components/myteamadd'
	import {
		ElMessage
	} from 'element-plus'
	import {
		authenticationList,
		getauthGroupImportTemplate,

	} from "@/utils/Myapi.js"
	import {url_config} from "@/utils/baseUrl"
	import {
		newfileUpload,
		authGroupImport,
		downBlobFile,
		authGroupExport
	} from '@/utils/upfile.js'
	export default {
		// 组件名
		name: 'heAd',
		// 注册组件
		components: {
			myteamDesc,
			myteamadd
		},
		// 接收父组件数据
		props: {},
		// 组件数据
		data() {
			return {
				slected: 0,
				tableData: [],
				activeName: '',
				FullShow: true,
				dialogVisible: false,
				status: '',
				lang: '',
				input3: "",
				titles: [],
				userid: [],
			}
		},
		// 计算属性login
		computed: {},
		// 监听属性
		watch: {},
		// 生命周期钩子函数
		created() {


		},
		mounted() {
			this.getdesc()
		},
		// 组件方法
		methods: {

			teamexport() {
				console.log(1111111);
				authGroupExport().then(res => {
					if (res.code == 200) {
						console.log('zires======', res);
						// const blob = res.data.url
						// if (blob && blob.size === 0) {
						// 	return
						// }
						let eleLink = document.createElement('a')
						eleLink.download = '';
						eleLink.href =  res.data.url;
						document.body.appendChild(eleLink);
						eleLink.click();
						eleLink.remove();
						URL.revokeObjectURL(res.data.url);
						
						// const link = document.createElement('a')
						// link.href = window.URL.createObjectURL(blob)
						// link.download = "团体成员.xlsx"
						// document.body.appendChild(link)
						// link.click()
						// window.setTimeout(function() {
						// 	window.URL.revokeObjectURL(blob)
						// 	document.body.removeChild(link)
						// }, 0)

					}
				})
			},
			goback() {
				console.log('zi组件方法');
				this.$parent.mychange();
				// this.getdesc()
			},
			fatherMethod() {
				this.slected = 0
				this.getdesc()
				console.log('父组件方法');
			},
			getdesc() {
				//我的团队
				authenticationList().then(res => {
					if (res.code == 200) {
						this.tableData = res.data.data

					}
				})

			},
			down() {
				// getauthGroupImportTemplate().then(res => {
				// })
				let eleLink = document.createElement('a')
				eleLink.download = '团队';
				eleLink.href = url_config+'/api/user/authGroupImportTemplate';
				document.body.appendChild(eleLink);
				eleLink.click();
				eleLink.remove();
				URL.revokeObjectURL( url_config+'/api/user/authGroupImportTemplate');
			},
			descclick(item) {

				console.log("item===", item);

				this.userid = item.id

				this.slected = 2

			},
			look(item){
				this.userid = item.id
				
				this.slected = 1
			},
			addpeople(item) {

				this.userid = item.id

				this.slected = 2
			},
			upload2(e, name) {
				const loading = this.$loading({
					lock: true,
					text: '正在上传，请耐心等候！',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)',
				})
				let file = e.file
				console.log(file)
				let formData = new FormData()
				formData.append('file', file)
				authGroupImport(formData).then((res) => {
					console.log(res, 'file')
					if (res.code == 200) {
						loading.close()
						ElMessage({
							message: '导入成功',
							type: 'success',
						})
						this.getdesc()
					}
				})
			},

		},
	}
</script>

<style scoped lang="scss">
	.grop {
		display: flex;
		flex-wrap: wrap;

		.gropitem {
			width: 240px;
			height: 70px;
			padding: 20px;
			display: flex;

			border-radius: 10px 10px 10px 10px;
			border: 1px solid #EEEEEE;

			.icon {
				width: 70px;
				height: 70px;
				margin-right: 20px;
				margin-right: 20px;
			}

			.number {

				font-size: 24px;
				color: #333333;
			}

			.title {
				margin-top: 12px;
				color: #666666;

				font-size: 16px;
			}
		}
	}

	.btn {
		width: 112px;
		height: 42px;
		border-radius: 26px;
		border: 1px solid #FF6A00;
		color: #FF6A00;
		text-align: center;
		line-height: 42px;
	}

	.zhongjiang {

		background-color: #fff;
		// border-radius: 10rpx;
		// margin: 30rpx;
		margin-top: 40rpx;

		.top {
			height: 60px;
			margin-left: auto;
			margin-right: auto;
			// border-bottom: 1rpx solid #FFFFFF;
			color: #fff;
			font-size: 20px;
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;

			.title {
				font-size: 20px;
				font-weight: bold;
			}

			.iconleft {
				width: 28px;
				height: 20px;
				margin-right: 20px;
			}

			.iconright {
				width: 28px;
				height: 20px;
				margin-left: 20px;
			}

		}

		.list {
			font-size: 16px;
			font-family: PingFang SC, PingFang SC;
			font-weight: 400;
			color: #FFFFFF;
			margin: 20px;
			// padding-bottom: 30rpx;
			// margin-top: 0rpx;
			// padding-top: 30rpx;

			.listitem {
				display: flex;
				justify-content: space-around;
				align-items: center;
				height: 70px;
				border-bottom: 1px solid #EEEEEE;

				.time {
					color: #666666;
					font-size: 16px;
					margin-top: 23rpx;

				}

				.name {
					color: #333333;
					font-size: 16px;
				}

				.fenshu {
					line-height: 70px;
					font-size: 20px;
					color: #FF6A00;
				}
			}

		}

	}

	.addbtn {
		width: 112px;
		height: 42px;
		border-radius: 26px;
		border: 1px solid #FF6A00;
		color: #FF6A00;
		text-align: center;
		display: flex;
		// line-height: 42px;
		align-items: center;
		justify-content: center;
		margin-right: 40px;
	}

	.icon {
		width: 20px;
		height: 20px;
		margin-right: 10px;
	}
</style>