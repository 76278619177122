<template>
	<div style="background-color: #fff;padding: 20px;
				background: #fff;
				width: 1000px;">
		<div style="display: flex;justify-content: space-between;align-items: center;">
			<div style="color: #333333;font-size: 24px;padding-left: 10px;border-left: 4px solid #FF6A00;"> 我的收藏 </div>
			<div
				class="btn"
				@click="goback"
			> 返回 </div>
		</div>
		<el-tabs
			v-model="activeName"
			@tab-click="handleClick"
		>
			<el-tab-pane
				label="收藏会议"
				name="first"
			></el-tab-pane>
			<el-tab-pane
				label="收藏商品"
				name="second"
			></el-tab-pane>
		</el-tabs>
		<div>
			<div
				class="goodlist"
				v-if="slected == 1"
			>
				<div
					class="good"
					@click="descclick(item.goods)"
					v-for="(item, index) in list"
				>
					<!-- {{item.goods}} -->
					<img
						class="img"
						:src="item.goods.cover_img"
						alt=""
					>
					<div class="goodname">{{ item.goods.name }}</div>
					<div
						v-show="item.showtype == 3"
						style="display: flex;margin-top: 10px;color: #FF6A00;"
					>
						<div><span style="font-size: 14px;">￥</span>{{ item.goods.spec[0].price }}</div>
						<div>+</div>
						<img
							class="jifen"
							src="../assets/my/jifen.png"
							alt=""
						/>
						<div>{{ item.goods.spec[0].point }}</div>
					</div>
					<div
						v-show="item.showtype == 2"
						style="display: flex;margin-top: 10px;color: #FF6A00;"
					>
						<div><span style="font-size: 14px;">￥</span>{{ item.goods.spec[0].price_all }}</div>
					</div>
					<div
						v-show="item.showtype == 1"
						style="display: flex;margin-top: 10px;color: #FF6A00;"
					>
						<img
							class="jifen"
							src="../assets/my/jifen.png"
							alt=""
						/>
						<div>{{ item.goods.spec[0].point_all }}</div>
					</div>
				</div>
			</div>
			<div
				class="huiyilist"
				v-if="slected == 0"
			>
				<!-- <div>{{list}}</div> -->
				<div
					class="huiyi"
					v-for="(item, index) in list"
					@click="meetDesc(item.meeting)"
				>
					<img
						class="img"
						v-if="item.meeting"
						:src="item.meeting.cover_str"
						alt=""
					/>
					<div class="topdiv" v-if="item.meeting">
						<div
							class="typeicon"
							v-show="item.type == 1"
						> {{ item.meeting.main_type_name }} </div>
						<div
							class="typeicon"
							v-show="item.type == 2"
						> {{ item.meeting.sub_type_name }} </div>
						<img
							@click.stop="setcollect(item.meeting)"
							v-show="item.meeting.is_collect == 1"
							class="SCollection"
							src="../assets/my/SCollection.png"
							alt=""
						/>
						<img
							@clickstop="setcollect(item.meeting)"
							v-show="item.meeting.is_collect == 0"
							class="SCollection"
							src="../assets/shoucang.png"
							alt=""
						/>
					</div>
					<div class="huiyiname" v-if="item.meeting">{{ item.meeting.name }}</div>
					<div class="huiyitime" v-if="item.meeting">
						<!-- {{ item.meeting.create_time }} -->
						{{item.meeting.start_time_str.substring(0, 10)}}至{{item.meeting.end_time_str.substring(0, 10)}}
							每日{{item.meeting.start_time_str.substring(10, 19)}}-{{item.meeting.end_time_str.substring(10, 19)}}
					</div>
					<div style="display: flex;align-items: center;color: #FF6A00;" v-if="item.meeting">
						<div class="huiyiprice">
							<span style="font-size: 14px;">￥</span> {{ itemprice(item.meeting.spec) }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		ElMessage
	} from 'element-plus'

	import {
		collect
	} from "@/utils/api"

	import {
		myUserCollect,
	} from "@/utils/Myapi"

	export default {
		// 组件名
		name: 'heAd',
		// 注册组件
		components: {},
		// 接收父组件数据
		props: {},
		// 组件数据
		data() {
			return {
				activeName: 'first',
				FullShow: true,
				dialogVisible: false,
				status: '',
				lang: '',
				list: [],
				input3: "",
				slected: 0,
				pageSize: {
					count: 0,
					limit: 0,
					page: 1,
					type: 1
				}
			}
		},
		// 计算属性login
		computed: {},
		// 监听属性
		watch: {},
		// 生命周期钩子函数
		created() {


		},
		mounted() {
			this.getdata()
		},
		// 组件方法
		methods: {
			setcollect(item) {
				collect({
					"id": item.id
				}).then(res => {
					if (res.code == 200) {
						ElMessage({
							message: '取消成功',
							type: 'success',
						})
					}
				})
			},
			descclick(item) {
				this.$router.push({
					name: 'shopdesc',
					query: {
						'shop_id': item.id
					}
				})
			},
			goback() {
				console.log('zi组件方法');
				this.$parent.fatherMethod();
			},
			itemprice(data) {
				if (data[0]) {
					var item = data[0]
					return item.price
				}

			},
			getdata() {
				//活动
				myUserCollect(this.pageSize).then(res => {
					if (res.code == 200) {



						if (this.slected == 1) {
							for (var item of res.data.data) {
								for (var item of res.data.data) {
									if (item.goods.point_price_arr.includes('1')) {
										item.showtype = 1

									} else if (item.goods.point_price_arr.includes('2')) {
										item.showtype = 2

									} else if (item.goods.point_price_arr.includes('3')) {
										item.showtype = 3

									}
								}
							}
						}

						this.list = res.data.data
					}
				})
			},
			meetDesc(item) {
				this.$router.push({
					name: 'meetingDesc',
					query: {
						'meet_id': item.id
					}
				})
			},
			handleClick(e) {
				console.log(e.index);
				this.slected = e.index
				if (e.index == 0) {
					this.pageSize.type = 1
				} else {
					this.pageSize.type = 2
				}
				this.list = []
				this.getdata()
			},
		},
	}
</script>
<style
	scoped
	lang="scss"
>
	.list {

		.listitem {
			width: 920px;
			padding: 20px;
			// height: 290px;

			border: 1px solid #EEEEEE;

			.top {
				display: flex;
				justify-content: space-between;
			}

			.desc {
				margin-top: 20px;
				color: #333333;
				font-size: 16px;
			}
		}
	}

	.btn {
		width: 112px;
		height: 42px;
		border-radius: 26px;
		border: 1px solid #FF6A00;
		color: #FF6A00;
		text-align: center;
		line-height: 42px;
	}



	::v-deep .el-tabs__item.is-active {
		color: #FF6A00; //选中
		opacity: 1;
	}

	::v-deep .el-tabs__item:hover {
		color: #FF6A00; //悬浮
		cursor: pointer;
		opacity: 1;
	}

	/* 下划线颜色 */
	::v-deep .el-tabs__active-bar {
		background-color: #FF6A00;
	}

	::v-deep .el-tabs__nav-wrap::after {

		position: static !important;

	}

	.goodlist {

		display: flex;
		flex-wrap: wrap;

		.good {
			// display: flex;
			// padding: 20px;
			margin-top: 20px;
			margin-right: 20px;
			// border: 1px solid #EEEEEE;
			justify-content: space-between;
			width: 225px;
			height: 338px;

			.img {
				width: 225px;
				height: 225px;
				margin-bottom: 10px;
			}

			.jifen {
				width: 20px;
				height: 20px;
				margin-right: 5px;
			}

			.goodname {
				font-size: 20px;
				color: #333333;
				margin-bottom: 10px;


			}

			.price {
				font-size: 24px;
			}

			.goodguige {
				font-size: 16px;
				color: #333333;
			}

			.goodgnumber {
				font-size: 16px;
				color: #666;
			}

			.goodtype {

				color: #FF6A00;
				font-size: 16px;
			}
		}
	}

	.huiyilist {
		display: flex;
		flex-wrap: wrap;

		.huiyi {
			width: 300px;
			height: 320px;
			margin-top: 20px;
			margin-right: 20px;

			.img {
				width: 100%;
				height: 200px;
			}

			.huiyiname {
				font-size: 20px;
				color: #333333;
			}

			.huiyitime {
				font-size: 16px;
				margin-top: 10px;
				margin-bottom: 10px;
				color: #666666;
			}

			.huiyiprice {
				color: #FF6A00;
				font-size: 24px;
			}

			.typeicon {
				// height: 28px;
				background-color: #FF6A00;
				border-radius: 10px 0px 10px 0px;
				padding: 5px 10px;
				color: #fff;
			}

			.SCollection {
				width: 20px;
				height: 20px;
				margin-right: 10px;
				// position: relative;
				// top: -205px;
				// right: -220px;
			}
		}
	}

	.topdiv {
		display: flex;
		position: relative;
		top: -205px;
		justify-content: space-between;
		align-items: center;

		/* margin-left: 10px; */
	}

</style>