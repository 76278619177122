<template>
	<div style="background-color: #fff;padding: 20px;
				background: #fff;
				width: 1000px;">
		<div v-if="slected==0">


			<div
				style="color: #333333;font-size: 24px;padding-left: 10px;border-left: 4px solid #FF6A00;margin-bottom: 20px;">
				酒店订单
			</div>
			<el-tabs v-model="activeName" @tab-click="handleClick">
				<el-tab-pane label="全部" name="first"></el-tab-pane>
				<el-tab-pane label="待支付" name="second"></el-tab-pane>
				<el-tab-pane label="支付审核中" name="third"></el-tab-pane>
				<el-tab-pane label="已支付" name="fourth"></el-tab-pane>
				<el-tab-pane label="已完成" name="five"></el-tab-pane>
			</el-tabs>
			<div>
				<div class="goodlist">
					<div class="good" v-for="(item,index) in list">
						<div style="display: flex;justify-content: space-between;">
							<div style="display: flex;">
								<div>
									<div class="goodguige">
										酒店名称：{{item.hotel.name}}
									</div>
									<div class="goodguige" style="margin-top: 10px;margin-bottom: 10px;">
										酒店地址：{{item.hotel.place}}
									</div>
									<div class="goodguige" style="margin-top: 10px;margin-bottom: 10px;">
										入住周期：{{item.start_time_str}} 至 {{item.end_time_str}}
									</div>
									<div class="goodguige" style="margin-top: 10px;margin-bottom: 10px;">
										预定房型：{{item.child[0].room_name}}*{{item.child.length}}
									</div>
								</div>
							</div>
							<div class="goodtype">
								{{item.status_str}}
							</div>
						</div>

						<div>
							<el-divider></el-divider>
							<div style="display: flex;justify-content: space-between;">
								<div>

								</div>
								<div style="display: flex;">
									<div class="btn" v-show="item.status==1" @click.stop="chanel(item)">
										取消
									</div>
									<div class="btn" v-show="item.status==4" @click.stop="dele(item)">
										删除
									</div>
									<div class="btn" v-show="item.pay_status==2&&item.status==1"
										@click="gopingzheng(item)">
										查看凭证
									</div>
									<div class="btn" @click="desclick(item)">
										查看详情
									</div>
									<div class="btn1" v-show="item.status==1" @click.stop="payclick(item)">
										去支付
									</div>

								</div>

							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
		<myhotelorderDesc :id='orderinfo.id' v-if="slected==1"></myhotelorderDesc>
	</div>
</template>


<script>
	import {
		hotelOrderList,
		hotelOrderDetail,
		hotelOrdercancel,
		hotelOrderdelete
	} from "@/utils/Myapi.js"
	import myhotelorderDesc from '@/components/myhotelorderDesc'
	import img1 from "@/assets/my/t1.png";
	import img2 from "@/assets/my/t2.png";
	import img3 from "@/assets/my/t3.png";
	import img4 from "@/assets/my/t4.png";
	import img5 from "@/assets/my/t5.png";
	import img6 from "@/assets/my/t6.png";
	import img7 from "@/assets/my/t7.png";
	import {
		ElMessage,
		ElMessageBox
	} from 'element-plus'
	export default {
		// 组件名
		name: 'heAd',
		// 注册组件
		components: {
			myhotelorderDesc
		},
		// 接收父组件数据
		props: {},
		// 组件数据
		data() {
			return {
				slected: 0,
				activeName: 'first',
				FullShow: true,
				dialogVisible: false,
				status: '',
				lang: '',
				orderinfo: {},
				input3: "",
				list: [],
				titles: [],
				pagesize: {
					status: ''
				}
			}
		},
		// 计算属性login
		computed: {},
		// 监听属性
		watch: {},
		// 生命周期钩子函数
		created() {


		},
		mounted() {
			this.getdata()
		},
		// 组件方法
		methods: {
			gopingzheng(item) {
				
				
				this.openUrl = 'http://exhibition-pc.huijik.com/#/pingzheng?from=3&id=' + item.id;
				
				window.open(this.openUrl);
				
				// this.$router.push({
				// 	name: 'pingzheng',
				// 	query: {
				// 		'id': item.id,
				// 		'from': '1'
				// 	}
				// })
				
				
				
			},
			fatherMethod() {
				this.slected = 0
				console.log('父组件方法');
			},
			payclick(item) {
				this.$router.push({
					name: 'orderpay',
					query: {
						'order_id': item.id,
						"order_no": item.orderno,
						"pay_price": item.pay_price,
						"from": '3',
						offline:JSON.stringify({
							offline_payname:item.hotel.payee,
							offline_bankname:item.hotel.bank_name,
							offline_payaccount:item.hotel.bank_account
						})
					}
				})
			},
			dele(item) {
				ElMessageBox.confirm(
						'您确定要，删除订单', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							confirmButtonClass: 'btnConfirm',
							center: true,
						}
					)
					.then(() => {
						this.deleOrder(item.id)
					})
					.catch(() => {})

			},
			deleOrder(id) {
				hotelOrderdelete({
					'id': id
				}).then(res => {
					if (res.code == 200) {
						ElMessage({
							message: '删除成功',
							type: 'success',
						})
						this.getdata()
					}
				})
			},
			chanel(item) {
				ElMessageBox.confirm(
						'您确定要，取消订单', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							confirmButtonClass: 'btnConfirm',
							center: true,
						}
					)
					.then(() => {
						this.cancelOrder(item.id)
					})
					.catch(() => {})

			},
			cancelOrder(id) {
				hotelOrdercancel({
					'id': id
				}).then(res => {
					if (res.code == 200) {
						ElMessage({
							message: '取消成功',
							type: 'success',
						})
						this.getdata()
					}
				})
			},
			getdata() {
				hotelOrderList(this.pagesize).then(res => {
					if (res.code == 200) {
						this.list = res.data.data
					}
				})
			},
			desclick(item) {
				this.slected = 1;
				this.orderinfo = item
			},
			handleClick(e) {
				console.log(e.index)
				if(e.index == 0){
					this.pagesize.pay_status = ''
				}else{
					this.pagesize.pay_status = e.index
				}
				
				this.getdata()

			},
		},
	}
</script>
<style lang='scss'>
	.btnConfirm {
		background: #FF6A00 !important;
		color: #fff !important;
		border: 1px solid #FF6A00 !important;
	}

	.btnCancel {
		background: #009BF4 !important;
		color: #FFFFFF !important;
	}

	.el-button:first-child:hover {
		color: #333 !important;
		background-color: #fff !important;
		border-color: #999 !important;
	}

	.el-button:nth-child(2):hover {
		color: #FFFFFF !important;
		border-color: #FF6A00 !important;
		background-color: #FF6A00 !important;
	}
</style>
<style scoped lang="scss">
	.list {

		.listitem {
			width: 920px;
			padding: 20px;
			// height: 290px;

			border: 1px solid #EEEEEE;

			.top {
				display: flex;
				justify-content: space-between;
			}

			.desc {
				margin-top: 20px;
				color: #333333;
				font-size: 16px;
			}
		}
	}

	.btn {
		width: 112px;
		height: 42px;
		border-radius: 26px;
		border: 1px solid #FF6A00;
		color: #FF6A00;
		text-align: center;
		line-height: 42px;
		margin-right: 20px;
	}

	.btn1 {
		width: 112px;
		height: 42px;
		border-radius: 26px;
		color: #fff;
		background-color: #FF6A00;
		text-align: center;
		line-height: 42px;
		margin-right: 20px;
	}


	::v-deep .el-tabs__item.is-active {
		color: #FF6A00; //选中
		opacity: 1;
	}

	::v-deep .el-tabs__item:hover {
		color: #FF6A00; //悬浮
		cursor: pointer;
		opacity: 1;
	}

	/* 下划线颜色 */
	::v-deep .el-tabs__active-bar {
		background-color: #FF6A00;
	}

	::v-deep .el-tabs__nav-wrap::after {

		position: static !important;

	}

	.goodlist {
		.good {
			// display: flex;
			padding: 20px;
			margin-top: 20px;
			border: 1px solid #EEEEEE;
			justify-content: space-between;

			.icon {
				width: 120px;
				height: 120px;
				margin-right: 20px;
			}

			.goodname {
				font-size: 20px;
				color: #333333;
			}

			.goodguige {
				font-size: 16px;
				color: #333333;
			}

			.goodgnumber {
				font-size: 16px;
				color: #666;
			}

			.goodtype {

				color: #FF6A00;
				font-size: 16px;
			}
		}
	}
</style>