<template>
	<div class='box' style="background-color: #F7F7F7;">
		<heads showindex=3></heads>

		<div class="listview">
			<div class="problemleft">
				<div v-for="(item,index) in titlearry" :key="index" @click="itemclick(index)"
					:class="['problemleftitem',slected == index ? 'on' : '',]">
					<img style="width: 20px;height: 20px;display:block;" :src="slected==index?item.icon:item.icon1"
						alt="">
					{{item.name}}
				</div>
			</div>
			<div class="listbackview" v-if="slected==0">
				<div style="display: flex;">
					<!-- {{userinfo}} -->
					<div class="userinfo">
						<img @click="dialogShow = true" v-if="userinfo.avater_str" :src="userinfo.avater_str" class="myIcon" mode="" ></img>
						<img @click="dialogShow = true" v-if="!userinfo.avater_str" class="myIcon"  src="../../assets/touxiang.png" alt="" />
						<div class="">
							<div class="" style="display: flex;align-items: center;">
								<div class="" style="margin-left: 20px;margin-right: 20px;font-size: 16px;">
									{{userinfo.real_name}}
								</div>
								<img v-show="my_auth_info.status!=2" @click='myinfoclick'
									style="width: 87rpx;height: 30px;margin-left: 30rpx;" src="../../assets/my/myNo.png"
									mode=""></img>
								<img v-show="my_auth_info.status==2" @click='myinfolook'
									style="width: 87rpx;height: 30px;margin-left: 30rpx;" src="../../assets/my/t04.png"
									mode=""></img>
							</div>
							<div class="" style="margin-top: 20px;margin-left: 20px; color: #666;font-size: 16px;">
								{{userinfo.mobile}}
							</div>
						</div>
					</div>

					<div class="tongji" style="border-radius: 10rpx;">
						<div class="item" @click="itemclick(24)">
							<div class="number">
								{{myInfo.group_num}}
							</div>
							<div class="title">
								团队人员
							</div>
						</div>
						<div class="item" @click="itemclick(23)">
							<div class="number">
								{{myInfo.coupon_bill_num}}
							</div>
							<div class="title">
								优惠券
							</div>
						</div>
						<div class="item" @click="itemclick(22)">
							<div class="number">
								{{myInfo.collect_num}}
							</div>
							<div class="title">
								收藏
							</div>
						</div>
						<div class="item" @click="itemclick(20)">
							<div class="number">
								{{userpoint.point}}
							</div>

							<div class="title">
								积分
							</div>

						</div>

					</div>
				</div>

				<div style="color: #333333;font-size: 24px;padding-left: 10px;border-left: 4px solid #FF6A00;margin-top: 40px;"
					v-show="tableData.length">
					我的报名
				</div>
				<div class="list" style="display: flex;margin-top: 20px;">
					<div @click="baomingclick(item)" class="user_for" v-for="(item,id) in tableData" :key="id"
						style="margin-right: 20px;">
						<div class="right_box" style="color: #666;">
							<div class="title" style="display: flex;align-items: center;justify-items: center;">
								<img v-show="item.order_type==2" src="../../assets/my/look1.png"
									style="width: 64px;height: 28px;" mode="">
								</img>
								<img v-show="item.order_type==1" src="../../assets/my/look2.png"
									style="width: 64px;height: 28px;" mode="">
								</img>
								<span style="margin-left: 10px;color: #333333;font-size: 20px;font-weight: bold;"
									@click="Jump(list)">{{item.activity_name}}
								</span>
							</div>
							<div class="title" style="margin-top: 20px;">
								会议名称：{{item.meeting.name}}
							</div>
							<div class="desc">
								会议类型：{{item.meeting.type_name}}
							</div>
							<div class="desc">
								会议时间：{{item.meeting.start_time_str}}到{{item.meeting.end_time_str}}
							</div>
							<div class="desc" style="display: flex;justify-content: space-between;">

								<div class="">
									姓 名：{{userinfo.real_name}}
								</div>
								<div class=""
									style="height: 30px;width: 68px;line-height: 30px; color:#FF6A00 ; background-color:#FFF0E5;border-radius: 15px;font-size: 16px;text-align: center;">
									{{item.type_str}}
								</div>
							</div>
						</div>
					</div>
				</div>

				<div style="color: #333333;font-size: 24px;padding-left: 10px;border-left: 4px solid #FF6A00;margin-top: 40px;"
					v-show="GoodsList.length">
					我的商品订单
				</div>
				<div class="goodlist">
					<div class="good" @click="lookdes(item)" v-for="(item,index) in GoodsList">
						<div style="display: flex;justify-content: space-between;">
							<div style="display: flex;">
								<div>
									<img class="icon" :src="item.cover_img" alt="" />
								</div>
								<div>
									<div class="goodname">
										{{item.goods_name}}
									</div>
									<div class="goodguige" style="margin-top: 10px;margin-bottom: 10px;">
										{{item.goods_spec}}
									</div>
									<div class="goodnumber">
										x{{item.goods_num}}
									</div>
								</div>
							</div>
							<!-- 1.待支付 2.代发货 3.待收货 4.已完成 5.申请售后 6.售后中 7.已取消 8.已退款 , 9 退款中' -->
							<div class="goodtype">
								{{item.my_order_status_str}}
							</div>
							<!-- <div v-show="item.status==2" class="goodtype">
								支付审核中
							</div>
							<div v-show="item.status==3" class="goodtype">
								待发货
							</div>
							<div v-show="item.status==4" class="goodtype">
								待收货
							</div>
							<div v-show="item.status==5" class="goodtype">
								售后中
							</div>
							<div v-show="item.status==6" class="goodtype">
								已完成
							</div>
							<div v-show="item.status==6" class="goodtype">
								已关闭
							</div> -->
						</div>

						<div>
							<!-- <el-divider></el-divider> -->
							<div style="display: flex;justify-content: space-between;">
								<div>

								</div>
								<!-- <div style="display: flex;">
									<div class="btn" v-show="item.status==1" @click="chanelOrder(item)">
										取消
									</div>
									<div class="btn" @click="lookdes(item)">
										查看详情
									</div>
									<div class="btn" v-show="item.pay_status==2">
										查看凭证
									</div>
									<div class="btn" @click="payclick(item)" v-show="item.status==1">
										去支付
									</div>
								</div> -->

							</div>
						</div>
					</div>

				</div>


			</div>
			<Registration v-if="slected==1"></Registration>
			<myactivity v-if="slected==2"></myactivity>
			<myaddress v-if="slected==3"></myaddress>
			<myqiandao v-if="slected==4"></myqiandao>
			<myinvoice v-if="slected==5"></myinvoice>
			<myquestionnaire v-if="slected==6"></myquestionnaire>
			<mygoodorder v-if="slected==7"></mygoodorder>
			<myhotelorder v-if="slected==11"></myhotelorder>
			<aboutUs v-if="slected==12"></aboutUs>

			<myLuckydraw v-if="slected==9"></myLuckydraw>
			<myapplication v-if="slected==10"></myapplication>
			<mychoujiang v-if="slected==8"></mychoujiang>
			<myjifen v-if="slected==20"></myjifen>
			<myteam v-if="slected==24"></myteam>
			<myCoupons v-if="slected==23"></myCoupons>
			<myCollection v-if="slected==22"></myCollection>

			<!-- //个人认证 -->
			<myteamadd :type='1' v-if="slected==26"></myteamadd>
			<myteamDesc :type='1' v-if="slected==28"></myteamDesc>

			<mygoodorderDesc :goodId='slectedInfo.id' v-if="slected==27"></mygoodorderDesc>




		</div>

		<el-dialog v-model="dialogVisible" title="联系我们" width="590" >
			<div class="showmain">
				{{seting.value}}
			</div>
		</el-dialog>
		<el-dialog v-model="dialogShow" title="修改" width="590" >
			<div>
				<el-form :model="formInline" class="demo-form-inline">
					<!-- <el-form-item label="昵称">
						<el-input v-model="formInline.nickname" placeholder="请输入您的昵称"></el-input>
					</el-form-item> -->
					<el-form-item label="头像">
						<el-upload v-model="formInline.avater_avater" action="#" :show-file-list="false" list-type="picture-card"
							:http-request="($event) => upload($event)" :on-remove="handleRemove"
							:on-preview="handlePictureCardPreview">
							<img v-if="formInline.avater_avater" :src="formInline.avater_avater" class="avatar">
							<el-icon v-else>
								<Plus />
							</el-icon>
						</el-upload>
						<el-dialog v-model="dialogshowImg">
							<img style="width: 100%;height:100%;" w-full :src="formInline.avater_avater" alt="Preview Image" />
						</el-dialog>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="onSubmit">确定</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-dialog>
		<footerview ></footerview>
	</div>
</template>

<script>
	import img01 from "@/assets/my/my01.png";
	import img00 from "@/assets/my/my00.png";
	import img10 from "@/assets/my/my10.png";
	import img11 from "@/assets/my/my11.png";
	import img20 from "@/assets/my/my20.png";
	import img21 from "@/assets/my/my21.png";
	import img30 from "@/assets/my/my30.png";
	import img31 from "@/assets/my/my31.png";
	import img40 from "@/assets/my/my40.png";
	import img41 from "@/assets/my/my41.png";
	import img50 from "@/assets/my/my50.png";
	import img51 from "@/assets/my/my51.png";
	import img60 from "@/assets/my/my60.png";
	import img61 from "@/assets/my/my61.png";
	import img70 from "@/assets/my/my70.png";
	import img71 from "@/assets/my/my71.png";
	import img81 from "@/assets/my/my81.png";
	import img80 from "@/assets/my/my80.png";
	import img90 from "@/assets/my/my90.png";
	import img91 from "@/assets/my/my91.png";
	import img110 from "@/assets/my/my110.png";
	import img111 from "@/assets/my/my111.png";
	import img120 from "@/assets/my/my120.png";
	import img121 from "@/assets/my/my121.png";
	import img130 from "@/assets/my/my130.png";
	import img131 from "@/assets/my/my131.png";
	import img140 from "@/assets/my/my140.png";
	import img141 from "@/assets/my/my141.png";

	import heads from '@/components/head'
	import Registration from '@/components/myRegistration'
	import myaddress from '@/components/myaddress.vue'
	import myactivity from '@/components/myactivity.vue'
	import myqiandao from '@/components/myqiandao.vue'
	import myinvoice from '@/components/myinvoice.vue'
	import myquestionnaire from '@/components/myquestionnaire.vue'
	import mygoodorder from '@/components/mygoodorder.vue'
	import myhotelorder from '@/components/myhotelorder.vue'
	import myjifen from '@/components/myjifen.vue'
	import myLuckydraw from '@/components/myLuckydraw.vue'
	import myapplication from '@/components/myapplication.vue'
	import mychoujiang from '@/components/mychoujiang.vue'
	import myteam from '@/components/myteam.vue'
	import myCoupons from '@/components/myCoupons.vue'
	import myCollection from '@/components/myCollection.vue'
	import aboutUs from '@/components/aboutUs.vue'
	import myteamadd from '@/components/myteamadd'
	import mygoodorderDesc from '@/components/mygoodorderDesc'
	import myteamDesc from '@/components/myteamDesc'
    import footerview from '@/components/footer'
	import {
		user_info,
		mymyGoodsList,
		myInfo,
		myActivity,
		editInfo
	} from "@/utils/Myapi.js"

	import {
		newfileUpload
	} from '@/utils/upfile.js'
	import {
		config,
		hotelroom,
		meetingList,
		myMeetingApplication,
		hotelraddOrder,
		meetingCancelApply,
		meetingChildDel
	} from "@/utils/api"
	export default {
		// 组件名
		name: 'my',
		// 注册组件
		components: {

			heads,
			Registration,
			myaddress,
			myactivity,
			myqiandao,
			myinvoice,
			myquestionnaire,
			mygoodorder,
			myhotelorder,
			myjifen,
			myLuckydraw,
			myapplication,
			mychoujiang,
			myteam,
			myCoupons,
			myCollection,
			aboutUs,
			myteamadd,
			mygoodorderDesc,
			myteamDesc,
			footerview
		},
		// 接收父组件数据
		props: {},
		// 组件数据
		data() {
			return {
				userid: '',
				lang: '',
				banner: [],
				dialogVisible: false,
				dialogShow: false,
				info: [],
				slected: 0,
				userinfo: {},
				userpoint: {},
				myInfo: {},
				GoodsList: [],
				Application: [],
				Activitys: [],
				tableData: [],
				seting: {},
				slectedInfo: {},
				titlearry: [{
						'name': '基本信息',
						'icon1': img00,
						'icon': img01
					},
					{
						'name': '我的报名',
						'icon1': img10,
						'icon': img11
					},
					{
						'name': '我的活动',
						'icon1': img20,
						'icon': img21
					},
					{
						'name': '我的地址',
						'icon1': img30,
						'icon': img31
					},
					{
						'name': '签到记录',
						'icon1': img40,
						'icon': img41
					},
					{
						'name': '维护发票',
						'icon1': img50,
						'icon': img51
					},
					{
						'name': '调查问卷',
						'icon1': img60,
						'icon': img61
					},
					{
						'name': '商品订单',
						'icon1': img70,
						'icon': img71
					},
					{
						'name': '抽奖',
						'icon1': img80,
						'icon': img81
					},
					{
						'name': '我的奖品',
						'icon1': img90,
						'icon': img91
					},
					{
						'name': '报名订单',
						'icon1': img110,
						'icon': img111
					},
					{
						'name': '酒店订单',
						'icon1': img120,
						'icon': img121
					},
					// {
					// 	'name': '联系客服',
					// 	'icon1': img130,
					// 	'icon': img131
					// },
					{
						'name': '联系我们',
						'icon1': img140,
						'icon': img141
					}
				],
				my_auth_info: {},
				from: '',
				fileList: [],
				slideshowurls: [],
				dialogImageUrl: '',
				dialogshowImg: false,
				formInline:{
					// nickname:'',
					avater: '',
					avater_avater: '',
				}
			}
		},
		// 计算属性
		computed: {},
		// 监听属性
		watch: {},
		// 生命周期钩子函数
		created() {},
		mounted() {

			this.from = this.$route.query.from,

				this.getdesc()
			if (this.from == 1) {
				this.slected = 7
			}
			if (this.from == 2) {
				this.slected = 10
			}
			if (this.from == 3) {
				this.slected = 11
			}
			if (this.from == 9) {
				this.slected = 9
			}
		},
		// 组件方法
		methods: {
			onSubmit(){
				editInfo(this.formInline).then(res => {
					if(res.code == 200){
						this.getdesc()
						this.dialogShow = false
					}
				})
			},
			upload(e, name) {
				const loading = this.$loading({
					lock: true,
					text: '正在上传，请耐心等候！',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)',
				})
				let file = e.file
				console.log(file)
				let formData = new FormData()
				formData.append('file', file)
				newfileUpload(formData).then((res) => {
					console.log(res, 'file')
					if (res.code == 200) {
						loading.close()
						this.formInline.avater = res.data.id
						this.formInline.avater_avater = res.data.address
					}
				})
			},
			handlePictureCardPreview(file) {
				this.formInline.avater_avater = file.url
				this.dialogshowImg = true
			},
			handleRemove(file, fileList) {
				console.log(file, "file")
				// for (let i in this.slideshowurls) {
				// 	if (this.slideshowurls[i].uid == file.uid || this.slideshowurls[i].address == file.url) {
				// 		 this.slideshowurls.splice(i,1);
				// 	}
				// }
				console.log(this.slideshowurls)
			},
			baomingclick() {

			},
			lookdes(item) {
				this.slectedInfo = item
				this.slected = 27
			},
			fatherMethod() {
				this.slected = 0
				console.log('父组件方法');
				this.getdesc()
			},
			mychange() {
				console.log('my方法');
				this.slected = 0;
				this.getdesc()
			},
			getdesc() {
				config().then(res => {
					if (res.code == 200) {
						this.info = res.data.data
						for (var item of this.info) {
							if (item.key == 'contact') {
								this.seting = item;
							}
						}

					}
				})
				//个人信息
				user_info().then(res => {
					if (res.code == 200) {
						this.userinfo = res.data;
						this.userpoint = res.data
						this.userid = res.data.id
						console.log("this.userpoint", res.data);

					}
				})

				myInfo().then(res => {
					if (res.code == 200) {
						this.myInfo = res.data
						this.my_auth_info = res.data.my_auth_info
						localStorage.setItem("user", JSON.stringify(res.data.user))
						localStorage.setItem("my_auth_info", JSON.stringify(res.data.my_auth_info))

					}
				})
				myMeetingApplication().then(res => {
					if (res.code == 200) {
						this.Application = res.data.data
					}
				})
				mymyGoodsList({
					'page': '1',
					'limit': '4'
				}).then(res => {
					if (res.code == 200) {
						this.GoodsList = res.data.data
					}
				})
				myMeetingApplication({
					'limit': '2',
					'page': '1'
				}).then(res => {
					if (res.code == 200) {
						this.tableData = res.data.data
					}
				})

				// myActivity().then(res => {
				// 	if (res.code == 200) {
				// 		this.Activitys = res.data.data
				// 	}
				// })
			},
			myinfoclick() {
				this.slected = 26
			},
			myinfolook() {
				this.slected = 28
			},
			changeLang(val) {},
			bannerclick(item) {},
			itemclick(index) {
				console.log(index);
				if (index == 12) {
					this.dialogVisible = true
				} else {
					this.dialogVisible = false
					this.slected = index
				}

			},
		},
	}
</script>
<style lang='scss'>
	.btnConfirm {
		background: #FF6A00 !important;
		color: #fff !important;
		border: 1px solid #FF6A00 !important;
	}

	.btnCancel {
		background: #009BF4 !important;
		color: #FFFFFF !important;
	}

	.el-button:first-child:hover {
		color: #333 !important;
		background-color: #fff !important;
		border-color: #999 !important;
	}

	.el-button:nth-child(2):hover {
		color: #FFFFFF !important;
		border-color: #FF6A00 !important;
		background-color: #FF6A00 !important;
	}
</style>
<style scoped lang='scss'>
	@import "../../element-variables.scss";

	.box {
		.daohang {
			padding-top: 10px;
			padding-bottom: 10px;
			max-width: 1200px;
			margin-right: auto;
			margin-left: auto;
		}

		.listview {
			display: flex;
			max-width: 1200px;
			margin-right: auto;
			margin-left: auto;

			.problemleft {
				padding-top: 19px;
				width: 200px !important;
				/* height: 945px; */
				font-size: 16px;
				font-family: PingFang SC-Regular, PingFang SC;
				font-weight: 400;
				color: #191919;
				background: #fff;
				margin-right: 9px;
				height: 1100px;
				.problemleftitem {
					display: flex;
					height: 47px;
					margin-top: 30px;
					padding-left: 20px;
					line-height: 47px;
					text-align: left;
					/* position: relative; */
					cursor: pointer;
					font-size: 16px;
					font-family: PingFang SC-Regular, PingFang SC;
					font-weight: 400;
					color: #191919;

					img {
						margin-top: auto;
						margin-bottom: auto;
						margin-right: 4px;
					}

					&.on {
						background-color: #FFF9F5;
						color: #FF6A00;
					}
				}

			}

			.listbackview {
				padding: 44px 20px;
				background: #fff;
				width: 1000px;

				.userinfo {
					/* padding-top: 206rpx; */
					display: flex;
					justify-items: center;
					align-items: center;
					font-size: 32rpx;
					font-family: PingFang SC, PingFang SC;
					font-weight: 400;
					color: #333333;
					font-weight: bold;

				}

				.tongji {
					display: flex;
					justify-content: space-around;
					width: 496px;
					height: 128px;
					margin-left: 60px;
					background-color: #fff;
					box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);

					border-radius: 10px 10px 10px 10px;

					.item {
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-items: center;
						padding-top: 30px;

						.number {
							font-size: 24px;
							font-family: PingFang SC, PingFang SC;
							font-weight: 400;
							color: #333333;
						}

						.title {
							margin-top: 10px;
							font-size: 16rpx;
							font-family: PingFang SC, PingFang SC;
							font-weight: 400;
							color: #666666;
						}
					}
				}
			}

			.list {
				display: flex;

				flex-wrap: wrap;

				.user_for {
					margin-right: 20px;
					width: 430px;
					height: 247px;
					margin-top: 20px;
					border: 1px solid #EEEEEE;

					.right_box {
						padding: 20px;

						.title {
							font-size: 20px;
							color: #333333;
						}

						.desc {
							margin-top: 20px;
							font-size: 16px;
							color: #666666;
						}
					}
				}

			}

			.goodlist {

				.good {
					/* display: flex; */
					padding: 20px;
					margin-top: 20px;
					border: 1px solid #EEEEEE;
					justify-content: space-between;

					.goodname {
						font-size: 20px;
						color: #333333;
					}

					.goodguige {
						font-size: 16px;
						color: #333333;
					}

					.goodgnumber {
						font-size: 16px;
						color: #666;
					}

					.goodtype {

						color: #FF6A00;
						font-size: 16px;
					}
				}
			}
		}
	}

	.goodlist {
		.good {
			// display: flex;
			padding: 20px;
			margin-top: 20px;
			border: 1px solid #EEEEEE;
			justify-content: space-between;

			.icon {
				width: 120px;
				height: 120px;
				border-radius: 10px;
				margin-right: 20px;
			}

			.goodname {
				font-size: 20px;
				color: #333333;
			}

			.goodguige {
				font-size: 16px;
				color: #333333;
			}

			.goodgnumber {
				font-size: 16px;
				color: #666;
			}

			.goodtype {

				color: #FF6A00;
				font-size: 16px;
			}
		}
	}

	.showmain {
		padding: 20px;
		font-size: 25px;

	}

	.myIcon {
		width: 80px;
		height: 80px;
		border-radius:40px;
		margin-left: 30rpx;
		
		margin-right: 20rpx;
	}
</style>