<template>
	<div style="background-color: #fff;padding: 20px;">
		<!-- <div>222222</div> -->
		<div class="mainshow"
			style="position: relative;z-index: 99; display: flex;align-items: center;margin-left: auto;margin-right: auto;width: 400px;top:90px">
			<!-- background-image: url('../../static/myluck/myluckback.png'); -->
			<LuckyGrid ref="myLucky" width="300px" height="300px" :blocks="blocks" :prizes="prizes" :buttons="buttons"
				:default-style="defaultStyle" @start="startCallback" @end="endCallback" />

			<!-- <LuckyWheel ref="myLucky" width="300px" height="300px" :prizes="prizes" :blocks="blocks" :buttons="buttons"
			@start="startCallback" @end="endCallback" /> -->
		</div>
		<div class="shuoming">
			<div class="shuomingtop">
				<span>—</span>
				<span>活动规则</span>
				<span>—</span>
			</div>
			<div class="content">
				{{info.rule}}
			</div>
		</div>
		<img class="confcon" src="../../src/assets/my/lockOne.png" alt="" />
		<el-dialog v-model="dialogVisible" title="" width="590" :before-close="handleClose">
			<div class="showmain">
				<!-- {{prize}} -->
				<div class="jiangping">恭喜！抽中了{{slectedinfo.name}}</div>
				<img v-if="slectedinfo.type==2" class="imgs" :src="slectedinfo.goods.cover_img" alt="" />
				<img v-if="slectedinfo.type==3" class="imgs" :src="Prizes" alt="" />
				<img v-if="slectedinfo.type==1" class="imgs" src="../assets/my/jifen.png" alt="" />
				<div  style="margin-top: 20px;">积分已自动放入积分账户</div>
			</div>


			<template #footer>
				<div class="dialog-footer" style="display: flex;justify-content: center;">
					<!-- <div>
		
					</div> -->
					<div style="display: flex;">
						<!-- <div class="btn" @click="dialogVisible = false">取消</div> -->
						<div class="btn1" @click="golook">
							去看看
						</div>
					</div>

				</div>
			</template>
		</el-dialog>
	</div>
</template>


<script>
	import {
		drawPrize
	} from "@/utils/Myapi.js"

	import img1 from "@/assets/my/t1.png";
	import img2 from "@/assets/my/t2.png";
	import img3 from "@/assets/my/t3.png";
	import img4 from "@/assets/my/t4.png";
	import img5 from "@/assets/my/t5.png";
	import img6 from "@/assets/my/t6.png";
	import img7 from "@/assets/my/t7.png";
	import lockbg from "@/assets/my/lockTwo.png";


	import lockbtnbg from "@/assets/my/lockbtnbg.png";
	import bg from "@/assets/my/lockOne.png";

	import Prizes from "@/assets/my/Prizes.png";
	import jifen from "@/assets/my/jifen.png";
	import {
		ElMessage,
		ElMessageBox
	} from 'element-plus'
	export default {
		// 组件名
		name: 'heAd',
		// 注册组件
		components: {},
		// 接收父组件数据
		props: {
			info: Object
		},
		// 组件数据
		data() {
			return {
				Prize: Prizes,
				jifen: jifen,
				activeName: '',
				FullShow: true,
				dialogVisible: false,
				status: '',
				lang: '',
				input3: "",
				titles: [],
				slectedinfo: {},
				blocks: [{
						padding: '10px',
						// background: '#869cfa',
						imgs: [{
							src: lockbg,
							width: '100%',
							height: '100%'
						}]
					},
					{
						padding: '10px',
						background: '#e9e8fe'
					},

				],
				prizes: [{
						x: 0,
						y: 0,
						background: '#FFFFFF',
						borderRadius: "5px",
						fonts: [{
							text: '谢谢惠顾',
							top: '55px'
						}],
						imgs: [{
							src: Prizes,
							width: '50px',
							height: '50px'
						}]
					},
					{
						x: 1,
						y: 0,
						background: '#FFFFFF',
						borderRadius: "5px",
						fonts: [{
							text: '谢谢惠顾',
							top: '55px'
						}],
						imgs: [{
							src: Prizes,
							width: '50px',
							height: '50px'
						}]
					},
					{
						x: 2,
						y: 0,
						background: '#FFFFFF',
						borderRadius: "5px",
						fonts: [{
							text: '谢谢惠顾',
							top: '55px'
						}],
						imgs: [{
							src: Prizes,
							width: '50px',
							height: '50px'
						}]
					},
					{
						x: 2,
						y: 1,
						background: '#FFFFFF',
						borderRadius: "5px",
						fonts: [{
							text: '谢谢惠顾',
							top: '55px'
						}],
						imgs: [{
							src: Prizes,
							width: '50px',
							height: '50px'
						}]
					},
					{
						x: 2,
						y: 2,
						background: '#FFFFFF',
						borderRadius: "5px",
						fonts: [{
							text: '谢谢惠顾',
							top: '55px'
						}],
						imgs: [{
							src: Prizes,
							width: '50px',
							height: '50px'
						}]
					},
					{
						x: 1,
						y: 2,
						background: '#FFFFFF',
						borderRadius: "5px",
						fonts: [{
							text: '谢谢惠顾',
							top: '55px'
						}],
						imgs: [{
							src: Prizes,
							width: '50px',
							height: '50px'
						}]
					},
					{
						x: 0,
						y: 2,
						background: '#FFFFFF',
						borderRadius: "5px",
						fonts: [{
							text: '谢谢惠顾',
							top: '55px'
						}],
						imgs: [{
							src: Prizes,
							width: '50px',
							height: '50px'
						}]
					},
					{
						x: 0,
						y: 1,
						background: '#FFFFFF',
						borderRadius: "5px",
						fonts: [{
							text: '谢谢惠顾',
							top: '55px'
						}],
						imgs: [{
							src: Prizes,
							width: '50px',
							height: '50px'
						}]
					},
				],
				buttons: [{
					x: 1,
					y: 1,
					background: '#7f95d1',
					imgs: [{
						src: lockbtnbg,
						width: '100%',
						height: '100%'
					}],
					fonts: [{
						text: '抽奖',
						top: '30px',
						fontColor: "#FEF2B8"
					}]
				}],

			}
		},
		// 计算属性login
		computed: {},
		// 监听属性
		watch: {},
		// 生命周期钩子函数
		created() {


		},
		mounted() {
			this.getdata()
		},
		// 组件方法
		methods: {
			golook() {
				this.dialogVisible = false
				this.openUrl = 'http://exhibition-pc.huijik.com/#/my?from=9'
				window.open(this.openUrl);
			},
			getdata() {
				console.log(this.info);
				for (var index in this.info.detail) {

					var item = this.info.detail[index]

					item.borderRadius = "5px"

					item.background = '#FFFFFF';

					if (index == 0) {
						item.x = 0
						item.y = 0
					}
					if (index == 1) {
						item.x = 1
						item.y = 0

					}
					if (index == 2) {
						item.x = 2
						item.y = 0
					}
					if (index == 3) {
						item.x = 0
						item.y = 1
					}
					if (index == 4) {
						item.x = 2
						item.y = 1
					}
					if (index == 5) {
						item.x = 0
						item.y = 2
					}
					if (index == 6) {
						item.x = 1
						item.y = 2
					}
					if (index == 7) {
						item.x = 2
						item.y = 2
					}

					if (item.type == 1) {
						var arry = []
						item.imgs = [{
							top: '10px',
							src: jifen,
							width: '50%',
							height: '50%',
						}]
						item.fonts = [{
							text: item.level,
							top: '55px'
						}]


					}
					if (item.type == 2) {
						// item.src = 
						item.imgs = [{
							src: item.goods.banner_ids[0].address,
							width: '50%',
							height: '50%',
						}]
						item.fonts = [{
							text: item.level,
							top: '55px'
						}]
					}
					if (item.type == 3) {
						item.src = Prizes
						item.imgs = [{
							src: Prizes,
							width: '50%',
							height: '50%',

						}]
						item.fonts = [{
							text: '谢谢惠顾',
							top: '55px'
						}]
					}

					this.prizes.push(item)

				}
			},
			// 点击抽奖按钮会触发star回调
			startCallback() {
				// 调用抽奖组件的play方法开始游戏

				// 模拟调用接口异步抽奖

				drawPrize({
					'draw_id': this.info.id
				}).then(res => {
					if (res.code == 200) {
						this.$refs.myLucky.play()
						this.slectedinfo = res.data
						console.log(this.slectedinfo, '22222222222')

						for (var item in this.info.detail) {

							var data = this.info.detail[item]

							console.log(data, 'data')

							if (this.slectedinfo.draw_prize_id == data.id) {

								console.log(this.slectedinfo, '11111111')
								
								console.log('item==', item);
								
								this.$refs.myLucky.stop(item)
							}
						}
					} else {
						ElMessage({
							message: res.message,
							type: 'error',
						})
					}
				})


				// setTimeout(() => {
				// 	// 假设后端返回的中奖索引是0
				// 	const index = 7
				// 	// 调用stop停止旋转并传递中奖索引
				// 	this.$refs.myLucky.stop(index)
				// }, 3000)
			},
			// 抽奖结束会触发end回调
			endCallback(prize) {
				
				console.log(prize, '11111111111111')
				
				console.log(prize)
				
				this.prize = prize
				
				this.dialogVisible = true
			},
			handleClick(index) {
				for (let var1 in this.titles) {
					this.titles[var1].ishow = false
				}
				this.titles[index].ishow = true

				switch (index) {}
			},
		},
	}
</script>

<style scoped lang="scss">
	.list {

		.listitem {
			width: 920px;
			padding: 20px;
			// height: 290px;

			border: 1px solid #EEEEEE;

			.top {
				display: flex;
				justify-content: space-between;
				align-items: center;
				justify-items: center;
				margin-bottom: 20px;
			}

			.desc {
				margin-top: 20px;
				color: #333333;
				font-size: 16px;
			}
		}
	}

	.btn {
		width: 112px;
		height: 42px;
		border-radius: 26px;
		border: 1px solid #FF6A00;
		color: #FF6A00;
		text-align: center;
		line-height: 42px;
	}

	.grop {
		display: flex;
		flex-wrap: wrap;

		.gropitem {
			width: 240px;
			height: 70px;
			padding: 20px;
			display: flex;

			border-radius: 10px 10px 10px 10px;
			border: 1px solid #EEEEEE;

			.icon {
				width: 70px;
				height: 70px;
				margin-right: 20px;
				// margin-right: 20px;

			}

			.number {

				font-size: 24px;
				color: #333333;
			}

			.title {
				margin-top: 12px;
				color: #666666;

				font-size: 16px;
			}
		}
	}

	.shuoming {
		color: #fff;
		margin-top: 30px;
		background-color: #EB4A49;
		margin: 30px;
		position: relative;
		top: 100px;
		z-index: 99;

		// margin-top: 30rpx;
		.shuomingtop {
			font-size: 28px;
			padding-top: 30px;
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
		}

		.content {
			font-size: 20px;
			font-family: PingFang SC, PingFang SC;
			font-weight: 400;
			color: #FFFFFF;
			padding: 30px;
			line-height: 42px;
		}
	}

	.confcon {
		// top: 0px;
		position: relative;
		width: 100%;
		height: 100%;
		bottom: 500px;
	}

	.btn1 {
		width: 92px;
		height: 42px;
		border-radius: 26px;
		color: #fff;
		background-color: #FF6A00;
		text-align: center;
		line-height: 42px;
		margin-right: 20px;
	}

	.showmain {

		display: flex;
		flex-direction: column;
		align-items: center;

		.jiangping {
			font-size: 40px;
			color: #FFE273;

		}
	}
</style>