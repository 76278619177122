import {
	createStore
} from 'vuex'
const store = createStore({
	state: {
		num: 123
	},
	// mutations {
	// 	add: (state, num) => {
			
	// 	}
	// },
	modules: {},
})

export default store