<template>
	<div style="background-color: #fff;padding: 20px;
				background: #fff;
				width: 1000px;">
		<div style="color: #333333;font-size: 24px;padding-left: 10px;border-left: 4px solid #FF6A00;">
			我的活动
		</div>
		<!-- 	<el-button round style=" background-color:#FF6A00;margin-top: 20px;margin-bottom: 40px;color: #fff;"
			@click="creatclick">创建活动</el-button> -->
		<div class="btn1" style="width: 120px;margin-top: 20px;margin-bottom: 40px" @click="creatclick">创建活动</div>
		<div>
			<div class="list">
				<div class="listitem" v-for="(item,index) in Activitys" :key="index">
					<div class="top">
						<div>
							活动名称：{{item.name}}
						</div>
						<div>
							<el-icon color="#FF6A00" @click="dele(item)">
								<Delete />
							</el-icon>
						</div>
					</div>
					<div class="desc">
						举办地区：{{item.city}}
					</div>
					<div class="desc">
						活动类型：{{item.class_name}}
					</div>
					<div class="desc">
						提交时间：{{item.create_time}}
					</div>
					<!-- //1 待审核 2审核通过 3审核驳回 -->
					<div class="desc">
						审核状态：
						<span v-show="item.status==1"
							style="padding: 2px 10px;background-color: #FFF0E5;color:#FF6A00;;border-radius: 15px;">待审核</span>
						<span v-show="item.status==2"
							style="padding: 2px 10px;background-color: #FFF0E5;color:#FF6A00;;border-radius: 15px;">已审核</span>
						<span v-show="item.status==3"
							style="padding: 2px 10px;background-color: #FFF0E5;color:#FF6A00;;border-radius: 15px;">已驳回</span>
					</div>
					<el-divider></el-divider>
					<div style="display: flex;justify-content: space-between;">
						<div>

						</div>
						<div style="display: flex;">
							<div v-show="item.status==3" class="btn" @click="editclick(item)">
								编辑
							</div>
							<div v-show="item.status==2" class="btn" @click="gohoutai(item)">
								完善
							</div>
							<div v-show="item.status==2||item.release==2" class="btn" @click="gohoutai(item)">
								管理
							</div>
							<div v-show="item.status==2||item.release==2" class="btn" @click="tongji(item)">
								统计
							</div>
						</div>

					</div>
				</div>

			</div>
		</div>
		<el-pagination v-model:current-page="pagesize.page" :page-size="pagesize.limit" :background="background"
			layout="total, prev, pager, next" :total="pagesize.count" @size-change="handleSizeChange"
			@current-change="handleCurrentChange" />

		<el-dialog title="创建" v-model="dialogVisible" width="520px" :before-close="handleClose">
			<div class="list">
				<el-form :model="formInline" class="demo-form-inline" label-width="100">
					<el-form-item label="活动名称：">
						<el-input class="shuru" v-model="formInline.name" placeholder="请输入" clearable />
					</el-form-item>
					<el-form-item label="举办城市：">
						<el-cascader popper-class="pc-sel-area-cascader" style="width: 350px;" ref="articleOrgRef" placeholder="请选择地址"
							v-model="formInline.citys" :props='props' :options="mapJson.data" />
					</el-form-item>
					<el-form-item label="活动类型：">
						<el-select popper-class="dropdown" filterable v-model="formInline.class_id"
							placeholder="请选择" clearable>
							<el-option v-for="item in Acclass" :label="item.name" :value="item.id" />
						</el-select>
					</el-form-item>
					<el-form-item label="起止日期：">
						<el-date-picker popper-class="date_form" v-model="times" type="daterange" range-separator="到"
							start-placeholder="开始" end-placeholder="结束" :size="size" />
					</el-form-item>
					<el-form-item label="参加人数：">
						<el-input v-model="formInline.number" placeholder="请输入" clearable />
					</el-form-item>
					<el-form-item label="大致预算：">
						<el-input v-model="formInline.budget" placeholder="请输入" clearable />
					</el-form-item>
				</el-form>
				<div @click="serviceclick" style="display: flex;align-items: center;margin-left: 20px;">
					<img v-if="formInline.meeting_service!=1" class="serveIcon" src="../assets/my/sleced2.png" alt="" />
					<img v-if="formInline.meeting_service==1" class="serveIcon" src="../assets/my/sleced.png" alt="" />

					<div>同时需要搭建、用车等其他会议服务。</div>
				</div>
			</div>
			<div>

			</div>
			<template #footer>
				<div class="dialog-footer" style="display: flex;justify-content: space-between;">
					<div>

					</div>
					<div style="display: flex;">
						<div class="btn" style="margin-right: 20px;" @click="dialogVisible = false">取消</div>
						<div class="btn1" @click="changclick">
							提交
						</div>
					</div>

				</div>
				<div class="bohui" v-if="formInline.status==3">
					<div style="display: flex;align-items: center;">
						<img class="bohuiicon" src="../../src/assets/my/bohui.png" alt="" />
						<div>被驳回原因</div>
					</div>
					<div style="margin-top: 20px;">
						{{formInline.reason}}
					</div>
				</div>
			</template>
		</el-dialog>
		<el-dialog title="统计" v-model="dialogVisible1" width="960px" :before-close="handleClose">
			<div class="grop">
				<div class="gropitem" style="width: 240px;margin-left: 20px;margin-top: 20px;" v-for="item in titles">
					<img class="icon" :src="item.icon" alt="" />
					<div>
						<div class="number">
							{{item.number}}
						</div>
						<div class="title">
							{{item.title}}
						</div>
					</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>


<script>
	import {
		user_info,
		myMeetingApplication,
		mymyGoodsList,
		myInfo,
		myActivity,

	} from "@/utils/Myapi.js"
	import {
		activityclass,
		editMyActivity,
		activitycreate,
		myActivityCount,
		myActivitydel
	} from "@/utils/api"
	import {
		ElMessage,
		ElMessageBox
	} from 'element-plus'
	import img1 from "@/assets/my/t1.png";
	import img2 from "@/assets/my/t2.png";
	import img3 from "@/assets/my/t3.png";
	import img4 from "@/assets/my/t4.png";
	import img5 from "@/assets/my/t5.png";
	import img6 from "@/assets/my/t6.png";
	import img7 from "@/assets/my/t7.png";
	import mapdata from '@/utils/city.json'
	export default {
		// 组件名
		name: 'heAd',
		// 注册组件
		components: {},
		// 接收父组件数据
		props: {},
		// 组件数据
		data() {
			return {
				mapJson: mapdata,
				times: [],
				props: {
					label: "name",
					value: "name"
				},
				pagesize: {
					count: 0,
					limit: 8,
					page: 1,
					status: ''
				},
				activeName: '',
				FullShow: true,
				dialogVisible: false,
				status: '',
				lang: '',
				input3: "",
				formInline: {},
				Activitys: [],
				Acclass: [],
				dialogVisible1: false,
				titles: [{
						icon: img1,
						title: '总人数',
						number: '0'
					},
					{
						icon: img2,
						title: '签到人数',
						number: '0'
					},
					{
						icon: img3,
						title: '付款人数',
						number: '0'
					},
					{
						icon: img4,
						title: '未付款人数',
						number: '0'
					},
					{
						icon: img5,
						title: '线下支付金额',
						number: '0'
					},
					{
						icon: img6,
						title: '支付宝支付金额',
						number: '0'
					},
					{
						icon: img7,
						title: '微信支付金额',
						number: '0'
					},

				],

			}
		},
		// 计算属性login
		computed: {},
		// 监听属性
		watch: {},
		// 生命周期钩子函数
		created() {


		},
		mounted() {
			this.getdata()
		},
		// 组件方法
		methods: {

			// http://exhibition-merchant.huijik.com
			gohoutai() {
				this.openUrl = "http://exhibition-merchant.huijik.com";
				window.open(this.openUrl);
			},
			handleCurrentChange() {
				myActivity(this.pagesize).then(res => {
					if (res.code == 200) {
						this.Activitys = []
						this.Activitys = res.data.data
						this.pagesize.count = res.data.count
					}
				})
			},
			dele(item) {

				ElMessageBox.confirm(
						'您确定要删除活动', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							confirmButtonClass: 'btnConfirm',
							center: true,
						}
					)
					.then(() => {
						this.cancelOrder(item.id)
					})
					.catch(() => {

					})

			},
			cancelOrder(id) {
				myActivitydel({
					'id': id
				}).then(res => {
					if (res.code == 200) {
						ElMessage({
							message: '删除成功',
							type: 'success',
						})
						this.getdata()
					}
				})
			},

			tongji(tiem) {
				myActivityCount({
					'id': tiem.id
				}).then(res => {

					if (res.code == 200) {
						for (var index in this.titles) {
							if (index == 0) {
								this.titles[index].number = res.data.total
							}
							if (index == 1) {
								this.titles[index].number = res.data.sign
							}
							if (index == 2) {
								this.titles[index].number = res.data.pay
							}
							if (index == 3) {
								this.titles[index].number = res.data.unpay
							}
							if (index == 4) {
								this.titles[index].number = res.data.offline
							}
							if (index == 5) {
								this.titles[index].number = res.data.alipay
							}
							if (index == 6) {
								this.titles[index].number = res.data.wxpay
							}
						}

					}
				})
				this.dialogVisible1 = true

			},
			serviceclick() {
				// this.formInline.meeting_service = !this.formInline.meeting_service
				if (this.formInline.meeting_service == 1) {
					this.formInline.meeting_service = 2
				} else {
					this.formInline.meeting_service = 1
				}
			},
			getdata() {
				myActivity(this.pagesize).then(res => {
					if (res.code == 200) {
						this.Activitys = []
						this.Activitys = res.data.data
						this.pagesize.count = res.data.count
					}
				})
				//活动分类
				activityclass().then(res => {
					if (res.code == 200) {
						console.log('res.Acclass===', res.data);

						this.Acclass = res.data.data

					}
				})
			},
			creatclick() {
				this.times = [];
				this.formInline = {};
				this.dialogVisible = true;
			},
			editclick(item) {
				if (item) {
					this.formInline = item;
					this.formInline.citys = item.city.split('-');
					this.times = [item.end_time_str, item.start_time_str];
				}

				this.dialogVisible = true;
			},
			changclick() {
				if (this.formInline.id > 0) {
					console.log("this.formInline======", this.formInline)

					var city = this.formInline.citys.join('-')

					if (this.times.length > 0) {
						this.formInline.start_time = this.times[0]
						this.formInline.end_time = this.times[1]
					}

					this.formInline.city = city

					editMyActivity(this.formInline).then(res => {
						if (res.code == 200) {
							this.formInline = {}
							this.times = []
							ElMessage({
								message: '提交成功',
								type: 'success',
							})
							this.getdata()
						}
						this.dialogVisible = false
					})
				} else {
					if (!this.formInline.citys) {
						ElMessage({
							message: '请选择城市',
							type: 'error',
						})
						return
					}
					var city = this.formInline.citys.join('-')
					if (this.times.length > 0) {
						this.formInline.start_time = this.times[0]
						this.formInline.end_time = this.times[1]
					}

					this.formInline.city = city

					activitycreate(this.formInline).then(res => {
						if (res.code == 200) {
							this.formInline = {}
							this.times = []
							ElMessage({
								message: '提交成功',
								type: 'success',
							})
							this.getdata()
							this.dialogVisible = false
						} else {
							ElMessage({
								message: res.message,
								type: 'error',
							})
						}

					})


				}


			},
			handleClick(index) {
				for (let var1 in this.titles) {
					this.titles[var1].ishow = false
				}
				this.titles[index].ishow = true

				switch (index) {}
			},
		},
	}
</script>
<style lang='scss'>
	.btnConfirm {
		background: #FF6A00 !important;
		color: #fff !important;
		border: 1px solid #FF6A00 !important;
	}

	.btnCancel {
		background: #009BF4 !important;
		color: #FFFFFF !important;
	}

	.el-button:first-child:hover {
		color: #333 !important;
		background-color: #fff !important;
		border-color: #999 !important;
	}

	.el-button:nth-child(2):hover {
		color: #FFFFFF !important;
		border-color: #FF6A00 !important;
		background-color: #FF6A00 !important;
	}

	/* //===============↓↓↓↓↓↓date选择框样式↓↓↓↓↓↓↓======================================= */
	.el-picker-panel .el-date-table.is-week-mode .el-date-table__row:hover div,
	.el-picker-panel .el-date-table td.in-range div,
	.el-picker-panel .el-date-table td.in-range div:hover {
		background-color: #FFF0E5;
		color: #333;
	}

	.el-picker-panel .el-date-table td.today  span {
		color: #FF6A00;
		/* background-color: #fff; */
	}

	.el-picker-panel .el-date-table td.start-date span {
		color: #fff;
		background-color: #FF6A00;
	}

	.el-picker-panel .el-date-table td.end-date span {
		color: #fff;
		background-color: #FF6A00;
	}
	.dropdown {
		z-index: 99999 !important;
	
		.el-select-dropdown__item {}
	
		.el-select__selected-item.selected {
			color: #FF6A00;
		}
		.el-select-dropdown__item.is-selected {
			color: #FF6A00!important;
		}
		.el-select__selected-item .el-select__placeholder {
			color: #FF6A00;
		}
		.el-select-dropdown__item.hover,
		.el-select-dropdown__item:hover {
			color: #FF6A00;
		}
	}
	.pc-sel-area-cascader {
		.el-cascader-node.in-active-path,
		.el-cascader-node.is-active,
		.el-cascader-node.is-selectable.in-checked-path {
			color: #FF6A00;
		}
	}
	.pc-sel-select-cascader {
		.el-select-node.in-active-path,
		.el-select-node.is-active,
		.el-select-node.is-selectable.in-checked-path {
			color: #FF6A00;
		}
	}
	
	.el-picker-panel .el-date-table.is-week-mode .el-date-table__row:hover div,
	.el-picker-panel .el-date-table td.in-range div,
	.el-picker-panel .el-date-table td.in-range div:hover {
		background-color: #FFF0E5;
		color: #333;
	}
	
	.el-picker-panel .el-date-table td.today span {
		color: #FF6A00;
		/* background-color: #fff; */
	}
	
	.el-picker-panel .el-date-table td.start-date span {
		color: #fff;
		background-color: #FF6A00;
	}
	
	.el-picker-panel .el-date-table td.end-date span {
		color: #fff;
		background-color: #FF6A00;
	}
	
	.el-date-table td:hover {
		color: #FF6A00 !important;
	}
</style>
<style scoped lang="scss">
	@import "../element-variables.scss";
	.list {

		.listitem {
			width: 920px;
			padding: 20px;
			// height: 290px;

			border: 1px solid #EEEEEE;

			.top {
				display: flex;
				justify-content: space-between;
			}

			.desc {
				margin-top: 20px;
				color: #333333;
				font-size: 16px;
			}
		}
	}

	.btn {
		width: 112px;
		height: 42px;
		border-radius: 26px;
		border: 1px solid #FF6A00;
		color: #FF6A00;
		text-align: center;
		line-height: 42px;
		margin-right: 20px;
	}

	.btn1 {
		width: 112px;
		height: 42px;
		border-radius: 26px;
		color: #fff;
		background-color: #FF6A00;
		text-align: center;
		line-height: 42px;
		margin-right: 20px;
	}

	.grop {
		display: flex;
		flex-wrap: wrap;

		.gropitem {
			width: 240px;
			height: 70px;
			padding: 20px;
			display: flex;

			border-radius: 10px 10px 10px 10px;
			border: 1px solid #EEEEEE;

			.icon {
				width: 70px;
				height: 70px;
				margin-right: 20px;
				margin-right: 20px;
			}

			.number {

				font-size: 24px;
				color: #333333;
			}

			.title {
				margin-top: 12px;
				color: #666666;

				font-size: 16px;
			}
		}
	}

	.serveIcon {
		width: 16px;
		height: 16px;
		margin-right: 10px;
	}

	.bohui {
		margin: 40px;
		padding: 20px;
		text-align: center;
		border: 1px solid #FF6A00;
		border-radius: 5px;
		color: #FF6A00;
		background-color: #FFF9F5;

		.bohuiicon {
			width: 20px;
			height: 20px;
			margin-right: 10px;
		}
	}

	/* 修改前后箭头未点击时的背景颜色 */
	::v-deep .el-pagination .btn-prev,
	::v-deep .el-pagination .btn-next {
		/* background-color: #0e3a90 !important; */
	}

	/* 修改未点击时的数字方块背景颜色 */
	:v-deep .el-pagination .el-pager li:not(.active):not(.disabled):hover {
		/* background-color: #75fbff !important; */
	}

	/* 未点击时的数字方块背景颜色 */
	::v-deep .el-pager li.is-active {
		/* background-color: #0e3a90 !important; */
		color: #FF6A00;
	}

	::v-deep .el-pagination.is-background .el-pager li:not(.is-disabled).is-active {
		/* background-color: #75fbff !important; //修改默认的背景色 */
		color: #01133c;
	}
</style>