<template>
	<div style="background-color: #fff;padding: 20px;
				background: #fff;
				width: 1000px;">
		<div style="display: flex;justify-content: space-between;">

			<div style="color: #333333;font-size: 24px;padding-left: 10px;border-left: 4px solid #FF6A00;">
				商城订单
			</div>
			<div style="display: flex;">
				<div class="btn" @click="goback">
					返回
				</div>
			</div>
		</div>
		<div class="ordertype">
			<!-- <div>{{goodId}}</div> -->
			<div class="top">
				<img class="icon" src="../assets/my/ordertype.png" alt="" />
				<div class="typename">申请退款</div>
			</div>
		</div>
		<div class="info">商品信息</div>

		<div class="goodlist">
			<div class="good">
				<div style="display: flex;">
					<div>
						<img style="width: 120px;height: 120px;margin-right: 20px;" :src="goodinfo.cover_img" alt="" />
					</div>
					<div>
						<div class="goodname">
							{{goodinfo.goods_name}}
						</div>
						<div class="goodguige" style="margin-top: 10px;margin-bottom: 10px;">
							{{goodinfo.goods_spec}}
						</div>
						<div class="goodnumber">
							{{goodinfo.goods_spec}}
						</div>
					</div>
				</div>
			</div>

		</div>
		<div class="roomitem" style="padding: 0;">
			<div class="roomdesc" style="display: flex;align-items: center;">
				<div class="roomdesctitle">商家信息：</div>
				<div class="roomdesccon">
					<div>{{config.name}} {{config.phone}}</div>
					<div>{{config.address}}</div>
				</div>
			</div>
			<div class="roomdesc" style="display: flex;align-items: center;">
				<div class="roomdesctitle">退款方式：</div>
				<div class="roomdesccon">
					<!-- 1.仅退款 2.退货退款 -->
					<el-radio-group v-model="type">
						<el-radio :label="1">仅退款</el-radio>
						<el-radio :label="2">退款退货</el-radio>
					</el-radio-group>
				</div>
			</div>
			<div class="roomdesc" style="display: flex;align-items: center;">

				<div class="roomdesctitle">退款金额：</div>				
				<div v-show="goodinfo.money_type==3" style="display: flex;margin-top: 10px;color: #FF6A00;">
					<img class="jifen" src="../assets/my/jifen.png" alt="" />
					<div>{{goodinfo.pay_point}}</div>
					<div>+</div>
					<div><span style="font-size: 14px;"></span>{{goodinfo.pay_price}}</div>
				</div>

				<div v-show="goodinfo.money_type==2" style="display: flex;margin-top: 10px;color: #FF6A00;">
					<div><span style="font-size: 14px;">￥</span>{{goodinfo.pay_price}}</div>
				</div>

				<div v-show="goodinfo.money_type==1" style="display: flex;margin-top: 10px;color: #FF6A00;">
					<img class="jifen" src="../assets/my/jifen.png" alt="" />
					<div>{{goodinfo.pay_point}}</div>
				</div>
			</div>
			<div class="roomdesc" style="display: flex;align-items: center;">
				<div class="roomdesctitle">退款描述：</div>
			</div>
			<div style="margin-top: 10px;">
				<el-input v-model="reason" :autosize="{ minRows: 6}" type="textarea" placeholder="请输入" />
			</div>
			<div style="display: flex;align-items: center;flex-direction: column;margin-top: 40px;">
				<div @click="Refund" class="btn1">提交</div>
			</div>


		</div>
	</div>
</template>

<script>
	import {
		myGoodsDetail,
		applyRefund,
	} from "@/utils/Myapi.js"
	import {
		ElMessage,
		ElMessageBox
	} from 'element-plus'
	import {
		config
	} from "@/utils/api.js"
	export default {
		// 组件名
		name: 'heAd',
		// 注册组件
		components: {

		},
		// 接收父组件数据
		props: {
			goodId: String
		},
		// 组件数据
		data() {
			return {
				formInline: {},
				activeName: '',
				goodinfo: {},
				reason: '',
				radio: '',
				textarea: '',
				dialogVisible1: false,
				FullShow: true,
				dialogVisible: false,
				status: '',
				lang: '',
				input3: "",
				titles: [],
				config: {},
				type: ''

			}
		},
		// 计算属性login
		computed: {},
		// 监听属性
		watch: {},
		// 生命周期钩子函数
		created() {


		},
		mounted() {
			this.getdata()
		},
		// 组件方法
		methods: {
			goback() {
				console.log('zi组件方法');
				this.$parent.fatherMethod();
			},
			getdata() {
				myGoodsDetail({
					'id': this.goodId
				}).then(res => {
					if (res.code == 200) {
						this.goodinfo = res.data
					}
				})
				config().then(res => {
					if (res.code == 200) {
						var name = ''
						var phone = ''
						var address = ''
						// this.config = res.data
						for (var item of res.data.data) {
							if (item.key == 'offline_payname') {
								name = item.value
							}
							if (item.key == 'contacts_phone') {
								phone = item.value
							}
							if (item.key == 'contacts_address') {
								address = item.value
							}
						}
						this.config = {
							'name': name,
							'phone': phone,
							'address': address
						}
						console.log("thisconfig", this.config)
					}
				})

			},
			pingjia() {
				this.dialogVisible1 = true
			},
			look() {
				this.dialogVisible = true
			},
			Refund() {

				var form = {
					'id': this.goodId,
					"type": this.type,
					"reason": this.reason
				}
				applyRefund(form).then(res => {
					if (res.code == 200) {

						ElMessage({
							message: '申请成功',
							type: 'success',
						})

						this.goback()
					}
				})
			},
			handleClick(index) {
				for (let var1 in this.titles) {
					this.titles[var1].ishow = false
				}
				this.titles[index].ishow = true

				switch (index) {}
			},
		},
	}
</script>

<style scoped lang="scss">
	.ordertype {
		margin-top: 20px;
		// border: 1px solid #eee;
		padding: 20px;
		// width: 960px;
		// height: 315px;
	}

	.btn {
		width: 112px;
		height: 42px;
		border-radius: 26px;
		border: 1px solid #FF6A00;
		color: #FF6A00;
		text-align: center;
		font-size: 16px;
		line-height: 42px;
		margin-right: 20px;
	}

	.btn1 {
		width: 112px;
		height: 42px;
		font-size: 16px;
		border-radius: 26px;
		color: #fff;
		background-color: #FF6A00;
		text-align: center;
		line-height: 42px;
		margin-right: 20px;
	}

	.top {
		display: flex;
		justify-items: center;
		align-items: center;

		.typename {
			color: #FF6A00;
			font-size: 24px;
			// font-weight: bold;
		}

		.icon {
			width: 40px;
			height: 40px;
			margin-right: 10px;
		}
	}

	.con {
		margin-top: 20px;
		font-size: 16px;
		color: #333333;
	}

	.hotellist {
		margin-top: 40px;
		// padding: 30rpx;
		background-color: #fff;
		font-size: 24px;
		// font-family: PingFang SC, PingFang SC;
		font-weight: 400;
		color: #333333;
		// line-height: 32rpx;
		// font-weight: bold;

		.hotelitem {
			display: flex;
			margin-top: 20px;
			// width: 690rpx;

			.right {
				margin-left: 30rpx;
				width: 500rpx;

				.imgs {
					display: flex;
					margin-bottom: 30rpx;

					.imgsitem {
						display: flex;
						flex-direction: column;
						justify-items: center;
						align-items: center;
						font-size: 28rpx;
						margin-right: 40px;
					}
				}

				.hotelbtm {
					display: flex;
					justify-content: flex-end;
					align-items: center;
					font-size: 32rpx;
					font-family: PingFang SC, PingFang SC;
					font-weight: 400;
					color: #FF6A00;
				}
			}

		}
	}

	.ruzhu {
		margin-top: 40px;
		// padding: 30rpx;
		background-color: #fff;
		font-size: 24px;
		// font-family: PingFang SC, PingFang SC;
		font-weight: 400;
		color: #333333;
		// font-weight: bold;
	}

	.timeicon {
		width: 40px;
		height: 48px;
	}

	.info {
		margin-top: 40px;
		margin-bottom: 20px;
		color: #333333;
		font-size: 24px;
	}

	.info1 {
		// margin-top: 40px;
		margin-bottom: 20px;
		color: #333333;
		font-size: 24px;
	}

	.info2 {
		// margin-top: 40px;
		margin-bottom: 20px;
		color: #333333;
		font-size: 20px;
	}

	.info3 {
		margin-bottom: 20px;
		margin-top: 20px;
		color: #333333;
		font-size: 20px;
	}

	.infoicon {
		width: 20px;
		height: 20px;
		margin-right: 10px;
	}

	.roomitem {
		width: 430px;
		// height: 160px;
		padding: 20px;
		// border: 1px solid #eeeeee;
		margin-right: 20px;

		.roomname {
			color: #333333;
			font-size: 20px;
			margin-bottom: 20px;
			font-weight: bold;
		}

		.roomdesc {
			margin-top: 20px;
			display: flex;
			align-items: center;

			.roomdesctitle {
				color: #666666;
				font-size: 16px;
				width: 84px;
				margin-right: 20px;

			}

			.roomdesccon {
				color: #333333;
				font-size: 16px;
			}
		}
	}

	.jifen {
		width: 20px;
		height: 20px;
		margin-right: 5px;
	}

	.goodlist {
		.good {
			display: flex;
			padding: 20px;
			margin-top: 20px;
			border: 1px solid #EEEEEE;
			justify-content: space-between;

			.goodname {
				font-size: 20px;
				color: #333333;
			}

			.goodguige {
				font-size: 16px;
				color: #333333;
			}

			.goodgnumber {
				font-size: 16px;
				color: #666;
			}

			.goodtype {

				color: #FF6A00;
				font-size: 16px;
			}
		}
	}

	.adresitem {
		width: 360px;
		padding: 20px;
		height: 60px;
		border: 1px solid #EEEEEE;
	}

	.wuliuicon {
		width: 40px;
		height: 40px;
		margin-right: 10px;
	}

	.list {
		margin-top: 20px;

		.jiedian {
			display: flex;
			align-items: center;

			// justify-content: center;
		}

		.neirong {
			padding-bottom: 40px;
			padding-left: 30px;
			border-left: 1px dashed #FF6A00;
			margin-left: 20px;
		}
	}

	.pingjiatitle {
		margin-top: 20px;
		color: #333333;
		font-size: 16px;
		margin-left: 20px;
		margin-bottom: 10px;
	}

	.pingjiaIcon {
		width: 120px;
		height: 120px;
		margin-right: 10px;
	}

	/* 选中后的字体颜色 */
	::v-deep .el-radio__input.is-checked+.el-radio__label {
		color: #FF6A00 !important;
	}

	/* 选中后小圆点的颜色 */
	::v-deep .el-radio__input.is-checked .el-radio__inner {
		background: #FF6A00 !important;
		border-color: #FF6A00 !important;
	}
</style>