var baseURL;
import request from "./request";
if (process.env.NODE_ENV === 'development') {
	baseURL = process.env.VUE_APP_BASE_URL
} else {
	baseURL = process.env.VUE_APP_BASE_URL
}
import {url_config} from "@/utils/baseUrl"
let url = url_config
export function user_info(param) {

	return request.post(url+"/api/index/user_info", param);
}
//我的信息
export function myInfo(param) {

	return request.post(url+"/api/user/myInfo", param);
}
//发票抬头 详情
export function invoiceDetailLoce(param) {

	return request.post(url+"/api/user/invoiceDetailLoce", param);
}
//发票抬头列表 维护发票
export function invoiceList(param) {

	return request.post(url+"/api/user/invoiceList", param);
}
//发票抬头列表 删除
export function invoiceDel(param) {

	return request.post(url+"/api/user/invoiceDel", param);
}
// 添加/修改 发票抬头
export function invoiceAddEdit(param) {

	return request.post(url+"/api/user/invoiceAddEdit", param);
}

//我的报名
export function myMeetingApplication(param) {
	return request.post(url+"/api/activity/myMeetingApplication", param);
}
//我的商品订单
export function mymyGoodsList(param) {

	return request.post(url+"/api/user/myGoodsList", param);
}
//我的商品订单详情
export function myGoodsDetail(param) {

	return request.post(url+"/api/user/myGoodsDetail", param);
}

//商品订单 取消订单
export function cancelGoodsOrder(param) {

	return request.post(url+"/api/goodsOrder/cancelOrder", param);
}
//商品订单 删除订单
export function delGoodsOrder(param) {

	return request.post(url+"/api/user/delGoodsOrder", param);
}
//优惠券列表
export function couponBillList(param) {

	return request.post(url+"/api/user/myCouponBill", param);
}
//积分明细
export function myPoint(param) {

	return request.post(url+"/api/user/myPoint", param);
}
//凭证详情
export function paymentVoucherDetail(param) {

	return request.post(url+"/api/user/paymentVoucherDetail", param);
}

//撤销退款
export function revokeRefund(param) {

	return request.post(url+"/api/user/revokeRefund", param);
}

//填写物流单号
export function completeLogisticsCode(param) {

	return request.post(url+"/api/user/completeLogisticsCode", param);
}
//售后详情 和 协商记录
export function goodsOrderAftersales(param) {

	return request.post(url+"/api/user/goodsOrderAftersales", param);
}

//申请退款
export function applyRefund(param) {

	return request.post(url+"/api/user/applyRefund", param);
}
//商品订单 确认收货
export function confirmGoodsOrder(param) {

	return request.post(url+"/api/user/confirmGoodsOrder", param);
}

//我的团队
export function authenticationList(param) {

	return request.post(url+"/api/user/authenticationList", param);
}

//我的收货地址列表
export function addressList(param) {
	return request.post(url+"/api/user/addressList", param);
}
//收货地址 添加/编辑
export function addressAddEdit(param) {
	return request.post(url+"/api/user/addressAddEdit", param);
}

//删除地址
export function addressDel(param) {
	return request.post(url+"/api/user/addressDel", param);
}
//修改订单的收货地址
export function changeAddress(param) {
	return request.get(url+"/api/goods_order/changeAddress", param);
}

//下载 团队导入模板
export function getauthGroupImportTemplate(param) {
	return request.get(url+"/api/user/authGroupImportTemplate", param);
}
//团队认证 导出
export function authGroupExport(param) {
	return request.post(url+"/api/user/authGroupExport", param);
}

//查看/编辑 团队/个人认证
export function authGroupEdit(param) {
	return request.post(url+"/api/user/authGroupEdit", param);
}
//查看 团队/个人认证
export function lookauthGroupEdit(param) {
	return request.get(url+"/api/user/authGroupEdit", param);
}
//团队认证 添加
export function authGroupAdd(param) {
	return request.post(url+"/api/user/authGroupAdd", param);
}
//团队认证 导入
export function authGroupImport(param) {
	return request.post(url+"/api/user/authGroupImport", param);
}

//团队认证 导入
export function myActivity(param) {
	return request.post(url+"/api/user/myActivity", param);
}

//修改团队成员 图片

export function authGroupEditImg(param) {
	return request.post(url+"/api/user/authGroupEditImg", param);
}

//我的调查问卷列表
export function questionnaire(param) {
	return request.post(url+"/api/user/questionnaire", param);
}

//问卷调查 题目信息
export function questionnaireDetail(param) {
	return request.post(url+"/api/user/questionnaireDetail", param);
}
// 问卷调查 提交
export function submitQuestionnaire(param) {
	return request.post(url+"/api/user/submitQuestionnaire", param);
}
// 问卷调查 提交查看
export function submitQuestionnaireDetail(param) {
	return request.post(url+"/api/user/submitQuestionnaireDetail", param);
}
// 我的收藏
export function myUserCollect(param) {
	return request.post(url+"/api/user/myUserCollect", param);
}

// 抽奖
export function drawPrize(param) {
	return request.post(url+"/api/user/drawPrize", param);
}
// 抽奖
// export function drawPrize(param) {
// 	return request.post(url+"/api/user/drawPrize", param);
// }
// 我的奖品
export function myPrize(param) {
	return request.post(url+"/api/user/myPrize", param);
}
// 奖品列表
export function drawList(param) {
	return request.post(url+"/api/user/drawList", param);
}
// 领取奖品
export function reveivePrize(param) {
	return request.post(url+"/api/user/reveivePrize", param);
}


// 报名订单
export function meetingOrderList(param) {
	return request.post(url+"/api/user/meetingOrderChildList", param);
}

// 报名详情
export function meetingOrderDetail(param) {
	return request.post(url+"/api/user/meetingOrderDetail", param);
}

// 酒店订单列表
export function hotelOrderList(param) {
	return request.post(url+"/api/user/hotelOrderList", param);
}
// 酒店订单详情
export function hotelOrderDetail(param) {
	return request.post(url+"/api/user/hotelOrderDetail", param);
}
// 酒店订单取消订单
export function hotelOrdercancel(param) {
	return request.post(url+"/api/hotel_order/cancel", param);
}
// 酒店订删除
export function hotelOrderdelete(param) {
	return request.post(url+"/api/hotel_order/delete", param);
}


// 签到记录
export function signLog(param) {
	return request.post(url+"/api/user/signLog", param);
}
// 签到详情
export function signLogDetail(param) {
	return request.post(url+"/api/user/signLogDetail", param);
}

// 线下支付
export function offlinePay(param) {
	return request.post(url+"/api/user/offlinePay", param);
}

// 线下支付 重新提交
export function offlinePayAgain(param) {
	return request.post(url+"/api/user/offlinePayAgain", param);
}


// 物流轨迹 Copy
export function deliveryList(param) {
	return request.post(url+"/api/GoodsOrder/deliveryList", param);
}

export function goodsEvaluate(param) {
	return request.post(url+"/api/goods/goodsEvaluate", param);
}

export function editInfo(param) {
	return request.post(url+"/api/user/editInfo", param);
}

