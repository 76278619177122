<template>
	<div style="background-color: #fff;padding: 20px;
				background: #fff;
				width: 1000px;">
					<div v-if="slected==0">
		<div 
			style="color: #333333;font-size: 24px;padding-left: 10px;border-left: 4px solid #FF6A00;margin-bottom: 20px;">
			抽奖
		</div>

		<div>
			<div class="goodlist">
				<div class="good" v-for="(item,index) in list" @click="goodclck(item)">
					<div style="display: flex;justify-content: space-between;align-items: center;">
						<div style="display: flex;width: 100%;">
							<div>
								<img class="icon" :src="item.cover_img" alt="" />
							</div>
							<div>
								<div class="goodname">
									活动名称：{{item.name}}
								</div>
								<div class="goodguige" style="margin-top: 20px;margin-bottom: 20px;">
									活动日期：{{item.start_time}} 至 {{item.end_time}}
								</div>
								<div class="goodnumber"
									style="display: flex;justify-content: space-between;align-items: center;">
									<div style="display: flex;">
										<div>
											活动状态：
										</div>
										<div>
											进行中
										</div>
									</div>

								</div>
							</div>
						</div>
						<div class="goodtype" @click="slected=1">
							抽奖
						</div>
					</div>

				</div>

			</div>
		</div>
		</div>
		<myLuckydrawOne :info='info' v-if="slected==1"></myLuckydrawOne>
		<myLuckydrawTwo :info='info' v-if="slected==2"></myLuckydrawTwo>
	</div>
</template>


<script>
	import myLuckydrawOne from '@/components/myLuckydrawOne.vue'
	import myLuckydrawTwo from '@/components/myLuckydrawTwo.vue'
	
	import {
		drawPrize,
		drawList
	} from "@/utils/Myapi.js"
	
	export default {
		// 组件名
		name: 'heAd',
		// 注册组件
		components: {
			myLuckydrawOne,
			myLuckydrawTwo
		},
		// 接收父组件数据
		props: {},
		// 组件数据
		data() {
			return {
				slected:0,
				activeName: 'first',
				FullShow: true,
				dialogVisible: false,
				status: '',
				lang: '',
				input3: "",
				titles: [],
				info:{},
				list:[],
			}
		},
		// 计算属性login
		computed: {},
		// 监听属性
		watch: {},
		// 生命周期钩子函数
		created() {


		},
		mounted() {
			this.getdata()
		},
		// 组件方法
		methods: {
			getdata() {
				//
				drawList().then(res => {
					if (res.code == 200) {
						this.list = res.data.data
					}
				})
			},
			goodclck(item){
				this.info = item
				if(item.type==1){
					this.slected=1
				}else{
					this.slected=2
				}
			},
			
			handleClick(index) {},
		},
	}
</script>

<style scoped lang="scss">
	.list {

		.listitem {
			width: 920px;
			padding: 20px;

			border: 1px solid #EEEEEE;

			.top {
				display: flex;
				justify-content: space-between;
			}

			.desc {
				margin-top: 20px;
				color: #333333;
				font-size: 16px;
			}
		}
	}

	.btn {
		width: 112px;
		height: 42px;
		border-radius: 26px;
		border: 1px solid #FF6A00;
		color: #FF6A00;
		text-align: center;
		line-height: 42px;
	}



	::v-deep .el-tabs__item.is-active {
		color: #FF6A00; //选中
		opacity: 1;
	}

	::v-deep .el-tabs__item:hover {
		color: #FF6A00; //悬浮
		cursor: pointer;
		opacity: 1;
	}

	/* 下划线颜色 */
	::v-deep .el-tabs__active-bar {
		background-color: #FF6A00;
	}

	::v-deep .el-tabs__nav-wrap::after {

		position: static !important;

	}

	.goodlist {
		.good {
			// display: flex;
			padding: 20px;
			margin-top: 20px;
			border: 1px solid #EEEEEE;
			justify-content: space-between;

			.icon {
				width: 120px;
				height: 120px;
				margin-right: 20px;
				border-radius: 10px;
			}

			.goodname {
				font-size: 20px;
				color: #333333;
			}

			.goodguige {
				font-size: 16px;
				color: #333333;
			}

			.goodgnumber {
				font-size: 16px;
				color: #666;
			}


		}
	}

	.goodtype {
		color: #FF6A00;
		font-size: 16px;
		border-radius: 22px;
		width: 92px;
		height: 44px;
		border: 1px solid #FF6A00;
		text-align: center;
		line-height: 44px;
		margin-top: 60px;
	}
</style>