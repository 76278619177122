<template>
	<div style="background-color: #fff;padding: 20px;
				background: #fff;
				width: 1000px;">
		<div style="display: flex;justify-content: space-between;">
			<div style="color: #333333;font-size: 24px;padding-left: 10px;border-left: 4px solid #FF6A00;">
				积分
			</div>
			<div class="btn" @click="goback">
				返回
			</div>
		</div>


		<div class="grop">
			<div class="gropitem" style="width: 240px;margin-left: 20px;margin-top: 20px;" v-for="item in titles">
				<img class="icon" :src="item.icon" alt="" />
				<div>
					<div class="number">
						{{item.number}}
					</div>
					<div class="title">
						{{item.title}}
					</div>
				</div>
			</div>
		</div>
		<div class="zhongjiang">
			<div class="top">
				<img class="iconleft" src="../assets/my/jifen4.png" mode=""></img>
				<span class="title" style="color: #FF6A00;">积分明细</span>
				<img class="iconright" src="../assets/my/jifen5.png" mode="">
				</img>
			</div>
			<div class="list">
				<div class="listitem" v-for="(item,index) in list">

					<div class="name">
						{{item.notes}}
					</div>
					<div class="time">
						{{item.create_time}}
					</div>
					<div v-show="item.type==1" class="fenshu">
						+{{item.num}}
					</div>
					<div v-show="item.type==2" class="fenshu" style="color:#666666">
						-{{item.num}}
					</div>
				</div>

			</div>
		</div>
		<el-pagination v-model:current-page="pagesize.page" :page-size="pagesize.limit" :background="background"
			layout="total, prev, pager, next" :total="pagesize.count" @size-change="handleSizeChange"
			@current-change="getdata" />

	</div>
</template>

<script>
	import {
		myPoint
	} from "@/utils/Myapi.js"

	import img1 from "@/assets/my/jifen1.png";
	import img2 from "@/assets/my/jifen2.png";
	import img3 from "@/assets/my/jifen3.png";
	export default {
		// 组件名
		name: 'heAd',
		// 注册组件
		components: {},
		// 接收父组件数据
		props: {},
		// 组件数据
		data() {
			return {
				activeName: '',
				pagesize: {
					count: 0,
					limit: 10,
					page: 1,
					status: ''
				},
				FullShow: true,
				dialogVisible: false,
				status: '',
				lang: '',
				list: [],
				input3: "",
				datainfo: {},
				titles: [{
						icon: img1,
						title: '我的积分',
						number: '47874'
					},
					{
						icon: img2,
						title: '今日获取积分',
						number: '47874'
					},
					{
						icon: img3,
						title: '总获取积分',
						number: '47874'
					},
				],
			}
		},
		// 计算属性login
		computed: {},
		// 监听属性
		watch: {},
		// 生命周期钩子函数
		created() {


		},
		mounted() {
			this.getdata()
		},
		// 组件方法
		methods: {
			goback() {
				console.log('zi组件方法');
				this.$parent.mychange();
			},
			getdata() {
				//我的积分
				myPoint(this.pagesize).then(res => {
					if (res.code == 200) {
						this.list = res.data.data
						this.datainfo = res.data
						this.pagesize.count = res.data.count
						for (var item in this.titles) {
							if (item == 0) {
								this.titles[0].number = this.datainfo.mypoint
							}
							if (item == 1) {
								this.titles[1].number = this.datainfo.today_point
							}
							if (item == 2) {
								this.titles[2].number = this.datainfo.all_point
							}
						}
					}
				})
			},
			handleClick(index) {
				// for (let var1 in this.titles) {
				// 	this.titles[var1].ishow = false
				// }
				// this.titles[index].ishow = true

				// switch (index) {}
			},
		},
	}
</script>

<style scoped lang="scss">
	.grop {
		display: flex;
		flex-wrap: wrap;

		.gropitem {
			width: 240px;
			height: 70px;
			padding: 20px;
			display: flex;

			border-radius: 10px 10px 10px 10px;
			border: 1px solid #EEEEEE;

			.icon {
				width: 70px;
				height: 70px;
				margin-right: 20px;
				margin-right: 20px;
			}

			.number {

				font-size: 24px;
				color: #333333;
			}

			.title {
				margin-top: 12px;
				color: #666666;

				font-size: 16px;
			}
		}
	}

	.btn {
		width: 112px;
		height: 42px;
		border-radius: 26px;
		border: 1px solid #FF6A00;
		color: #FF6A00;
		text-align: center;
		line-height: 42px;
	}

	.zhongjiang {

		background-color: #fff;
		// border-radius: 10rpx;
		// margin: 30rpx;
		margin-top: 40rpx;

		.top {
			height: 60px;
			margin-left: auto;
			margin-right: auto;
			// border-bottom: 1rpx solid #FFFFFF;
			color: #fff;
			font-size: 20px;
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;

			.title {
				font-size: 20px;
				font-weight: bold;
			}

			.iconleft {
				width: 28px;
				height: 20px;
				margin-right: 20px;
			}

			.iconright {
				width: 28px;
				height: 20px;
				margin-left: 20px;
			}

		}

		.list {
			font-size: 16px;
			font-family: PingFang SC, PingFang SC;
			font-weight: 400;
			color: #FFFFFF;
			margin: 20px;
			// padding-bottom: 30rpx;
			// margin-top: 0rpx;
			// padding-top: 30rpx;

			.listitem {
				display: flex;
				justify-content: space-between;
				align-items: center;
				height: 70px;
				border-bottom: 1px solid #EEEEEE;

				.time {
					color: #666666;
					font-size: 16px;
					margin-top: 23rpx;

				}

				.name {
					color: #333333;
					font-size: 16px;
					width: 200px;
				}

				.fenshu {
					line-height: 70px;
					font-size: 20px;
					width: 100px;
					color: #FF6A00;
				}
			}

		}

	}
	/* 修改前后箭头未点击时的背景颜色 */
	::v-deep .el-pagination .btn-prev,
	::v-deep .el-pagination .btn-next {
		/* background-color: #0e3a90 !important; */
	}
	
	/* 修改未点击时的数字方块背景颜色 */
	:v-deep .el-pagination .el-pager li {
		/* background-color: #75fbff !important; */
		color: #FF6A00!important;
	}
	
	/* 未点击时的数字方块背景颜色 */
	::v-deep .el-pager li.is-active {
		/* background-color: #0e3a90 !important; */
		color: #FF6A00!important;
	}
	
	::v-deep .el-pagination.is-background .el-pager li:not(.is-disabled).is-active {
		/* background-color: #75fbff !important; //修改默认的背景色 */
		color: #01133c!important;
	}
</style>