<template>
	<div style="background-color: #fff;padding: 20px;
				background: #fff;
				width: 1000px;">
		<div style="display: flex;justify-content: space-between;align-items: center;">
			<div v-show="type!=1"
				style="color: #333333;font-size: 24px;padding-left: 10px;border-left: 4px solid #FF6A00;">
				团队人员添加人员
			</div>
			<div v-show="type==1"
				style="color: #333333;font-size: 24px;padding-left: 10px;border-left: 4px solid #FF6A00;">
				个人认证
			</div>
			<div style="display: flex;">
				<div class="btn" @click="goback">
					返回
				</div>
				<div class="btn1" @click="addclick">
					提交
				</div>
			</div>

		</div>
		<div>
			<div class="list">
				<el-form :model="formInline" class="demo-form-inline" label-width="100">
					<el-form-item label="真实姓名：">
						<el-input v-model="formInline.real_name" placeholder="请输入"
							clearable />
					</el-form-item>
					<el-form-item label="公司：">
						<el-input  v-model="formInline.company" placeholder="请输入" clearable />
					</el-form-item>
					<el-form-item label="职务：">
						<el-input  v-model="formInline.job" placeholder="请输入" clearable />
					</el-form-item>
					<el-form-item label="证件类型：">
						<el-select v-model="formInline.id_type" placeholder="请选择" clearable>
							<el-option label="身份证" value="0" />
							<el-option label="护照" value="2" />
							<el-option label="港澳台通行证" value="1" />
							<el-option label="港澳居民来往内地通行证" value="3" />
							<el-option label="台湾居民来往大陆通行证" value="4" />
							<el-option label="外国人永久居留身份证" value="5" />
							<el-option label="港澳居民居住证" value="6" />
							<el-option label="台湾居民居住证" value="7" />
						</el-select>
					</el-form-item>
					<el-form-item label="证件号码：">
						<el-input v-model="formInline.id_number" placeholder="请输入"
							clearable />
					</el-form-item>
			
					<el-form-item label="手机号码：">
						<el-input style="width: 210px;margin-right: 10px;" v-model="formInline.mobile" placeholder="请输入"
							clearable />
						<el-button @click="getcode"
							style="background-color: #FF6A00;color: #fff;">{{mobileCodeText}}</el-button>
					</el-form-item>
					<el-form-item label="验证码：">
						<el-input v-model="formInline.mobile_code" placeholder="请输入" clearable />
					</el-form-item>
					
					<el-form-item label="邮箱：">
						<el-input style="width: 210px;margin-right: 10px;" v-model="formInline.email" placeholder="请输入"
							clearable />
						<el-button @click="getcode2"
							style="background-color: #FF6A00;color: #fff;">{{emailCodeText}}</el-button>
					</el-form-item>
					<el-form-item label="验证码：">
						<el-input v-model="formInline.email_code" placeholder="请输入" clearable />
					</el-form-item>
					<!-- <el-form-item label="驳回原因：">
						<div :disabled="true">{{formInline.content}}</div>
					</el-form-item> -->
				</el-form>
				<div class="bohui" v-if="formInline.status==3">
					<div style="display: flex;align-items: center;">
						<img class="bohuiicon" src="../../src/assets/my/bohui.png" alt="" />
						<div>被驳回原因</div>
					</div>
					<div style="margin-top: 20px;">
						{{formInline.content}}
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus'
	import {
		authenticationList,
		authGroupAdd,
		lookauthGroupEdit,
		authGroupEdit,
		myInfo
	} from "@/utils/Myapi.js"
	import {
		login,
		sendcode,
		pagehome,
		meetingList,
		sitelist,
		activitytrade,
		activityclass,
		meetingOrderData,
		sendEmailCode,
		MeetingOrderpay,
		calculatePrice,
		createOrder
	} from "@/utils/api"
	export default {
		// 组件名
		name: 'heAd',
		// 注册组件
		components: {},
		// 接收父组件数据
		props: {
			userid: String,
			type: String
		},
		// 组件数据
		data() {
			return {
				formInline: {
					type: '2'
				},
				activeName: '',
				FullShow: true,
				dialogVisible: false,
				status: '',
				lang: '',
				input3: "",
				titles: [],
				emailCodeText: '获取验证码',
				mobileCodeText: '获取验证码',
				buttonText: '获取验证码',
				count: 60, // 倒计时时间，单位为秒
				count1: 60,
				disabled: false,
				disabled1: false,
			}
		},
		// 计算属性login
		computed: {},
		// 监听属性
		watch: {},
		// 生命周期钩子函数
		created() {


		},
		mounted() {
			console.log('this.userid=====', this.userid);

			if (this.type == 1) {
				this.getmyinfo()
			} else {
				if (this.userid > 0) {
					this.getdesc()
				}
			}

		},
		// 组件方法
		methods: {
			getmyinfo() {
				myInfo().then(res => {
					if (res.code == 200) {
						this.formInline = res.data.my_auth_info
					}
				})
			},
			goback() {
				console.log('zi组件方法');
				this.$parent.fatherMethod();
			},
			getdesc() {
				//信息
				lookauthGroupEdit({
					"id": this.userid
				}).then(res => {
					if (res.code == 200) {
						this.formInline = res.data
					}
				})
			},

			addclick() {

				if (this.type == 1) {
					this.formInline.type = 1
				} else {
					this.formInline.type = 2
				}

				if (this.userid) {
					this.formInline.id = this.userid
					authGroupEdit(this.formInline).then(res => {
						if (res.code == 200) {
							ElMessage({
								message: '提交成功',
								type: 'success',
							})
							this.goback()
						}
					})
				} else {
					authGroupAdd(this.formInline).then(res => {
						if (res.code == 200) {
							ElMessage({
								message: '提交成功',
								type: 'success',
							})
							this.goback()
						} else {
							ElMessage({
								message: res.message,
								type: 'error',
							})
						}
					})
				}

			},
			getcode() {
				if (this.disabled) {
					return
				}

				sendcode({
					"mobile": this.formInline.mobile
				}).then(res => {
					if (res.code == 200) {
						ElMessage({
							message: '发送成功',
							type: 'success',
						})
						this.disabled = true;
						this.mobileCodeText = '重新发送 (' + this.count + 's)';
						const timer = setInterval(() => {
							this.count--;
							this.mobileCodeText = '重新发送 (' + this.count + 's)';

							if (this.count <= 0) {
								clearInterval(timer);
								this.mobileCodeText = '获取验证码';
								this.disabled = false;
								this.count = 60; // 重置倒计时时间
							}
						}, 1000);
					} else {
						ElMessage({
							message: res.message,
							type: 'error',
						})
					}

				})
			},
			getcode2() {
				if (this.disabled1) {
					return
				}

				sendEmailCode({
					"email": this.formInline.email,
					"name": this.formInline.real_name,
				}).then(res => {
					if (res.code == 200) {
						ElMessage({
							message: '发送成功',
							type: 'success',
						})
						this.disabled1 = true;
						this.emailCodeText = '重新发送 (' + this.count1 + 's)';
						const timer = setInterval(() => {
							this.count1--;
							this.emailCodeText = '重新发送 (' + this.count1 + 's)';

							if (this.count1 <= 0) {
								clearInterval(timer);
								this.emailCodeText = '获取验证码';
								this.disabled1 = false;
								this.count1 = 60; // 重置倒计时时间
							}
						}, 1000);
					} else {
						ElMessage({
							message: res.message,
							type: 'eror',
						})
					}
				})
			},
			// handleClick(index) {
			// 	for (let var1 in this.titles) {
			// 		this.titles[var1].ishow = false
			// 	}
			// 	this.titles[index].ishow = true

			// 	switch (index) {}
			// },
		},
	}
</script>

<style scoped lang="scss">
	.list {
		margin-top: 40px;

		.demo-form-inline .el-input {
			--el-input-width: 320px;
		}

		.demo-form-inline .el-select {
			--el-select-width: 320px;
		}
	}

	.btn {
		width: 112px;
		height: 42px;
		border-radius: 26px;
		border: 1px solid #FF6A00;
		color: #FF6A00;
		text-align: center;
		line-height: 42px;
		margin-right: 20px;
	}

	.btn1 {
		width: 112px;
		height: 42px;
		border-radius: 26px;
		color: #fff;
		background-color: #FF6A00;
		text-align: center;
		line-height: 42px;
		margin-right: 20px;
	}
	.bohui {
		margin: 40px;
		width: 350px;
		padding: 20px;
		text-align: center;
		border: 1px solid #FF6A00;
		border-radius: 5px;
		color: #FF6A00;
		background-color: #FFF9F5;
	
		.bohuiicon {
			width: 20px;
			height: 20px;
			margin-right: 10px;
		}
	}
</style>