<template>
	<div style="background-color: #fff;padding: 20px;
				background: #fff;
				width: 1000px;">
		<div style="display: flex;justify-content: space-between;align-items: center;">
			<div style="color: #333333;font-size: 24px;padding-left: 10px;border-left: 4px solid #FF6A00;">
				团队人员
			</div>
			<div style="display: flex;">
				<div class="btn" @click="goback">
					返回
				</div>
				<!-- <div class="btn1" @click="addclick">
					提交
				</div> -->
			</div>

		</div>

		<div>
			<div class="list">
				<el-form :model="formInline" class="demo-form-inline" label-width="100">
					<el-form-item label="真实姓名：">
						<el-input disabled="true" v-model="formInline.real_name" placeholder="请输入" clearable />
					</el-form-item>
					<el-form-item label="公司：">
						<el-input disabled="true" v-model="formInline.company" placeholder="请输入" clearable />
					</el-form-item>
					<el-form-item label="职务：">
						<el-input disabled="true" v-model="formInline.job" placeholder="请输入" clearable />
					</el-form-item>
					<el-form-item label="证件类型：">
						<el-select disabled="true" v-model="formInline.id_type" placeholder="请选择" clearable>
							<el-option label="身份证" value="0" />
									<el-option label="护照" value="2" />
									<el-option label="港澳台通行证" value="1" />
									<el-option label="港澳居民来往内地通行证" value="3" />
									<el-option label="台湾居民来往大陆通行证" value="4" />
									<el-option label="外国人永久居留身份证" value="5" />
									<el-option label="港澳居民居住证" value="6" />
									<el-option label="台湾居民居住证" value="7" />
						</el-select>
					</el-form-item>
					<el-form-item label="证件号码：">
						<el-input disabled="true" v-model="formInline.id_number" placeholder="请输入" clearable />
					</el-form-item>
					<el-form-item label="手机号码：">
						<el-input disabled="true" v-model="formInline.mobile" placeholder="请输入" clearable />
					</el-form-item>

					<el-form-item label="邮箱：">
						<el-input disabled="true" v-model="formInline.email" placeholder="请输入" clearable />
					</el-form-item>

				</el-form>
			</div>
		</div>

	</div>
</template>

<script>
	import {
		lookauthGroupEdit,
		myInfo
	} from "@/utils/Myapi.js"
	export default {
		// 组件名
		name: 'heAd',
		// 注册组件
		components: {},
		// 接收父组件数据
		props: {
			userid: String,
			type: String
		},
		// 组件数据
		data() {
			return {
				formInline: {},
				activeName: '',
				FullShow: true,
				dialogVisible: false,
				status: '',
				lang: '',
				input3: "",
				titles: [],
			}
		},
		// 计算属性login
		computed: {},
		// 监听属性
		watch: {},
		// 生命周期钩子函数
		created() {


		},
		mounted() {

			if (this.type == 1) {
				this.getmyinfo()
			} else {
				this.getdesc()
			}
		},
		// 组件方法
		methods: {
			goback() {
				this.$parent.fatherMethod();
			},
			getmyinfo() {
				myInfo().then(res => {
					if (res.code == 200) {
						this.formInline = res.data.my_auth_info
					}
				})
			},
			getdesc() {
				//我的团队
				lookauthGroupEdit({
					"id": this.userid
				}).then(res => {
					if (res.code == 200) {
						this.formInline = res.data

					}
				})

			},
			handleClick(index) {
				for (let var1 in this.titles) {
					this.titles[var1].ishow = false
				}
				this.titles[index].ishow = true

				switch (index) {}
			},
		},
	}
</script>

<style scoped lang="scss">
	.list {
		margin-top: 40px;

		.demo-form-inline .el-input {
			--el-input-width: 220px;
		}

		.demo-form-inline .el-select {
			--el-select-width: 220px;
		}
	}

	.btn {
		width: 112px;
		height: 42px;
		border-radius: 26px;
		border: 1px solid #FF6A00;
		color: #FF6A00;
		text-align: center;
		line-height: 42px;
		margin-right: 20px;
	}
</style>