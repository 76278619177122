<template>
	<div style="
      background-color: #fff;
      padding: 20px;
      background: #fff;
      width: 1000px;
    ">
		<div style="display: flex; justify-content: space-between">
			<div
				style=" color: #333333; font-size: 24px; padding-left: 10px; border-left: 4px solid #ff6a00;line-height: 42px;">
				优惠卷
			</div>
			<div class="btn" @click="goback">返回</div>
		</div>

		<el-tabs v-model="activeName" @tab-click="handleClick">
			<el-tab-pane label="全部" name="first"></el-tab-pane>
			<el-tab-pane label="待使用" name="second"></el-tab-pane>
			<el-tab-pane label="已使用" name="third"></el-tab-pane>
			<el-tab-pane label="已过期" name="fourth"></el-tab-pane>
		</el-tabs>

		<div class="list">
			<div class="citem" v-for="item in list">
				<div class="citemtop">
					<div style="display: flex">
						<div v-show="item.type==2" style="font-size: 48px; margin-left: 84px">{{item.point}}</div>
						<div v-show="item.type==1" style="font-size: 48px; margin-left: 84px">{{item.dikou_amount}}
						</div>
						<div v-show="item.type==3" style="font-size: 48px; margin-left: 84px">{{item.discount}}</div>
						<div v-show="item.type==1" style="font-size: 14px; text-align: start; margin-top: 30px">
							元
						</div>
						<div v-show="item.type==2" style="font-size: 14px; text-align: start; margin-top: 30px">
							积分
						</div>
						<div v-show="item.type==3" style="font-size: 14px; text-align: start; margin-top: 30px">
							折
						</div>
					</div>

					<div v-show="item.type==1" class="tiaojian">使用条件：满{{item.manzu_amount}}-{{item.dikou_amount}}</div>
					<div v-show="item.type==2" class="tiaojian">使用条件：抵扣{{item.point}}</div>

					<div v-show="item.type==3" class="tiaojian">使用条件：打{{item.discoun}}折</div>

					<div class="tiaojian">有效期：{{item.expire_time}}</div>
					<div>
						<img class="line" src="../assets/my/line.png" alt="" />
					</div>
				</div>
				<div class="showcontent" style="background-color: #F7F7F7;padding-top: 20px;">
					<div class="title" style="color: #333333;">使用规则:</div>
					<div class="content" style="color: #333333;">
						{{item.rule}}
					</div>
				</div>
				<div v-show="item.used==1" style="display: flex;align-items: center;justify-content: center;background-color: #F7F7F7;padding-bottom:20px">
					<div class="btn1">{{item.status_text}}</div>
				</div>
				<div v-show="item.used==2" style="display: flex;align-items: center;justify-content: center;background-color: #F7F7F7;padding-bottom:20px">
					<div class="btn2">{{item.status_text}}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		couponBillList
	} from "@/utils/Myapi.js"
	export default {
		// 组件名
		name: "heAd",
		// 注册组件
		components: {},
		// 接收父组件数据
		props: {},
		// 组件数据
		data() {
			return {
				activeName: "first",
				FullShow: true,
				dialogVisible: false,
				status: "",
				lang: "",
				input3: "",
				titles: [

				],
				list: [],
				pagesize: {
					type: ''
				}
			};
		},
		// 计算属性login
		computed: {},
		// 监听属性
		watch: {},
		// 生命周期钩子函数
		created() {},
		mounted() {
			this.getdata()
		},
		// 组件方法
		methods: {
			goback() {
				console.log('zi组件方法');
				this.$parent.mychange();
			},

			getdata() {

				couponBillList(this.pagesize).then(res => {
					if (res.code == 200) {
						this.list = res.data.data
					}
				})
			},
			handleClick(e) {
				this.pagesize.type = e.index
				// for (let var1 in this.titles) {
				// 	this.titles[var1].ishow = false;
				// }
				// this.titles[index].ishow = true;
				this.getdata()
				// switch (index) {}
			},
		},
	};
</script>

<style scoped lang="scss">
	.grop {
		display: flex;
		flex-wrap: wrap;

		.gropitem {
			width: 240px;
			height: 70px;
			padding: 20px;
			display: flex;

			border-radius: 10px 10px 10px 10px;
			border: 1px solid #eeeeee;

			.icon {
				width: 70px;
				height: 70px;
				margin-right: 20px;
				margin-right: 20px;
			}

			.number {
				font-size: 24px;
				color: #333333;
			}

			.title {
				margin-top: 12px;
				color: #666666;

				font-size: 16px;
			}
		}
	}

	.btn {
		width: 112px;
		height: 42px;
		border-radius: 26px;
		border: 1px solid #ff6a00;
		color: #ff6a00;
		text-align: center;
		line-height: 42px;
	}

	.list {
		font-size: 16px;
		font-family: PingFang SC, PingFang SC;
		font-weight: 400;
		color: #ffffff;
		margin: 20px;
		display: flex;
		flex-wrap: wrap;

		// padding-bottom: 30rpx;
		// margin-top: 0rpx;
		// padding-top: 30rpx;

		.citem {
			width: 286px;
			height: 626px;
			margin-right: 20px;
			margin-top:20px;
			// border-bottom: 1px solid #EEEEEE;
			.citemtop {
				height: 176px;
				background-color: #ff6a00;
				color: fff;
				padding-top: 30px;
			}

			.time {
				color: #666666;
				font-size: 16px;
				margin-top: 23rpx;
			}

			.name {
				color: #333333;
				font-size: 16px;
			}

			.fenshu {
				line-height: 70px;
				font-size: 20px;
				color: #ff6a00;
			}
		}
	}

	.addbtn {
		width: 112px;
		height: 42px;
		border-radius: 26px;
		border: 1px solid #ff6a00;
		color: #ff6a00;
		text-align: center;
		display: flex;
		// line-height: 42px;
		align-items: center;
		justify-content: center;
		margin-right: 40px;
	}

	.tiaojian {
		margin-top: 10px;
		padding-left: 20px;
	}

	.line {
		// width: 100%;
		height: 20px;
		position: relative;
		left: -10px;
		right: -10px;
		bottom: -40px;
		width: 300px;
	}

	.title {
		font-size: 16px;
		color: #333333;
		margin-top: 20px;
		margin-bottom: 10px;
		margin-left: 20px;
		font-weight: bold;
	}

	.content {
		font-size: 16px;
		color: #333333;
		margin-left: 20px;
		height: 280px;
		overflow-y: auto;

	}

	.btn1 {
		width: 92px;
		height: 42px;
		border-radius: 26px;
		border: 1px solid #FF6A00;
		color: #FF6A00;
		text-align: center;
		line-height: 42px;
		margin-right: 20px;
	}

	.btn2 {
		width: 92px;
		height: 42px;
		border-radius: 26px;
		border: 1px solid #D8D8D8;
		color: #999999;
		text-align: center;
		line-height: 42px;
		margin-right: 20px;
		background-color: #EEEEEE;
	}

	::v-deep .el-tabs__item.is-active {
		color: #FF6A00; //选中
		opacity: 1;
	}

	::v-deep .el-tabs__item:hover {
		color: #FF6A00; //悬浮
		cursor: pointer;
		opacity: 1;
	}



	/* 下划线颜色 */
	::v-deep .el-tabs__active-bar {
		background-color: #FF6A00;
	}

	::v-deep .el-tabs__nav-wrap::after {

		position: static !important;

	}
</style>