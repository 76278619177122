<template>
	<div style="background-color: #fff;padding: 20px;
				background: #fff;
				width: 1000px;">
		<div v-if="slected==0">
			<div style="color: #333333;font-size: 24px;padding-left: 10px;border-left: 4px solid #FF6A00;margin-bottom: 20px;">
				商城订单
			</div>
			<el-tabs v-model="activeName" @tab-click="handleClick">
				<el-tab-pane label="全部" name="1"></el-tab-pane>
				<el-tab-pane label="待支付" name="2"></el-tab-pane>
				<el-tab-pane label="支付审核中" name="3"></el-tab-pane>
				<el-tab-pane label="待发货" name="4"></el-tab-pane>
				<el-tab-pane label="待收货" name="5"></el-tab-pane>
				<el-tab-pane label="已完成" name="6"></el-tab-pane>
				<el-tab-pane label="已关闭" name="7"></el-tab-pane>
				<el-tab-pane label="售后中" name="8"></el-tab-pane>
			</el-tabs>
			<div>
				<!-- 1.待付款 2.待审核（支付审核） 3.代发货 4.待收货 5.退款（售后）6.已完成 7.已取消（已关闭） -->
				<div class="goodlist">
					<div class="good" v-for="(item,index) in list">
						<div style="display: flex;justify-content: space-between;">
							<div style="display: flex;">
								<div>
									<img class="icon" :src="item.cover_img" alt="" />
								</div>
								<div>
									<div class="goodname">
										{{item.goods_name}}
									</div>
									<div class="goodguige" style="margin-top: 10px;margin-bottom: 10px;">
										{{item.goods_spec}}
									</div>
									<div class="goodnumber">
										x{{item.goods_num}}
									</div>
								</div>
							</div>
							<!-- 1.待支付 2.代发货 3.待收货 4.已完成 5.申请售后 6.售后中 7.已取消 8.已退款 , 9 退款中' -->
							<div class="goodtype">
								{{item.my_order_status_str}}
							</div>
						</div>

						<div>
							<el-divider></el-divider>
							<div style="display: flex;justify-content: space-between;">
								<div>

								</div>
								<div style="display: flex;">
									<div class="btn" v-show="item.status==1||item.status==2" @click="chanelOrder(item)">
										取消
									</div>
									<div class="btn" @click="lookdes(item)">
										查看详情
									</div>
									<div class="btn" v-show="item.pay_status==2&&item.status==1"  @click="gopingzheng(item)">
										查看凭证
									</div>
									<!-- <div class="btn" v-show="item.status==1" v-show="item.pay_status==2">
										退款
									</div> -->
									<div class="btn" @click="payclick(item)" v-show="item.status==1">
										去支付
									</div>
								</div>

							</div>
						</div>
					</div>

				</div>
			</div>
			<el-pagination v-model:current-page="pagesize.page" :page-size="pagesize.limit"
				:background="background" layout="total, prev, pager, next" :total="pagesize.count"
				@size-change="handleSizeChange" @current-change="handleCurrentChange" />
		</div>
		
			
		<mygoodorderDesc :goodId='slectedInfo.id' v-if="slected==1"></mygoodorderDesc>
		
	</div>
</template>


<script>
	import img1 from "@/assets/my/t1.png";
	import img2 from "@/assets/my/t2.png";
	import img3 from "@/assets/my/t3.png";
	import img4 from "@/assets/my/t4.png";
	import img5 from "@/assets/my/t5.png";
	import img6 from "@/assets/my/t6.png";
	import img7 from "@/assets/my/t7.png";

	import mygoodorderDesc from '@/components/mygoodorderDesc'
	import mygoodordertuikuan from '@/components/mygoodordertuikuan'
	import {
		ElMessage,
		ElMessageBox
	} from 'element-plus'
	import {
		Action
	} from 'element-plus'

	import {
		mymyGoodsList,
		cancelGoodsOrder
	} from "@/utils/Myapi.js"

	export default {
		// 组件名
		name: 'heAd',
		// 注册组件
		components: {
			mygoodorderDesc,
			mygoodordertuikuan
		},
		// 接收父组件数据
		props: {},
		// 组件数据
		data() {
			return {
				slected: 0,
				activeName: '1',
				FullShow: true,
				dialogVisible: false,
				status: '',
				lang: '',
				input3: "",
				slectedInfo: {},
				list: [],
			
				pagesize: {
					count: 0,
					limit: 5,
					page: 1,
					status:''
				},

			}
		},
		// 计算属性login
		computed: {},
		// 监听属性
		watch: {},
		// 生命周期钩子函数
		created() {


		},
		mounted() {
			this.getdata()
		},
		// 组件方法
		methods: {
			handleCurrentChange(){
				this.getdata()
			},
			// pingzheng
			gopingzheng(item) {
				
				
				this.openUrl = 'http://exhibition-pc.huijik.com/#/pingzheng?from=1&id=' + item.id;
				
				window.open(this.openUrl);
				
				// this.$router.push({
				// 	name: 'pingzheng',
				// 	query: {
				// 		'id': item.id,
				// 		'from': '1'
				// 	}
				// })
				
				
				
			},
			chanelOrder(item) {
				ElMessageBox.confirm(
						'您确定要，取消订单', {
							confirmButtonText: '确定',
							// confirmButtonClass: '{background: #FF6A00!important;color: #fff!important;}',
							cancelButtonText: '取消',
							confirmButtonClass: 'btnConfirm',
							center: true,
						}
					)
					.then(() => {

						this.quexiao(item)
					})
					.catch(() => {

					})

			},
			quexiao(data) {

				cancelGoodsOrder({
					"order_id": data.id
				}).then(res => {
					if (res.code == 200) {
						ElMessage({
							message: '取消成功',
							type: 'success',
						})
						this.getdata()
					}else{
						ElMessage({
							message:res.message,
							type: 'error',
						})
					}
				})
			},
			lookdes(item) {
				this.slectedInfo = item
				this.slected = 1
			},
			fatherMethod() {
				this.slected = 0
				this.getdata()
			},
			payclick(item) {
				this.$router.push({
					name: 'orderpay',
					query: {
						//money_type 1.全积分 2.全现金 3.积分+现金
						'order_id': item.id,
						"order_no": item.orderno,
						"pay_point": item.pay_point,
						"pay_price": item.pay_price,
						"money_type": item.money_type,
						"from": '1',
					}
				})

			},
			getdata() {
				mymyGoodsList(this.pagesize).then(res => {
					if (res.code == 200) {
						
						this.list = res.data.data
						
						console.log('222222==',res.data.count)
						
						this.pagesize.count = res.data.count
					}
				})
			},
			handleClick(e) {
				
				console.log(e.index)
				if(e.index==5){
					this.pagesize.status = 6
				}else if(e.index==6){
					this.pagesize.status = 7
				}
				else if(e.index==7){
					this.pagesize.status = 5
				}
				else{
					this.pagesize.status = e.index
				}
				
				this.getdata()
				
			},
		}
	}
</script>
<style lang='scss'>
	.btnConfirm {
		background: #FF6A00 !important;
		color: #fff !important;
		border: 1px solid #FF6A00 !important;
	}

	.btnCancel {
		background: #009BF4 !important;
		color: #FFFFFF !important;
	}

	.el-button:first-child:hover {
		color: #333 !important;
		background-color: #fff !important;
		border-color: #999 !important;
	}

	.el-button:nth-child(2):hover {
		color: #FFFFFF !important;
		border-color: #FF6A00 !important;
		background-color: #FF6A00 !important;
	}
</style>
<style scoped lang="scss">
	.list {

		.listitem {
			width: 920px;
			padding: 20px;
			// height: 290px;

			border: 1px solid #EEEEEE;

			.top {
				display: flex;
				justify-content: space-between;
			}

			.desc {
				margin-top: 20px;
				color: #333333;
				font-size: 16px;
			}
		}
	}

	.btn {
		width: 112px;
		height: 42px;
		border-radius: 26px;
		border: 1px solid #FF6A00;
		color: #FF6A00;
		text-align: center;
		line-height: 42px;
		margin-right: 20px;
	}





	.goodlist {
		.good {
			// display: flex;
			padding: 20px;
			margin-top: 20px;
			border: 1px solid #EEEEEE;
			justify-content: space-between;

			.icon {
				width: 120px;
				height: 120px;
				margin-right: 20px;
				border-radius: 10px
			}

			.goodname {
				font-size: 20px;
				color: #333333;
			}

			.goodguige {
				font-size: 16px;
				color: #333333;
			}

			.goodgnumber {
				font-size: 16px;
				color: #666;
			}

			.goodtype {

				color: #FF6A00;
				font-size: 16px;
			}
		}
	}

	.ExitConfirmButton {
		background: #FF6A00 !important;
		color: #fff !important;
	}

	::v-deep .el-tabs__item.is-active {
		color: #FF6A00; //选中
		opacity: 1;
	}

	::v-deep .el-tabs__item:hover {
		color: #FF6A00; //悬浮
		cursor: pointer;
		opacity: 1;
	}

	/* 下划线颜色 */
	::v-deep .el-tabs__active-bar {
		background-color: #FF6A00;
	}

	::v-deep .el-tabs__nav-wrap::after {

		position: static !important;

	}
	/* 修改前后箭头未点击时的背景颜色 */
	::v-deep .el-pagination .btn-prev,
	::v-deep .el-pagination .btn-next {
		/* background-color: #0e3a90 !important; */
	}
	
	/* 修改未点击时的数字方块背景颜色 */
	:v-deep .el-pagination .el-pager li:not(.active):not(.disabled):hover {
		/* background-color: #75fbff !important; */
	}
	
	/* 未点击时的数字方块背景颜色 */
	::v-deep .el-pager li.is-active {
		/* background-color: #0e3a90 !important; */
		color: #FF6A00;
	}
	
	::v-deep .el-pagination.is-background .el-pager li:not(.is-disabled).is-active {
		/* background-color: #75fbff !important; //修改默认的背景色 */
		color: #01133c;
	}
</style>