<template>
	<div style="background-color: #fff;padding: 20px;
				background: #fff;
				width: 1000px;">
		<div v-show="slected==0">
			<div style="display: flex;justify-content: space-between;">

				<div style="color: #333333;font-size: 24px;padding-left: 10px;border-left: 4px solid #FF6A00;">
					商城订单
				</div>
				<div style="display: flex;">
					<div class="btn" @click="goback">
						返回
					</div>
				</div>

			</div>

			<div class="ordertype">
				<div class="top">
					<img class="icon" src="../assets/my/ordertype.png" alt="" />
					<!-- 1.待付款 2.待审核（支付审核） 3.代发货 4.待收货 5.退款（售后）6.已完成 7.已取消（已关闭） -->
					<div v-show="goodinfo.my_order_status==1" class="typename">待支付</div>
					<div v-show="goodinfo.my_order_status==2" class="typename">付款待审核</div>
					<div v-show="goodinfo.my_order_status==3" class="typename">待发货</div>
					<div v-show="goodinfo.my_order_status==4" class="typename">待收货</div>
					<div v-show="goodinfo.my_order_status==5" class="typename">退款详情</div>
					<div v-show="goodinfo.my_order_status==6" class="typename">已完成</div>
					<div v-show="goodinfo.my_order_status==7" class="typename">已取消</div>

				</div>
				<div class="roomitem">
					<div v-show="goodinfo.my_order_status==1" class="roomdesc"
						style="display: flex;align-items: center;">
						该订单尚未支付，请您尽快完成支付
					</div>
					<div v-show="goodinfo.my_order_status==2" class="roomdesc"
						style="display: flex;align-items: center;">
						请耐心等待管理员审核
					</div>
					<div v-show="goodinfo.my_order_status==3" class="roomdesc"
						style="display: flex;align-items: center;">
						等待卖家发货
					</div>
					<div v-show="goodinfo.my_order_status==4" class="roomdesc"
						style="display: flex;align-items: center;">
						请收到货后，记得确认收货
					</div>
					<div v-show="goodinfo.my_order_status==6" class="roomdesc"
						style="display: flex;align-items: center;">
						交易成功，快去评价一下吧
					</div>
					<div v-show="goodinfo.my_order_status==5" class="roomdesc"
						style="display: flex;align-items: center;">

					</div>
					<div v-show="goodinfo.my_order_status==7" class="roomdesc"
						style="display: flex;align-items: center;">

					</div>
					<div class="roomdesc" style="display: flex;align-items: center;">
						<div class="roomdesctitle">下单时间：</div>
						<div class="roomdesccon">{{goodinfo.create_time}}</div>
					</div>
					<div class="roomdesc" style="display: flex;align-items: center;">
						<div class="roomdesctitle">支付积分：</div>
						<div class="roomdesccon" style="display: flex;align-items: center;">
							<img class="jifen" src="../assets/my/jifen.png" alt="" />
							<div style="color: #FF6A00;">{{goodinfo.point}}</div>
						</div>
					</div>
					<div class="roomdesc" style="display: flex;align-items: center;">
						<div class="roomdesctitle">支付金额：</div>
						<div class="roomdesccon" style="color: #FF6A00;font-size: 24px;">
							<span style="font-size: 14px;">￥</span>{{goodinfo.price}}
						</div>
					</div>
					<div class="roomdesc" style="display: flex;align-items: center;">
						<div class="roomdesctitle">订单编号：</div>
						<div class="roomdesccon">{{goodinfo.orderno}}</div>
					</div>
					<div v-if="goodinfo.my_order_status>1" class="roomdesc" style="display: flex;align-items: center;">
						<div class="roomdesctitle">支付时间：</div>
						<div class="roomdesccon">{{goodinfo.pay_time_str}}</div>
					</div>
				</div>

				<div style="display: flex;justify-content: space-between;">
					<div>

					</div>
					<div style="display: flex;">
						<!-- 1.待付款 2.待审核（支付审核） 3.代发货 4.待收货 5.退款（售后）6.已完成 7.已取消（已关闭） -->
						<div @click="chanelOrder" v-show="goodinfo.my_order_status==1||goodinfo.my_order_status==3"
							class="btn" style="margin-right: 20px;">取消订单</div>
						<!-- <div class="btn1" style="margin-right: 20px;">去支付</div> -->
						<div v-show="goodinfo.pay_status==2&&goodinfo.my_order_status==1" class="btn"
							style="margin-right: 20px;">凭证详情</div>
						<div v-show="goodinfo.my_order_status==4" class="btn" style="margin-right: 20px;" @click="look">
							查看物流
						</div>

						<div v-show="goodinfo.my_order_status==6&&goodinfo.is_evaluate==0" class="btn"
							style="margin-right: 20px;" @click="pingjia">
							评价</div>

						<div v-show="goodinfo.my_order_status==6" class="btn" style="margin-right: 20px;"
							@click="tuikuang">
							退款</div>

						<!-- <div @click="deleorder" v-show="goodinfo.my_order_status==7" class="btn"
							style="margin-right: 20px;">删除</div> -->

						<!-- 	<div v-show="goodinfo.my_order_status==5" class="btn" style="margin-right: 20px;"
							@click="lishi">
							协商历史</div> -->

						<div v-show="goodinfo.my_order_status==5" class="btn" style="margin-right: 20px;"
							@click="tuikuangdesc">
							退款详情</div>



						<!-- 	<div @click="revokeRefundone" v-show="goodinfo.my_order_status==5" class="btn"
							style="margin-right: 20px;">撤销</div> -->

						<div v-show="goodinfo.my_order_status==4" class="btn" style="margin-right: 20px;"
							@click="shouhuo">
							确认收货</div>

						<div v-show="goodinfo.my_order_status==1" @click="payclick" class="btn1">去支付</div>
					</div>
				</div>
			</div>
			<div class="info">商品信息</div>

			<div class="goodlist">
				<div class="good">
					<div style="display: flex;">
						<div>
							<img style="width: 120px;height: 120px;margin-right: 20px;border-radius: 10px;"
								:src="goodinfo.cover_img" alt="" />
						</div>
						<div>
							<div class="goodname">
								{{goodinfo.goods_name}}
							</div>
							<div class="goodguige" style="margin-top: 10px;margin-bottom: 10px;">
								{{goodinfo.goods_spec}}
							</div>
							<div class="goodnumber">
								x{{goodinfo.goods_num}}
							</div>
						</div>
					</div>
					<div class="goodtype">

					</div>
				</div>

			</div>

			<div class="info">收货信息</div>

			<div class="adresitem">
				<div style="display: flex;align-items: center;justify-content: space-between;">
					<div>
						<div>{{goodinfo.address_name}}</div>
						<div>{{goodinfo.address_mobile}}</div>
					</div>

					<el-icon v-show="goodinfo.my_order_status==3" color="#FF6A00" @click="edit(item)" style="">
						<EditPen />
					</el-icon>
				</div>
				<div style="margin-top: 20px;">
					<div>{{goodinfo.user_address}}</div>
				</div>
			</div>

			<!-- <div v-show="goodinfo.my_order_status==5" class="info">退款信息</div> -->

			<!-- <div v-show="goodinfo.my_order_status==5" class="roomitem" style="padding: 0;">
				<div class="roomdesc" style="display: flex;align-items: center;">
					<div class="roomdesctitle">退款方式：</div>
					<div class="roomdesccon">仅退款</div>
				</div>
				<div class="roomdesc" style="display: flex;align-items: center;">
					<div class="roomdesctitle">退款金额：</div>
					<div class="roomdesccon" style="display: flex;align-items: center;">
						<img class="jifen" src="../assets/my/jifen.png" alt="" />
						<div style="color: #FF6A00;">2200</div>
					</div>
				</div>
				<div class="roomdesc" style="display: flex;align-items: center;">
					<div class="roomdesctitle">申请时间：</div>
					<div class="roomdesccon">2023-12-12 22:00:00</div>
				</div>
				<div class="roomdesc" style="display: flex;align-items: center;">
					<div class="roomdesctitle">退款描述：</div>
					<div class="roomdesccon">345678976545354657687</div>
				</div>
				<div class="roomdesc" style="display: flex;align-items: center;">
					<div class="roomdesctitle">退款物流单号：</div>
					<div class="roomdesccon">345678976545354657687</div>
				</div>

			</div> -->
		</div>
		<el-dialog v-model="dialogVisible2" title="协商历史" width="500" :before-close="handleClose">
			<div class="peoplelist">
				<div v-for="(item,index) in log">
					<div class="people">
						<img class="peopleicon" src="../assets/touxiang.png" alt="" />
						<div>
							<div class="peoplename">{{item.title}}</div>
							<div class="peopletime">{{item.create_time}}</div>
						</div>
					</div>
					<div class="neirong">
						{{item.content}}
					</div>
				</div>


				<!-- <div class="people">
					<img class="peopleicon" src="../assets/touxiang.png" alt="" />
					<div>
						<div class="peoplename">买家申请退款</div>
						<div class="peopletime">2024-01-01 12:21:12</div>
					</div>
				</div> -->
				<!-- <div class="neirong">
					<div style="margin-top: 15px;">
						退款金额：¥568
					</div>
					<div style="margin-top: 15px;">
						问题描述：不要了，不适合
					</div>
				</div> -->
				<!-- <div class="people">
					<img class="peopleicon" src="../assets/touxiang.png" alt="" />
					<div>
						<div class="peoplename">退款已到账</div>
						<div class="peopletime">2024-01-01 12:21:12</div>
					</div>
				</div> -->

				<!-- <div class="jiedian">
					<img class="wuliuicon" src="../assets/my/mywuliu4.png" alt="" />
					<div>仓库处理中</div>
				</div>
				<div class="neirong" style="border-left: 0px;">
					预计6月30日发货，7月3日送达
				</div> -->
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button @click="handleClose">返回</el-button>

				</div>
			</template>
		</el-dialog>


		<el-dialog v-model="dialogVisible" title="查看物流" width="500" :before-close="handleClose">
			<div style="color: #333333;font-size: 24px;padding-left: 10px;border-left: 4px solid #FF6A00;">
				物流单号：{{wuliuinfo.number}}
			</div>
			<div class="list" style="height: 400px;overflow-y: auto;">
				<div v-for="item in traces">
					<div class="jiedian">
						<img class="wuliuicon" src="../assets/my/mywuliu1.png" alt="" />
						<div>{{item.info}}</div>
					</div>
					<div class="neirong">
						{{item.time}}
					</div>
				</div>

				<!-- <div class="jiedian">
					<img class="wuliuicon" src="../assets/my/mywuliu2.png" alt="" />
					<div>快递运输</div>
				</div>
				<div class="neirong">
					【沈阳市】快件已从辽宁沈阳转运中心发出，正在发往上海转运中心[预计明天送达]
				</div>
				<div class="jiedian">
					<img class="wuliuicon" src="../assets/my/mywuliu3.png" alt="" />
					<div>仓库处理中</div>
				</div>
				<div class="neirong">
					预计6月30日发货，7月3日送达
				</div>
				<div class="jiedian">
					<img class="wuliuicon" src="../assets/my/mywuliu4.png" alt="" />
					<div>仓库处理中</div>
				</div>
				<div class="neirong" style="border-left: 0px;">
					预计6月30日发货，7月3日送达
				</div> -->
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button @click="dialogVisible = false">返回</el-button>

				</div>
			</template>
		</el-dialog>

		<el-dialog v-model="dialogVisible1" title="评价" width="610" :before-close="handleClose">
			<div class="pingjiatitle">
				请输入评价内容
			</div>
			<div style="margin-left: 20px;margin-top: 20px;">
				<el-input :autosize="{ minRows: 6 }" v-model="textarea" type="textarea" placeholder="请输入" />
			</div>

			<div style="display: flex;align-items: center;margin-top: 30px;margin-left: 20px;">

				<!-- <el-upload ref="uploadRef" class="upload-demo"
					action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15" :auto-upload="false">
					<template #trigger>
						<img class="pingjiaIcon" src="../assets/my/tupian.png" alt="" />
					</template>
				</el-upload> -->

				<el-upload v-show="this.videoaddress.length==0" v-model:file-list="fileList" action="#"
					list-type="picture-card" :http-request="($event) => upload($event)" :on-remove="handleRemove"
					:on-preview="handlePictureCardPreview">
					<!-- <template #trigger> -->
					<img class="pingjiaIcon" src="../assets/my/tupian.png" alt="" />
					<!-- </template> -->
					<!-- <el-icon>
						<Plus />
					</el-icon> -->
				</el-upload>

				<el-dialog v-model="dialogVisible4">
					<img style="width: 100%;height:100%;" w-full :src="dialogImageUrl" alt="Preview Image" />
				</el-dialog>

				<div id="" style="margin-left: 20px;">
					<video v-show="fileList.length==0&&this.videoaddress.length!=0" class="video"
						:src="this.videoaddress"></video>
					<el-upload v-show="fileList.length==0&&this.videoaddress.length==0" action="#"
						:show-file-list="false" :http-request="($event) => upload2($event)" :on-remove="handleRemove"
						:on-preview="handlePictureCardPreview" :before-upload="beforeAvatarUpload">
						<template #trigger>
							<img class="pingjiaIcon" src="../assets/my/shipin.png" alt="" />
						</template>
					</el-upload>
				</div>


				<!-- <el-upload v-show="fileList.length==0" ref="uploadRef" class="upload-demo"
					action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15" :auto-upload="false">
					<template #trigger>
						<img class="pingjiaIcon" src="../assets/my/shipin.png" alt="" />
					</template>
				</el-upload> -->
			</div>

			<template #footer>
				<div class="dialog-footer" style="display: flex;justify-content: space-between;">
					<div></div>
					<div style="display: flex;">
						<div class="btn" @click="handleClose">取消</div>
						<div class="btn1" @click="goodsEvaluateclick">
							评价
						</div>
					</div>

				</div>
			</template>
		</el-dialog>

		<el-dialog title="选择收货地址" width="510" v-model="dialogVisible5" :before-close="handleClose">
			<div class="addlist">
				<div class="user_for" v-for="(item,index) in addlist" :key="id">
					<div @click.stop="btnclick(index)" class="">
						<img v-show="!item.ishow" class="icon" src="../assets/chooseNO.png" alt="">
						<img v-show="item.ishow" class="icon" src="../assets/choose.png" alt="">
					</div>
					<div class="right_box">
						<div class="title" style="display: flex;margin-top: 20px;align-items: center;">
							<span style="color: #333333;">{{item.name}} {{item.mobile}}
							</span>
							<div v-show="item.is_default==2" class="moren">
								默认
							</div>
						</div>
						<div class="desc" style="margin-top: 20px;">
							{{item.area}}{{item.address}}
						</div>
					</div>
				</div>
			</div>
			<template #footer>
				<div class="dialog-footer" style="display: flex;justify-content: space-between;">
					<div></div>
					<div style="display: flex;">
						<div class="btn" @click="dialogVisible5 = false">取消</div>
						<div class="btn1" @click="slectedAdd()">
							保存
						</div>
					</div>
				</div>
			</template>
		</el-dialog>

		<el-dialog v-model="dialogVisible3" title="填写物流单号" width="510" :before-close="handleClose">
			<div style="margin-top: 20px;">
				<el-form :model="formInline" class="demo-form-inline" label-width="100">
					<el-form-item label="物流单号：">
						<el-input class="adw" v-model="formInline.express_delivery_number" placeholder="请输入物流单号"
							clearable />
					</el-form-item>
				</el-form>
			</div>
			<template #footer>
				<div class="dialog-footer" style="display: flex;justify-content: space-between;">
					<div></div>
					<div style="display: flex;">
						<div class="btn" @click="dialogVisible3 = false">取消</div>
						<div class="btn1" @click="completeLogclick()">
							提交
						</div>
					</div>
				</div>
			</template>
		</el-dialog>
		<mygoodordertuikuan :goodId='goodId' v-if="slected==2"></mygoodordertuikuan>
		<mygoodorderTuiDesc :goodId='goodId' v-if="slected==3"></mygoodorderTuiDesc>
	</div>
</template>

<script>
	import {
		addressList,
		addressAddEdit,
		deliveryList,
		confirmGoodsOrder,
		goodsOrderAftersales,
		myGoodsDetail,
		completeLogisticsCode,
		changeAddress,
		revokeRefund,
		delGoodsOrder
	} from "@/utils/Myapi.js"

	import mapdata from '@/utils/city.json'
	import {
		newfileUpload,
		newfileUploadvideo
	} from '@/utils/upfile.js'
	import {
		ElMessage,
		ElMessageBox
	} from 'element-plus'
	import {
		Action
	} from 'element-plus'

	import {
		mymyGoodsList,
		cancelGoodsOrder,
		goodsEvaluate
	} from "@/utils/Myapi.js"
	import mygoodordertuikuan from '@/components/mygoodordertuikuan'
	import mygoodorderTuiDesc from '@/components/mygoodorderTuiDesc'
	export default {
		// 组件名
		name: 'heAd',
		// 注册组件
		components: {
			mygoodordertuikuan,
			mygoodorderTuiDesc
		},
		// 接收父组件数据
		props: {
			goodId: String
		},
		// 组件数据
		data() {
			return {
				slected: 0,
				props: {
					label: "name",
					value: "name"
				},
				addlist: [],
				dialogVisible4: false,
				mapJson: mapdata,
				formInline: {},
				activeName: '',
				textarea: '',
				dialogVisible1: false,
				FullShow: true,
				dialogVisible: false,
				dialogVisible2: false,
				status: '',
				lang: '',
				input3: "",
				titles: [],
				goodinfo: {},
				traces: [],
				wuliuinfo: {},
				slideshowurls: [],
				fileList: [],
				dialogImageUrl: '',
				videoinfo: {},
				videoaddress: '',
				video_id: '',
				log: [],
				Aftersales: {},
				dialogVisible3: false,
				express_delivery_number: '',
				dialogVisible5: false,
				slectedinfo: {}
			}
		},
		// 计算属性login
		computed: {},
		// 监听属性
		watch: {},
		// 生命周期钩子函数
		created() {


		},
		mounted() {
			this.getdata()
		},
		// 组件方法
		methods: {
			tuikuangdesc() {
				this.slected = 3
				this.getdata()
			},
			completeLogclick() {
				var formData = {
					'express_delivery_number': this.formInline.express_delivery_number,
					'id': this.goodinfo.id,
				}
				completeLogisticsCode(formData).then((res) => {
					if (res.code == 200) {

						ElMessage({
							message: '提交成功',
							type: 'success',
						})
						this.handleClose()
					}
				})
			},
			addwuliu() {
				this.dialogVisible3 = true;
			},
			goodsEvaluateclick() {

				var formData = {
					'content': this.textarea,
					'id': this.goodinfo.id,
				}
				// img_ids
				var imgs = []
				if (this.slideshowurls.length > 0) {
					for (var item of this.slideshowurls) {
						imgs.push(item.id)
					}

					formData.img_ids = imgs.join(',');
				}
				if (this.video_id.length > 0) {

					formData.video_id = this.video_id
				}

				goodsEvaluate(formData).then((res) => {
					if (res.code == 200) {
						ElMessage({
							message: '评价成功',
							type: 'success',
						})
						this.getdata()
						this.handleClose()
					} else {
						ElMessage({
							message: res.message,
							type: 'error',
						})
					}
				})
			},
			handleClose() {

				this.fileList = []

				this.slideshowurls = []

				this.videoaddress = []

				this.textarea = ''

				this.formInline.express_delivery_number = ''
				this.dialogVisible = false;
				this.dialogVisible1 = false;

				this.dialogVisible2 = false

				this.dialogVisible5 = false
				this.dialogVisible3 = false
			},
			tuikuang() {
				console.log('1111111')
				this.slected = 2

			},
			beforeAvatarUpload(file) {
				console.log(file, 'file')
				// let suffix = this.getFileType(file.name);
				// let suffixArray = ['mp4']; // 限制的文件类型，根据情况自己定义
				// if (suffixArray.indexOf(suffix) === -1) {
				// 	this.$message.warning("只能上传视频");
				// 	return false
				// } else {
				// 	return true
				// }
			},
			upload(e, name) {
				const loading = this.$loading({
					lock: true,
					text: '正在上传，请耐心等候！',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)',
				})
				let file = e.file
				console.log(file)
				let formData = new FormData()
				formData.append('file', file)
				newfileUpload(formData).then((res) => {
					console.log(res, 'file')
					if (res.code == 200) {
						loading.close()
						let obj = {
							uid: file.uid,
							...res.data
						}

						this.slideshowurls.push(obj);
					}
				})
			},
			upload2(e, name) {

				const loading = this.$loading({
					lock: true,
					text: '正在上传，请耐心等候！',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)',
				})

				let file = e.file

				console.log(file)

				let formData = new FormData()

				formData.append('file', file)

				newfileUploadvideo(formData).then((res) => {
					console.log(res, 'file')
					if (res.code == 200) {
						loading.close()
						this.videoaddress = res.data.address;
						this.video_id = res.data.id
					}
				})
			},
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url
				this.dialogVisible4 = true
			},
			handleRemove(file, fileList) {
				console.log(file, "file")
				for (let i in this.slideshowurls) {
					if (this.slideshowurls[i].uid == file.uid || this.slideshowurls[i].address == file.url) {
						delete this.slideshowurls[i]
					}
				}
				console.log(this.slideshowurls)
			},
			deleorder() {
				ElMessageBox.confirm(
						'您确定要，删除订单', {
							confirmButtonText: '确定',
							confirmButtonClass: 'btnConfirm',
							cancelButtonText: '取消',
							// type: 'warning',
							center: true,
						}
					)
					.then(() => {

						this.deleorderclick()
					})
					.catch(() => {

					})
			},

			deleorderclick() {
				delGoodsOrder({
					"id": this.goodinfo.id
				}).then(res => {
					if (res.code == 200) {
						ElMessage({
							message: '操作成功',
							type: 'success',
						})
						this.getdata()
					}
				})
			},
			shouhuo() {
				ElMessageBox.confirm(
						'您确定要，确认收货', {
							confirmButtonText: '确定',
							confirmButtonClass: 'btnConfirm',
							cancelButtonText: '取消',
							// type: 'warning',
							center: true,
						}
					)
					.then(() => {

						this.receivegood()
					})
					.catch(() => {

					})

			},
			receivegood() {

				confirmGoodsOrder({
					"id": this.goodinfo.id
				}).then(res => {
					if (res.code == 200) {
						ElMessage({
							message: '收货成功',
							type: 'success',
						})
						this.getdata()
					}
				})
			},
			revokeRefundone() {
				ElMessageBox.confirm(
						'您确定要撤销退款吗', {
							confirmButtonText: '确定',
							confirmButtonClass: 'btnConfirm',
							cancelButtonText: '取消',
							// type: 'warning',
							center: true,
						}
					)
					.then(() => {

						this.revokeRefundtwo()
					})
					.catch(() => {

					})
			},
			revokeRefundtwo() {
				revokeRefund({
					"id": this.goodinfo.id
				}).then(res => {
					if (res.code == 200) {
						ElMessage({
							message: '撤销成功',
							type: 'success',
						})
						this.getdata()
					} else {
						ElMessage({
							message: res.message,
							type: 'error',
						})
					}
				})
			},




			payclick() {
				this.$router.push({
					name: 'orderpay',
					query: {
						//money_type 1.全积分 2.全现金 3.积分+现金
						'order_id': this.goodinfo.id,
						"order_no": this.goodinfo.orderno,
						"pay_point": this.goodinfo.pay_point,
						"pay_price": this.goodinfo.pay_price,
						"money_type": this.goodinfo.money_type,
						"from": '1',
					}
				})

			},
			chanelOrder() {
				ElMessageBox.confirm(
						'您确定要，取消订单', {
							confirmButtonText: '确定',
							confirmButtonClass: 'btnConfirm',
							cancelButtonText: '取消',
							// type: 'warning',
							center: true,
						}
					)
					.then(() => {

						this.quexiao()
					})
					.catch(() => {

					})

			},
			quexiao() {

				cancelGoodsOrder({
					"order_id": this.goodinfo.id
				}).then(res => {
					if (res.code == 200) {
						ElMessage({
							message: '取消成功',
							type: 'success',
						})
						this.getdata()
					} else {
						ElMessage({
							message: res.message,
							type: 'error',
						})
					}
				})
			},
			goback() {
				console.log('zi组件方法');
				this.$parent.fatherMethod();
				this.getdata()
			},
			edit() {
				this.dialogVisible5 = true
			},
			fatherMethod() {
				this.slected = false
				this.getdata()
			},
			getdata() {
				myGoodsDetail({
					'id': this.goodId
				}).then(res => {
					if (res.code == 200) {
						this.goodinfo = res.data
					}
				})
				goodsOrderAftersales({
					'id': this.goodId
				}).then(res => {
					if (res.code == 200) {
						this.log = res.data.log
						this.Aftersales = res.data

					}
				})
				//我的地址
				addressList().then(res => {
					if (res.code == 200) {
						this.addlist = res.data.data
					}
				})
			},
			pingjia() {
				this.dialogVisible1 = true
			},
			look() {
				deliveryList({
					'id': this.goodId
				}).then(res => {
					if (res.code == 200) {
						this.traces = res.data.traces
						this.wuliuinfo = res.data
					}
				})
				this.dialogVisible = true


			},
			lishi() {
				this.dialogVisible2 = true
			},
			handleClick(index) {
				for (let var1 in this.titles) {
					this.titles[var1].ishow = false
				}
				this.titles[index].ishow = true

				switch (index) {}
			},
			btnclick(index) {
				console.log(index, "index");
				for (let var1 in this.addlist) {
					this.addlist[var1].ishow = false
				}

				this.addlist[index].ishow = true

				this.slectedinfo = this.addlist[index];

			},
			slectedAdd() {
				console.log(111111111)
				// this.formInline = this.slectedinfo
				// this.formInline.citys = this.slectedinfo.area.split('-');
				this.dialogVisible5 = false

				changeAddress({
					'order_id': this.goodId,
					'address_id': this.slectedinfo.id
				}).then(res => {
					if (res.code == 200) {
						ElMessage({
							message: '修改成功',
							type: 'success',
						})
						this.getdata()
					} else {
						ElMessage({
							message: res.message,
							type: 'error',
						})
					}
				})
			},
		},
	}
</script>
<style lang='scss'>
	.btnConfirm {
		background: #FF6A00 !important;
		color: #fff !important;
		border: 1px solid #FF6A00 !important;
	}

	.btnCancel {
		background: #009BF4 !important;
		color: #FFFFFF !important;
	}

	.el-button:first-child:hover {
		color: #333 !important;
		background-color: #fff !important;
		border-color: #999 !important;
	}

	.el-button:nth-child(2):hover {
		color: #FFFFFF !important;
		border-color: #FF6A00 !important;
		background-color: #FF6A00 !important;
	}
</style>
<style scoped lang="scss">
	.ordertype {
		margin-top: 20px;
		border: 1px solid #eee;
		padding: 20px;
		// width: 960px;
		// height: 315px;
	}

	.btn {
		width: 112px;
		height: 42px;
		border-radius: 26px;
		border: 1px solid #FF6A00;
		color: #FF6A00;
		text-align: center;
		font-size: 16px;
		line-height: 42px;
		margin-right: 20px;
	}

	.btn1 {
		width: 112px;
		height: 42px;
		font-size: 16px;
		border-radius: 26px;
		color: #fff;
		background-color: #FF6A00;
		text-align: center;
		line-height: 42px;
		margin-right: 20px;
	}

	.top {
		display: flex;
		justify-items: center;
		align-items: center;

		.typename {
			color: #FF6A00;
			font-size: 24px;
			font-weight: bold;
		}

		.icon {
			width: 40px;
			height: 40px;
			margin-right: 10px;
			border-radius: 10px;
		}
	}

	.con {
		margin-top: 20px;
		font-size: 16px;
		color: #333333;
	}

	.hotellist {
		margin-top: 40px;
		// padding: 30rpx;
		background-color: #fff;
		font-size: 24px;
		// font-family: PingFang SC, PingFang SC;
		font-weight: 400;
		color: #333333;
		// line-height: 32rpx;
		// font-weight: bold;

		.hotelitem {
			display: flex;
			margin-top: 20px;
			// width: 690rpx;

			.right {
				margin-left: 30rpx;
				width: 500rpx;

				.imgs {
					display: flex;
					margin-bottom: 30rpx;

					.imgsitem {
						display: flex;
						flex-direction: column;
						justify-items: center;
						align-items: center;
						font-size: 28rpx;
						margin-right: 40px;
					}
				}

				.hotelbtm {
					display: flex;
					justify-content: flex-end;
					align-items: center;
					font-size: 32rpx;
					font-family: PingFang SC, PingFang SC;
					font-weight: 400;
					color: #FF6A00;
				}
			}

		}
	}

	.ruzhu {
		margin-top: 40px;
		// padding: 30rpx;
		background-color: #fff;
		font-size: 24px;
		// font-family: PingFang SC, PingFang SC;
		font-weight: 400;
		color: #333333;
		// font-weight: bold;
	}

	.timeicon {
		width: 40px;
		height: 48px;
	}

	.info {
		margin-top: 40px;
		margin-bottom: 20px;
		color: #333333;
		font-size: 24px;
	}

	.info1 {
		// margin-top: 40px;
		margin-bottom: 20px;
		color: #333333;
		font-size: 24px;
	}

	.info2 {
		// margin-top: 40px;
		margin-bottom: 20px;
		color: #333333;
		font-size: 20px;
	}

	.info3 {
		margin-bottom: 20px;
		margin-top: 20px;
		color: #333333;
		font-size: 20px;
	}

	.infoicon {
		width: 20px;
		height: 20px;
		margin-right: 10px;
	}

	.roomitem {
		width: 430px;
		// height: 160px;
		padding: 20px;
		padding-left: 0;
		// border: 1px solid #eeeeee;
		margin-right: 20px;

		.roomname {
			color: #333333;
			font-size: 20px;
			margin-bottom: 20px;
			font-weight: bold;
		}

		.roomdesc {
			margin-top: 20px;
			display: flex;
			align-items: center;

			.roomdesctitle {
				color: #666666;
				font-size: 16px;
				width: 84px;
				margin-right: 20px;

			}

			.roomdesccon {
				color: #333333;
				font-size: 16px;
			}
		}
	}

	.jifen {
		width: 20px;
		height: 20px;
		margin-right: 5px;
	}

	.goodlist {
		.good {
			display: flex;
			padding: 20px;
			margin-top: 20px;
			border: 1px solid #EEEEEE;
			justify-content: space-between;

			.goodname {
				font-size: 20px;
				color: #333333;
			}

			.goodguige {
				font-size: 16px;
				color: #333333;
			}

			.goodgnumber {
				font-size: 16px;
				color: #666;
			}

			.goodtype {

				color: #FF6A00;
				font-size: 16px;
			}
		}
	}

	.adresitem {
		width: 360px;
		padding: 20px;
		height: 80px;
		border: 1px solid #EEEEEE;
	}

	.wuliuicon {
		width: 40px;
		height: 40px;
		margin-right: 10px;
	}

	.list {
		margin-top: 20px;

		.jiedian {
			display: flex;
			align-items: center;

			// justify-content: center;
		}

		.neirong {
			padding-bottom: 40px;
			padding-left: 30px;
			border-left: 1px dashed #FF6A00;
			margin-left: 20px;
		}
	}

	.pingjiatitle {
		margin-top: 20px;
		color: #333333;
		font-size: 16px;
		margin-left: 20px;
		margin-bottom: 10px;
	}

	.pingjiaIcon {
		width: 120px;
		height: 120px;
		margin-right: 10px;
		border-radius: 10px;
	}

	.peoplelist {
		.people {
			display: flex;

			.peoplename {
				font-size: 20px;
				color: #333333;
			}

			.peopletime {
				margin-top: 10px;
				color: #666666;
				font-size: 16px;
			}

			.peopleicon {
				width: 60px;
				height: 60px;
				margin-right: 20px;

			}

			.peoplebiaoti {}
		}

		.neirong {
			padding-bottom: 40px;
			padding-left: 50px;
			border-left: 1px dashed #FF6A00;
			margin-left: 30px;
		}
	}

	.ExitConfirmButton {
		background: #FF6A00 !important;
		color: #fff !important;
	}

	.video {
		width: 120px;
		height: 120px;
	}

	.addlist {
		height: 484px;
		overflow-y: auto;
	}

	.user_for {
		display: flex;
		height: 106px;
		margin-bottom: 20px;
		width: 400px;
		border: 1px solid #EEEEEE;
		background-color: #fff;
		text-align: center;
		align-items: center;
	}


	.right_box {
		margin: 0rpx 20rpx 20rpx 20rpx;
		background-color: #fff;

		.title {
			font-size: 16px;
			color: #333333;
		}

		.desc {
			font-size: 16px;
			color: #333333;
			display: flex;
			margin-top: 20px;
		}
	}

	.icon {
		width: 20px;
		height: 20px;
		margin-right: 20px;
		margin-left: 20px
	}

	::v-deep .el-upload--picture-card {
		background-color: #fff !important;
		border: 0px !important;
	}

	::v-deep .el-upload-list--picture-card .el-upload-list__item-actions span+span {
		margin-left: 4px !important;
	}
</style>