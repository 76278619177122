<template>
	<div style="background-color: #fff;padding: 20px;
				background: #fff;
				width: 1000px;">
		<div v-if="slected==0">


			<div style="color: #333333;font-size: 24px;padding-left: 10px;border-left: 4px solid #FF6A00;">
				调查问卷
			</div>
			<!-- <el-button round
				style=" background-color:#FF6A00;margin-top: 20px;margin-bottom: 40px;color: #fff;">创建活动</el-button> -->
			<div>
				<div class="list">
					<div class="listitem" v-for="item in list">
						<div class="desc" style="margin-top: 0;">
							所属活动：{{item.activity.name}}
						</div>
						<div class="desc">
							问卷名称：{{item.name}}
						</div>
						<!-- status -->
						<div class="desc">
							审核状态：
							<span class="circle"></span>
							<span v-show="item.is_submit==2">待开始</span>
							<span v-show="item.is_submit==1">已答题</span>
						</div>
						<el-divider></el-divider>
						<div style="display: flex;justify-content: space-between;">
							<div>
							</div>
							<div v-show="item.is_submit==2" class="btn" @click="itemclick(item)">
								答题
							</div>
							<div v-show="item.is_submit==1" class="btn" @click="look(item)">
								查看结果
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
		
		<el-dialog v-model="dialogVisible1" title="答题介绍" width="800" :before-close="handleClose">
			<div style="display: flex;align-items: center;flex-direction: column;">
				<div v-html="quesinfo.profile">
					
				</div>
				<!-- <div> -->
		
				<!-- </div> -->
		
			</div>
		
			<template #footer>
				<div class="dialog-footer" style="display: flex;justify-content: space-between;">
				<div></div>
					<div style="display: flex;">
						<div class="btn" @click="dialogVisible1 = false">取消</div>
						<div class="btn1" @click="startclick">
							开始答题
						</div>
					</div>
		
				</div>
			</template>
		</el-dialog>
		<myquestionnaireDesc  :questionnaireId="quesinfo.id" :questionnairename="quesinfo.name" v-if="slected==1"></myquestionnaireDesc>
		<myquestionnaireDescLook  :questionnaireId="quesinfo.id" :questionnairename="quesinfo.name" v-if="slected==2"></myquestionnaireDescLook>
		
	</div>
</template>


<script>
	import {
		questionnaire,
	} from "@/utils/Myapi"
	import img1 from "@/assets/my/t1.png";
	import img2 from "@/assets/my/t2.png";
	import img3 from "@/assets/my/t3.png";
	import img4 from "@/assets/my/t4.png";
	import img5 from "@/assets/my/t5.png";
	import img6 from "@/assets/my/t6.png";
	import img7 from "@/assets/my/t7.png";
	import myquestionnaireDesc from '@/components/myquestionnaireDesc'
	import myquestionnaireDescLook from '@/components/myquestionnaireDescLook'
	
	export default {
		// 组件名
		name: 'heAd',
		// 注册组件
		components: {
			myquestionnaireDesc,
			myquestionnaireDescLook
		},
		// 接收父组件数据
		props: {},
		// 组件数据
		data() {
			return {
				islook:false,
				slected: 0,
				activeName: '',
				FullShow: true,
				dialogVisible: false,
				status: '',
				lang: '',
				input3: "",
				list: [],
				quesinfo:{},
				dialogVisible1:false,
				titles: [],
			}
		},
		// 计算属性login
		computed: {},
		// 监听属性
		watch: {},
		// 生命周期钩子函数
		created() {


		},
		mounted() {
			this.getdata()
		},
		// 组件方法
		methods: {
			fatherMethod() {
				this.slected = 0
				this.getdata()
				console.log('父组件方法');
			},
			startclick(){
				this.dialogVisible1 = false;
				this.slected=1;
			},
			itemclick(item){
				this.quesinfo = item;
				this.dialogVisible1 = true
			},
			getdata() {
				//活动详情
				questionnaire().then(res => {
					if (res.code == 200) {
						this.list = res.data.data
					}
				})
			},
			look(item){
				this.quesinfo = item;
				this.slected=2;
			},
			handleClick(index) {
				for (let var1 in this.titles) {
					this.titles[var1].ishow = false
				}
				this.titles[index].ishow = true

				switch (index) {}
			},
		},
	}
</script>

<style scoped lang="scss">
	.list {
		// margin-top: 0px;
		display: flex;
		flex-wrap: wrap;

		.listitem {
			width: 400px;
			padding: 20px;
			margin-right: 20px;
			margin-top: 20px;
			// height: 290px;

			border: 1px solid #EEEEEE;

			.top {
				display: flex;
				justify-content: space-between;
			}

			.desc {
				margin-top: 20px;
				color: #333333;
				font-size: 16px;
				display: flex;
				align-items: center;

			}
		}
	}

	.btn {
		width: 112px;
		height: 42px;
		border-radius: 26px;
		border: 1px solid #FF6A00;
		color: #FF6A00;
		text-align: center;
		line-height: 42px;
	}

	.grop {
		display: flex;
		flex-wrap: wrap;

		.gropitem {
			width: 240px;
			height: 70px;
			padding: 20px;
			display: flex;

			border-radius: 10px 10px 10px 10px;
			border: 1px solid #EEEEEE;

			.icon {
				width: 70px;
				height: 70px;
				margin-right: 20px;
				margin-right: 20px;
			}

			.number {

				font-size: 24px;
				color: #333333;
			}

			.title {
				margin-top: 12px;
				color: #666666;

				font-size: 16px;
			}
		}
	}

	.circle {
		width: 6px;
		height: 6px;
		border-radius: 3px;
		display: inline-block;
		background: #FF6A00;
		margin-right: 5px;
	}
	.btn1 {
		width: 112px;
		height: 42px;
		border-radius: 26px;
		color: #fff;
		background-color: #FF6A00;
		text-align: center;
		line-height: 42px;
		margin-left: 20px;
	}
</style>