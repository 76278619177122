<template>
	<div class="footer" style="color:white">
		Copyright©202x 北京xxxxxxxxxxxx有限公司版权所有 京ICP备xxxxxxxx号-4
	</div>
</template>

<script>
	export default {
		// 组件名
		name: 'footer',
		// 注册组件
		components: {
			
		},
		// 接收父组件数据
		props: {},
		// 组件数据
		data() {
			return {}
		},
		// 计算属性
		computed: {},
		// 监听属性
		watch: {},
		// 生命周期钩子函数
		created() {},
		mounted() {

		},
		// 组件方法
		methods: {},
	}
</script>

<style scoped lang="scss">
	.footer {
		width: 100%;
		height: 63px;
		background-color: #000000;
		font-family: Source Han Sans, Source Han Sans;
		font-size: 16px;
		text-align: center;
		line-height: 63px;
		color: #fffff;
	}
</style>