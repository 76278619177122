<template>
	<div style="background-color: #fff;padding: 20px;
				background: #fff;">
		<div style="display: flex;justify-content: space-between;align-items: center;">
			<div style="color: #333333;font-size: 24px;padding-left: 10px;border-left: 4px solid #FF6A00;align-items: center;">
				申请发票
			</div>
			<div style="display: flex;">
				<div class="btn" @click="goback">
					返回
				</div>
				<div class="btn1" @click="submit">
					提交
				</div>
			</div>

		</div>
		<div>
			<div class="list">
				<el-form :model="form" class="demo-form-inline" label-width="130">
					<el-form-item label="发票类型：">
						<el-radio-group v-model="form.type1">
							<el-radio :label="1">普通发票</el-radio>
							<el-radio :label="2">增值税专已发票</el-radio>
						</el-radio-group>
					</el-form-item>

					<el-form-item label="发票类型：">
						<el-radio-group v-model="form.type2">
							<el-radio :label="1">邮箱</el-radio>
							<el-radio :label="2">邮寄</el-radio>
						</el-radio-group>
					</el-form-item>

					<el-form-item v-if="form.type2==1" label="邮箱：">
						<el-input v-model="form.email" placeholder="请输入" clearable />
					</el-form-item>

					<div v-if="form.type2==2">
						<!-- <el-form :model="form" class="demo-form-inline" label-width="150"> -->
						<el-form-item label="选择收货地址：">
							<span style="color: #FF6A00;" @click="dialogVisible = true">选择地址</span>
						</el-form-item>
						<el-form-item label="收货人姓名：">
							<el-input class="adw" v-model="form.name" placeholder="请输入" clearable />
						</el-form-item>
						<el-form-item label="收货人手机号：">
							<el-input class="adw" v-model="form.usermobile" placeholder="请输入" clearable />
						</el-form-item>
						<el-form-item label="收货地区：">
							<el-cascader style="width: 420px;" ref="articleOrgRef" placeholder="请选择地址"
								v-model="form.citys" :props='props' :options="mapJson.data" />
						</el-form-item>
						<el-form-item label="详细地址：">
							<el-input class="adw" v-model="form.useraddress" type="textarea" placeholder="请输入"
								clearable />
						</el-form-item>
						<!-- </el-form> -->
					</div>
					<el-form-item label="企业名称：">
						<!-- <el-input v-model="form.name" placeholder="请输入" clearable /> -->
						<el-select filterable v-model="form.inv_id" placeholder="请选择" clearable>
							<el-option @click="choose(item)" v-for="item in list " :label="item.name"
								:value="item.id" />
						</el-select>

					</el-form-item>
					<el-form-item label="税号：">
						<el-input v-model="form.code" placeholder="请输入" clearable />
					</el-form-item>
					<el-form-item label="电话号码：">
						<el-input v-model="form.mobile" placeholder="请输入" clearable />
					</el-form-item>
					<el-form-item label="企业地址：">
						<el-input v-model="form.address" placeholder="请输入" clearable />
					</el-form-item>

					<el-form-item label="开户银行：">
						<el-input v-model="form.bank_name" placeholder="请输入" clearable />
					</el-form-item>

					<el-form-item label="银行账号：">
						<el-input v-model="form.bank_account" placeholder="请输入" clearable />
					</el-form-item>
				</el-form>
			</div>
		</div>
		<el-dialog v-model="dialogVisible" title="选择收货地址" width="510" :before-close="handleClose" append-to-body>
			<div class="addlist">
				<div class="user_for" v-for="(item,index) in addlist" :key="id">
					<div @click.stop="btnclick(index)" class="">
						<img v-show="!item.ishow" class="icon" src="../assets/chooseNO.png" alt="">
						<img v-show="item.ishow" class="icon" src="../assets/choose.png" alt="">
					</div>
					<div class="right_box">
						<div class="title" style="display: flex;margin-top: 20px;align-items: center;">
							<span style="color: #333333;">{{item.name}} {{item.mobile}}
							</span>
							<div v-show="item.is_default==2" class="moren">
								默认
							</div>
						</div>
						<div class="desc" style="margin-top: 20px;">
							{{item.area}}{{item.address}}
						</div>
					</div>
				</div>
			</div>
			<template #footer>
				<div class="dialog-footer" style="display: flex;justify-content: space-between;">
					<div></div>
					<div style="display: flex;">
						<div class="btn" @click="dialogVisible = false">取消</div>
						<div class="btn1" @click="slectedAdd()">
							保存
						</div>
					</div>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		addressList,
		addressAddEdit,
		addressDel,
		invoiceDetailLoce,
		invoiceList
	} from "@/utils/Myapi.js"

	import {
		ElMessage
	} from 'element-plus'
	import {
		meetingApplyInvoice
	} from "@/utils/api.js"

	import mapdata from '@/utils/city.json'

	export default {
		// 组件名
		name: 'heAd',
		// 注册组件
		components: {},
		// 接收父组件数据
		props: {
			orderid: String
		},
		// 组件数据
		data() {
			return {
				form: {
					type: 1
				},
				dialogVisible: false,
				radio: '',
				props: {
					label: "name",
					value: "name"
				},
				mapJson: mapdata,
				slected: '',
				options: [],
				activeName: '',
				FullShow: true,
				dialogVisible: false,
				status: '',
				lang: '',
				input3: "",
				titles: [],
				addlist: [],
				list: []
			}
		},
		// 计算属性login
		computed: {},
		// 监听属性
		watch: {},
		// 生命周期钩子函数
		created() {


		},
		mounted() {
			this.getdata()
		},
		// 组件方法
		methods: {
			submit() {
				this.form.order_id = this.orderid;
				meetingApplyInvoice(this.form).then(res => {
					if (res.code == 200) {
						ElMessage({
							message: '提交成功',
							type: 'success',
						})
						this.goback()
					}else{
						ElMessage({
							message: res.message,
							type: 'error',
						})
					}
				})
			},
			getdata() {

				invoiceList().then(res => {
					if (res.code == 200) {
						this.list = res.data.data
					}
				})

				//我的地址
				addressList().then(res => {
					if (res.code == 200) {
						this.addlist = res.data.data
					}
				})
			},
			choose(item) {
				console.log('222', item);
				Object.assign(this.form, item)
				this.form.inv_id = item.id

			},
			goback() {
				console.log('zi组件方法');
				this.$parent.fatherMethod();
			},
			btnclick(index) {
				console.log(index, "index");
				for (let var1 in this.addlist) {
					this.addlist[var1].ishow = false
				}

				this.addlist[index].ishow = true

				this.slectedinfo = this.addlist[index];

			},
			slectedAdd() {

				// this.form = this.slectedinfo

				// this.form.address = this.slectedinfo
				// Object.assign(this.form, this.slectedinfo)

				this.form.name = this.slectedinfo.name
				this.form.user_address_id = this.slectedinfo.id
				this.form.usermobile = this.slectedinfo.mobile

				this.form.useraddress = this.slectedinfo.address


				this.form.citys = this.slectedinfo.area.split('-');

				this.dialogVisible = false

			},
			handleClick(index) {
				for (let var1 in this.titles) {
					this.titles[var1].ishow = false
				}
				this.titles[index].ishow = true

				switch (index) {}
			},
		},
	}
</script>

<style scoped lang="scss">
	.list {
		margin-top: 40px;
		width: 450px;
	}

	.btn {
		width: 112px;
		height: 42px;
		border-radius: 26px;
		border: 1px solid #FF6A00;
		color: #FF6A00;
		text-align: center;
		line-height: 42px;
		margin-right: 20px;
	}

	.btn1 {
		width: 112px;
		height: 42px;
		border-radius: 26px;
		color: #fff;
		background-color: #FF6A00;
		text-align: center;
		line-height: 42px;
		margin-right: 20px;
	}

	.demo-form-inline .el-input {
		--el-input-width: 320px;
	}

	.demo-form-inline .el-select {
		--el-select-width: 320px;
	}

	.addlist {
		height: 484px;
		overflow-y: auto;
	}

	.user_for {
		display: flex;
		height: 106px;
		margin-bottom: 20px;
		width: 400px;
		border: 1px solid #EEEEEE;
		background-color: #fff;
		text-align: center;
		align-items: center;
	}


	.right_box {
		margin: 0rpx 20rpx 20rpx 20rpx;
		background-color: #fff;

		.title {
			font-size: 16px;
			color: #333333;
		}

		.desc {
			font-size: 16px;
			color: #333333;
			display: flex;
			margin-top: 20px;
		}
	}


	.icon {
		width: 20px;
		height: 20px;
		padding-left: 20px;
		margin-right: 20px;
	}

	.addlist {
		height: 484px;
		overflow-y: auto;
	}

	.user_for {
		display: flex;
		height: 106px;
		margin-bottom: 20px;
		width: 400px;
		border: 1px solid #EEEEEE;
		background-color: #fff;
		text-align: center;
		align-items: center;
	}


	.right_box {
		margin: 0rpx 20rpx 20rpx 20rpx;
		background-color: #fff;

		.title {
			font-size: 16px;
			color: #333333;
		}

		.desc {
			font-size: 16px;
			color: #333333;
			display: flex;
			margin-top: 20px;
		}
	}

	/* 选中后的字体颜色 */
	::v-deep .el-radio__input.is-checked+.el-radio__label {
		color: #FF6A00 !important;
	}

	/* 选中后小圆点的颜色 */
	::v-deep .el-radio__input.is-checked .el-radio__inner {
		background: #FF6A00 !important;
		border-color: #FF6A00 !important;
	}
</style>