<template>
	<div style="background-color: #fff;padding: 20px;
				background: #fff;
				width: 1000px;">
		<div style="color: #333333;font-size: 24px;padding-left: 10px;border-left: 4px solid #FF6A00;">
			联系我们
		</div>
		<div>{{seting.value}}</div>
		
		
		
	</div>
</template>

<script>
	import {
		config
	} from "@/utils/api"
	import {
		ArrowRight
	} from '@element-plus/icons-vue'

	import heads from '@/components/head'
	export default {
		// 组件名
		name: 'hoMe',
		// 注册组件
		components: {
			heads
		},
		// 接收父组件数据
		props: {},
		// 组件数据
		data() {
			return {
				info: [],
				seting:{},
				Guest: {
					avatar: ''
				}
			}
		},
		// 计算属性
		computed: {},
		// 监听属性
		watch: {},
		// 生命周期钩子函数
		created() {},
		mounted() {
			this.getdata()



		},
		// 组件方法
		methods: {
			getdata() {

				config().then(res => {
					if (res.code == 200) {
						this.info = res.data.data
						for(var item of this.info){
							if(item.key=='contact'){
								this.seting = item;
							}
						}
						
					}
				})
			},
			btnclick() {

			}
		},
	}
</script>

<style scoped lang='scss'>
	.box {

		background-color: #F7F7F7;
		/* height: 100vh; */
		padding-bottom: 30px;

		.center {
			width: 1000px;
			margin-left: auto;
			margin-right: auto;
			padding-bottom: 100px;
			background-color: #fff;

			.info {
				display: flex;
				padding-top: 20px;
				/* flex-direction: column; */
				/* align-items: center; */
				width: 300px;
				height: 180px;
				margin-left: auto;
				margin-right: auto;

				.img {
					width: 135px;
					height: 180px;
					margin-right: 20px;
				}

				.desc {
					color: #333333;
					font-size: 16px;
					margin-bottom: 20px;
				}

			}

			.title {
				margin-left: 150px;
				color: #333333;
				font-size: 24px;
				padding-left: 10px;
				border-left: 4px solid #FF6A00;
			}

			.contetn {
				padding-top: 20px;
				padding-left: 150px;
				padding-right: 150px;
			}
		}
	}

	.banner {
		width: 100%;
		height: 300px;
		margin-left: auto;
		margin-right: auto;
	}

	.name {
		color: #333333;
		font-size: 20px;
		text-align: center;
		margin-top: 20px;
		margin-bottom: 10px;
	}

	.time {
		font-size: 16px;
		color: #666666;
		text-align: center;
		margin-bottom: 20px;
	}

	.contetn {
		border-top: 1px solid #eeeeee;
	}

	.breadcrumb {
		height: 20px;
		padding-top: 10px;
		padding-bottom: 10px;
		background-color: #F7F7F7;
	}
</style>