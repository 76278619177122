<template>
	<div style="background-color: #fff;padding: 20px;">
		<div style="display: flex;justify-content: space-between;">
			<div style="color: #333333;font-size: 24px;padding-left: 10px;border-left: 4px solid #FF6A00;line-height: 45px;">
				签到信息
			</div>
			<div style="display: flex;">
				<div class="btn" @click="goback">
					返回
				</div>
			</div>
		
		</div>

		<div>
			<div class="list">
				<div class="listitem" style="display: flex;align-items: center;justify-content: space-between;">
					<div>
						<div class="top">
							活动名称：{{meetingOrderChild.activity_name}}
						</div>
						<div class="desc">
							会议名称：{{meetingOrderChild.meeting_name}}
						</div>
						<div class="desc">
							签到时间：{{info.create_time}}
						</div>
						<div class="desc">
							签到方式：{{info.sign_type_str}}
						</div>
					</div>
					<div style="display: flex;align-items: center;justify-items: center;">
						<div class="jifen">
							+{{info.point}}
						</div>
						<img class="icon" src="../../src/assets/my/jifen.png" alt="" />
					</div>

				</div>

			</div>
		</div>

		<div class="cent">
			<div class="left">
				<div style="color: #333333;font-size: 24px;padding-left: 10px;border-left: 4px solid #FF6A00;">
					会议信息
				</div>
				<div class="huiyilist">
					<div class="huiyi">
						<img class="img" :src="meeting.cover_str" alt="">
						<!-- <img class="typeicon" src="../assets/my/mettingM.png" alt="" /> -->
						<img v-show="meeting.type==1" class="typeicon"
							src="../assets/my/zhu.png" mode="">
						</img>
						<img v-show="meeting.type==2" class="typeicon"
							src="../assets/my/fen.png" mode="">
						</img>
						<img v-show="meeting.is_collect==1" class="SCollection" src="../assets/my/SCollection.png" alt="" />
						<div class="huiyiname">{{meeting.name}}</div>
						<div class="huiyitime">{{meeting.start_time_str}}</div>
						<div style="display: flex;align-items: center;color: #FF6A00;">
							<div class="huiyiprice">
								<span style="font-size: 14px;">￥</span>
								{{meetingOrderChild.price}}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="right">
				<div style="color: #333333;font-size: 24px;padding-left: 10px;border-left: 4px solid #FF6A00;">
					签到图片
				</div>
				<div class="imgs">
					<img v-for="item in info.sign_img" class="img" :src="item" alt="" ></img>
				</div>
			</div>

		</div>



	</div>
</template>

<script>
	import {
		signLogDetail
	} from "@/utils/Myapi.js"
	export default {
		// 组件名
		name: 'heAd',
		// 注册组件
		components: {},
		// 接收父组件数据
		props: {
			signLogId: String
		},
		// 组件数据
		data() {
			return {
				activeName: '',
				FullShow: true,
				dialogVisible: false,
				status: '',
				lang: '',
				input3: "",
				titles: [],
				info:{},
				meetingOrderChild:{},
				meeting:{}
			}
		},
		// 计算属性login
		computed: {},
		// 监听属性
		watch: {},
		// 生命周期钩子函数
		created() {


		},
		mounted() {
			this.getdata()
		},
		// 组件方法
		methods: {
			goback() {
				console.log('zi组件方法');
				this.$parent.fatherMethod();
			},
			getdata() {
				signLogDetail({
					"id": this.signLogId
				}).then(res => {
					if (res.code == 200) {
						this.info = res.data
						this.meetingOrderChild = res.data.meetingOrderChild
						this.meeting =  res.data.meetingOrderChild.meeting
					}
				})
			},
			handleClick(index) {
				for (let var1 in this.titles) {
					this.titles[var1].ishow = false
				}
				this.titles[index].ishow = true

				switch (index) {}
			},
		},
	}
</script>

<style scoped lang="scss">
	.list {
		margin-top: 20px;

		.listitem {
			// width: 920px;
			padding: 20px;
			// height: 290px;

			border: 1px solid #EEEEEE;

			.top {
				display: flex;
				justify-content: space-between;
			}

			.desc {
				margin-top: 20px;
				color: #333333;
				font-size: 16px;
			}

			.jifen {
				font-size: 24px;
				color: #FF6A00;
			}

			.icon {
				width: 30px;
				height: 30px;
				margin-left: 10px;
			}
		}
	}

	.btn {
		width: 112px;
		height: 42px;
		border-radius: 26px;
		border: 1px solid #FF6A00;
		color: #FF6A00;
		text-align: center;
		line-height: 42px;
	}

	.cent {
		display: flex;

		// justify-content: space-between;
		.left {
			margin-top: 40px;

			// margin-bottom: 20px;
			.huiyilist {
				display: flex;
				margin-top: 20px;

				.huiyi {
					width: 300px;
					height: 320px;

					.img {
						width: 100%;
						height: 200px;
					}

					.huiyiname {
						font-size: 20px;
						color: #333333;
					}

					.huiyitime {
						font-size: 16px;
						margin-top: 10px;
						margin-bottom: 10px;
						color: #666666;
					}

					.huiyiprice {
						color: #FF6A00;
						font-size: 24px;
					}

					.typeicon {
						width: 50px;
						height: 28px;
						position: relative;
						top: -205px;
						left: -5px;
					}

					.SCollection {
						width: 20px;
						height: 20px;
						position: relative;
						top: -205px;
						right: -220px;
					}
				}
			}
		}

		.right {
			// display: flex;
			margin-top: 40px;
			margin-left: 200px;

			.imgs {
				margin-top: 20px;
				display: flex;
				flex-wrap: wrap;

				.img {
					width: 120px;
					height: 120px;
					margin-right: 20px;
				}
			}
		}

	}
</style>