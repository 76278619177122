<template>
	<div style="background-color: #fff;padding: 20px;">
		<!-- <div>111</div> -->
		<div v-if="isReload" class="mainshow"
			style="position: relative;z-index: 99; display: flex;align-items: center;margin-left: auto;margin-right: auto;width: 400px;top:90px">
			<LuckyWheel ref="myLucky" width="300px" height="300px" :prizes="prizes" :blocks="blocks" :buttons="buttons"
				@start="startCallback" @end="endCallback" />
		</div>
		<div class="shuoming">
			<div class="shuomingtop">
				<span>—</span>
				<span>活动规则</span>
				<span>—</span>
			</div>
			<div class="content">
				{{info.rule}}
			</div>
		</div>
		<img class="confcon" src="../../src/assets/my/lockOne.png" alt="" />

		<el-dialog v-model="dialogVisible" title="" width="590" :before-close="handleClose">
			<div class="showmain">
				<div class="jiangping">恭喜！抽中了{{slectedinfo.name}}</div>
				<img v-if="slectedinfo.type==2" class="imgs" :src="slectedinfo.goods.cover_img" alt="" />
				<img v-if="slectedinfo.type==3" class="imgs" :src="Prizes" alt="" />
				<img v-if="slectedinfo.type==1" class="imgs" src="../assets/my/jifen.png" alt="" />
				<div style="margin-top: 20px;">积分已自动放入积分账户</div>
			</div>
			<template #footer>
				<div class="dialog-footer" style="display: flex;justify-content: center;">
					<div style="display: flex;">
						<div class="btn1" @click="golook">
							去看看
						</div>
					</div>
				</div>
			</template>
		</el-dialog>

	</div>
</template>


<script>
	import {
		drawPrize
	} from "@/utils/Myapi.js"
	import {
		ElMessage,
		ElMessageBox
	} from 'element-plus'
	import img1 from "@/assets/my/t1.png";
	import img2 from "@/assets/my/t2.png";
	import img3 from "@/assets/my/t3.png";
	import img4 from "@/assets/my/t4.png";
	import img5 from "@/assets/my/t5.png";
	import img6 from "@/assets/my/t6.png";
	import img7 from "@/assets/my/t7.png";
	import lockbg from "@/assets/my/lockBg.png";
	import lockbtn from "@/assets/my/lockbtn.png";
	import Prizes from "@/assets/my/Prizes.png";
	import jifen from "@/assets/my/jifen.png";

	export default {
		// 组件名
		name: 'heAd',
		// 注册组件
		components: {},
		// 接收父组件数据
		props: {
			info: Object
		},
		// 组件数据
		data() {
			return {
				isReload: false,
				activeName: '',
				Prizes: Prizes,
				jifen: jifen,
				FullShow: true,
				prize: {},
				dialogVisible: false,
				status: '',
				lang: '',
				input3: "",
				slectedinfo: {},
				titles: [],
				detail: [],
				blocks: [{
					padding: '10px',
					background: '#869cfa',
					imgs: [{
						src: lockbg,
						width: '100%',
						height: '100%'
					}]
				}],
				prizes: [],
				buttons: [
					// {
					// 	radius: '40%',
					// 	background: '#617df2'
					// },
					// {
					// 	radius: '35%',
					// 	background: '#afc8ff'
					// },
					{
						radius: '30%',
						// background: '#869cfa',	
						pointer: true,
						// fonts: [{
						// 	text: '开始',
						// 	top: '-10px'
						// }],
						imgs: [{
							src: lockbtn,
							top: '-50px',
							width: '100%',
							height: '100%'
						}]
					},

				]
			}
		},
		// 计算属性login
		computed: {},
		// 监听属性
		watch: {},
		// 生命周期钩子函数
		created() {


		},
		mounted() {

			this.$nextTick(() => {
				setTimeout(() => {
					this.getdata()
				}, 1000);
			})
		},
		// 组件方法
		methods: {

			golook() {
				this.dialogVisible = false
				this.openUrl = 'http://exhibition-pc.huijik.com/#/my?from=9'
				window.open(this.openUrl);
			},

			getdata() {
				for (var index in this.info.detail) {

					var item = this.info.detail[index]

					if (index % 2 == 1) {
						item.background = '#FFEAB1'
					} else {
						item.background = '#FFFFFF'
					}

					if (item.type == 1) {
						var arry = []
						item.imgs = [{
							src: jifen,
							width: '40%',
							height: '40%',
							top: '10px',
						}]
					}
					if (item.type == 2) {
						// item.src = 
						item.imgs = [{
							src: item.goods.banner_ids[0].address,
							width: '40%',
							height: '40%',
							top: '10px'
						}]
					}
					if (item.type == 3) {
						item.src = Prizes
						item.imgs = [{
							src: Prizes,
							width: '40%',
							height: '40%',
							top: '10px'
						}]

					}
					if (item.level) {
						item.fonts = [{
							'text': item.level,
							top: '60px'
						}]
					} else {
						item.fonts = [{
							'text': '谢谢惠顾',
							top: '60px'
						}]
					}


					this.prizes.push(item)
					this.isReload = true
				}
			},
			// 点击抽奖按钮会触发star回调
			startCallback() {
				// 调用抽奖组件的play方法开始游戏



				drawPrize({
					'draw_id': this.info.id
				}).then(res => {
					if (res.code == 200) {
						this.$refs.myLucky.play()
						this.slectedinfo = res.data
						for (var item in this.info.detail) {
							var data = this.info.detail[item]
							if (this.slectedinfo.draw_prize_id == data.id) {
								this.$refs.myLucky.stop(item)
							}
						}
					} else {
						ElMessage({
							message: res.message,
							type: 'error',
						})
					}
				})



				// // 模拟调用接口异步抽奖
				// setTimeout(() => {
				// 	// 假设后端返回的中奖索引是0
				// 	const index = 0
				// 	// 调用stop停止旋转并传递中奖索引
				// 	this.$refs.myLucky.stop(index)
				// }, 3000)
			},
			// 抽奖结束会触发end回调
			endCallback(prize) {

				console.log(prize)

				this.prize = prize

				this.dialogVisible = true

			},
			handleClick(index) {
				for (let var1 in this.titles) {
					this.titles[var1].ishow = false
				}
				this.titles[index].ishow = true

				switch (index) {}
			},
		},
	}
</script>

<style scoped lang="scss">
	.btn {
		width: 112px;
		height: 42px;
		border-radius: 26px;
		border: 1px solid #FF6A00;
		color: #FF6A00;
		text-align: center;
		line-height: 42px;
	}

	.confcon {
		// top: 0px;
		position: relative;
		width: 100%;
		height: 100%;
		bottom: 500px;
	}

	.grop {}

	.shuoming {
		z-index: 99;
		position: relative;
		color: #fff;
		margin-top: 100px;
		background-color: #EB4A49;
		margin: 30px;
		top: 100px;

		// margin-top: 30rpx;
		.shuomingtop {
			font-size: 28px;
			padding-top: 30px;
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
		}

		.content {
			font-size: 20px;
			font-family: PingFang SC, PingFang SC;
			font-weight: 400;
			color: #FFFFFF;
			padding: 30px;
			line-height: 42px;
		}
	}

	.imgs {
		width: 200px;
		height: 200px;
	}

	.btn1 {
		width: 92px;
		height: 42px;
		border-radius: 26px;
		color: #fff;
		background-color: #FF6A00;
		text-align: center;
		line-height: 42px;
		margin-right: 20px;
	}

	.showmain {

		display: flex;
		flex-direction: column;
		align-items: center;

		.jiangping {
			font-size: 40px;
			color: #FFE273;

		}
	}
</style>