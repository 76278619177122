<template>
	<div style="background-color: #fff;padding: 20px;">
		<div style="color: #333333;font-size: 24px;padding-left: 10px;border-left: 4px solid #FF6A00;">
			调查问卷
		</div>
		<div>
			<div class="name">
				{{questionnairename}}
			</div>
			<div class="list">
				<div v-for="(item,index) in list" :key="index">
					<div class="item" v-show="item.type==1">
						<div>
							{{index+1}}、{{item.name}}
						</div>
						<div>
							<el-radio-group v-model="item.answerId">
								<el-radio v-for="data in item.answer" :label="data.id">{{data.name}}</el-radio>
							</el-radio-group>
						</div>
					</div>
					<div class="item" v-show="item.type==2">
						<div>
							{{index+1}}、{{item.name}}
						</div>

						<div>
							<el-checkbox-group v-model="item.answerId">
								<el-checkbox v-for="data in item.answer" :label="data.id">{{data.name}}</el-checkbox>
							</el-checkbox-group>
						</div>

					</div>
					<div class="item" v-show="item.type==3">
						<div>
							{{index+1}}、{{item.name}}
						</div>
						<div>
							<el-radio-group v-model="item.answerId">
								<el-radio v-for="data in item.answer" :label="data.id">{{data.name}}</el-radio>
							</el-radio-group>
						</div>

					</div>
				</div>
			</div>
			<div style="display: flex;justify-content: center;">
				<div class="btn" @click="goback">
					返回
				</div>
				<div class="btn1" @click="dialogVisible = true">
					提交
				</div>
			</div>
		</div>


		<el-dialog v-model="dialogVisible" title="答题完成" width="400" :before-close="handleClose">
			<div style="display: flex;align-items: center;flex-direction: column;">
				<div>本次作答结束啦~</div>
				<!-- <div> -->
				<img class="erweima" src="../assets/my/finish.png" alt="" />
				<!-- </div> -->

			</div>

			<template #footer>
				<div class="dialog-footer" style="display: flex;justify-content: center;">

					<div style="display: flex;">
						<div class="btn" @click="dialogVisible = false">取消</div>
						<div class="btn1" @click="submit">
							保存
						</div>
					</div>

				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		questionnaireDetail,
		submitQuestionnaire
	} from "@/utils/Myapi"
	import {
		ElMessage
	} from 'element-plus'
	export default {
		// 组件名
		name: 'heAd',
		// 注册组件
		components: {},
		// 接收父组件数据
		props: {
			questionnaireId: String,
			questionnairename: String
		},
		// 组件数据
		data() {
			return {
				dialogVisible1: true,
				checkList: [],
				radio: '',
				activeName: '',
				FullShow: true,
				dialogVisible: false,
				list: [],
				status: '',
				lang: '',
				input3: "",
				titles: [],
			}
		},
		// 计算属性login
		computed: {},
		// 监听属性
		watch: {},
		// 生命周期钩子函数
		created() {},
		mounted() {
			this.getdata()
		},
		// 组件方法
		methods: {
			getdata() {
				//活动详情
				questionnaireDetail({
					'id': this.questionnaireId
				}).then(res => {
					if (res.code == 200) {
						this.list = res.data
					} else {
						ElMessage({
							message: res.message,
							type: 'error',
						})
					}

				})
			},

			submit() {

				console.log("list ======", this.list)
				var arry = []
				for (var item of this.list) {
					var data = {}
					data.question_id = item.id
					data.answer_id = item.answerId;
					arry.push(data)
				}
				var form = {
					"questionnaire_id": this.questionnaireId,
					"question": arry
				}
				submitQuestionnaire(form).then(res => {
					if (res.code == 200) {
						ElMessage({
							message: '提交成功',
							type: 'success',
						})
						this.dialogVisible = false
						this.goback()
					} else {
						ElMessage({
							message: res.message,
							type: 'error',
						})
					}
				})
			},
			goback() {
				console.log('zi组件方法');
				this.$parent.fatherMethod();
			},
			// handleClick(index) {
			// 	for (let var1 in this.titles) {
			// 		this.titles[var1].ishow = false
			// 	}
			// 	this.titles[index].ishow = true

			// 	switch (index) {}
			// },
		},
	}
</script>
<style scoped lang="scss">
	::v-deep .el-radio {
		display: block;
		margin: 10px 0;
	}

	::v-deep .el-radio-group {
		display: block;
	}

	::v-deep .el-checkbox {
		display: block;
		margin: 10px 0;
	}

	.name {
		margin-top: 20px;
		font-size: 20px;
		color: #333333;
		margin-bottom: 20px;
	}


	.btn {
		width: 112px;
		height: 42px;
		border-radius: 26px;
		border: 1px solid #FF6A00;
		color: #FF6A00;
		text-align: center;
		line-height: 42px;
		margin-right: 20px;
	}

	.btn1 {
		width: 112px;
		height: 42px;
		border-radius: 26px;
		color: #fff;
		background-color: #FF6A00;
		text-align: center;
		line-height: 42px;
		margin-right: 20px;
	}


	.erweima {
		width: 200px;
		margin-top: 20px;
		height: 200px;
	}

	/* 选中后的字体颜色 */
	::v-deep .el-radio__input.is-checked+.el-radio__label {
		color: #FF6A00 !important;
	}

	/* 选中后小圆点的颜色 */
	::v-deep .el-radio__input.is-checked .el-radio__inner {
		background: #FF6A00 !important;
		border-color: #FF6A00 !important;
	}

	//修改选择框的颜色

	::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
	.el-checkbox__input.is-indeterminate .el-checkbox__inner {
		border-color: #FF6A00 !important;
		background-color: #FF6A00 !important;
	}

	::v-deep .el-checkbox__input.is-focus .el-checkbox__inner {
		border-color: #FF6A00 !important;
	}

	//修改选中后文本的颜色
	::v-deep .el-checkbox__input.is-checked+.checkbox__label {
		color: #FF6A00 !important;
	}

	::v-deep .el-checkbox__input.is-checked+.el-checkbox__label {
		color: #FF6A00 !important;
	}
</style>