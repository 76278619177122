<template>
	<div>
		<div class='top'>
			<div class="left">
				<img src="@/assets/homelogo.png" alt="" class="imgs">
			</div>
			<!-- <div>{{showindex}}</div> -->
			<div class="content">
				<div class="tap">
					<div v-for="(item, index) in titles" :key="index">
						<div @click="tabclick(index)" :class="index==showindex ? 'name' : 'name1'">{{ item.name
						}}
						</div>
					</div>
					<img class="topicon" src="../../src/assets/headerhot.png" alt="" />
				</div>
				<div class="right">
					<div style="display: flex;align-items: center;justify-items: center;">
						<img style="width: 20px;height: 20px;margin-right: 10px;" src="../assets/phone.png" alt="" />
						<div>客服电话：{{seting.contacts_phone}}</div>
					</div>
					<div>
						<!-- <img style="width: 20px;height: 20px;margin-left: 20px;" src="../assets/closed.png" alt="" /> -->
						<img v-if="showindex==0" v-show="showSearch" @click="seraclick"
							style="width: 20px;height: 20px;margin-left: 20px;" src="../assets/closed.png" alt="" />
						<img v-if="showindex==0" v-show="!showSearch" @click="seraclick"
							style="width: 20px;height: 20px;margin-left: 20px;" src="../assets/search1.png" alt="" />

					</div>
					<div style="display: flex;align-items: center;justify-items: center;margin-left: 40px;"
						@click="loginOrout">

						<img style="width: 40px;height: 40px;margin-right: 10px;border-radius: 20px;" :src="usericon"
							alt="" />
						<!-- 
						<img v-if="!userinfo" style="width: 40px;height: 40px;margin-right: 10px;border-radius: 20px;"
							src="../assets/touxiang.png" alt="" /> -->
						<div v-if="userinfo">{{userinfo.real_name}}</div>
						<div v-if="!userinfo">用户名</div>
					</div>
				</div>
			</div>
			<!-- <loginview v-if="islogin" ref="login"></loginview> -->
		</div>
		<div class="topserach" v-show="showSearch">
			<div class="searview">
				<el-input style="width: 640px;" v-model="input" placeholder="输入活动名称进行搜索">
				</el-input>
				<div @click="searchclick" class="codebtn">搜索</div>
			</div>
		</div>
	</div>

</template>

<script>
	import {
		config,
		loginOut
	} from "@/utils/api"
	import {
		ElMessage,
		ElMessageBox
	} from 'element-plus'
	import loginview from '@/components/login.vue'
	import {url_config} from "@/utils/baseUrl"
	export default {
		// 组件名
		name: 'heAd',
		// 注册组件
		components: {
			loginview
		},
		// 接收父组件数据
		props: {
			showindex: String,
		},
		// 组件数据
		data() {
			return {
				showSearch: false,
				islogin: false,
				FullShow: true,
				dialogVisible: false,
				usericon: url_config+'/static/api/img/userimg.png',
				status: '',
				lang: '',
				input: "",
				titles: [{
						name: '首页',
						ishow: true
					},
					{
						name: '活动',
						hot: true,
						ishow: false
					},
					{
						name: '商城',

						ishow: false
					},
					{
						name: '我的',

						ishow: false
					},
					{
						name: '会议服务',

						ishow: false
					},

				],
				seting: {},
				LanguageList: [],
				userinfo: {},
			}
		},
		// 计算属性login
		computed: {},
		// 监听属性
		watch: {},
		// 生命周期钩子函数
		created() {

		},
		mounted() {
			window.addEventListener(
				"loginOut",
				this.loginshow
			);
			var info = localStorage.getItem("user");
			console.log('info==', info);
			this.userinfo = JSON.parse(info);
			if (this.userinfo) {
				if (this.userinfo.avater_str.length) {
					this.usericon = this.userinfo.avater_str
				}
			}
			this.getdata()
		},
		// 组件方法
		methods: {
			loginOrout() {
				if (this.userinfo) {
					ElMessageBox.confirm(
							'您确定要退出登陆', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								confirmButtonClass: 'btnConfirm',
								center: true,
							}
						)
						.then(() => {
							this.cancelOrder()
						})
						.catch(() => {})
				} else {
					this.$router.push({
						name: 'my'
					})
				}
			},
			cancelOrder() {
				loginOut().then(res => {
					if (res.code == 200) {
						ElMessage({
							message: '退出成功',
							type: 'success',
						})
						localStorage.clear()
						location.reload();
					}
				})
			},
			getdata() {
				config().then(res => {
					if (res.code == 200) {
						this.info = res.data.data
						for (var item of this.info) {
							if (item.key == 'contact') {
								this.seting.contacts_phone = item.value;
							}
							if (item.key == 'offline_bankname') {
								this.seting.offline_bankname = item.value;
							}
							if (item.key == 'offline_payaccount') {
								this.seting.offline_payaccount = item.value;
							}
						}

					}
				})
			},
			loginshow() {
				this.islogin = true
			},
			seraclick() {
				this.showSearch = !this.showSearch
			},
			loginclick() {
				this.islogin = true
			},
			goLanguage(data, index) {
				for (let i in data) {
					data[i].show = false
				}
				data[index].show = true


			},
			searchclick() {
				this.$router.push({
					name: 'activities',
					query: {
						'search': this.input
					}
				})
			},
			tabclick(index) {
				for (let var1 in this.titles) {
					this.titles[var1].ishow = false
				}
				this.titles[index].ishow = true

				switch (index) {
					case 0:
						this.$router.push({
							name: 'home'
						})
						break;
					case 1:
						this.$router.push({
							name: 'activities'
						})
						break;
					case 2:
						this.$router.push({
							name: 'shop'
						})
						break;
					case 3:
						this.$router.push({
							name: 'my'
						})
						break;
					case 4:
						this.$router.push({
							name: 'meeting'
						})
						break;
				}
			},
		},
	}
</script>
<style lang='scss'>
	.btnConfirm {
		background: #FF6A00 !important;
		color: #fff !important;
		border: 1px solid #FF6A00 !important;
	}

	.btnCancel {
		background: #009BF4 !important;
		color: #FFFFFF !important;
	}

	.el-button:first-child:hover {
		color: #333 !important;
		background-color: #fff !important;
		border-color: #999 !important;
	}

	.el-button:nth-child(2):hover {
		color: #FFFFFF !important;
		border-color: #FF6A00 !important;
		background-color: #FF6A00 !important;
	}
</style>

<style scoped lang='scss'>
	.top {
		background-color: #fff;
		padding: 0 50px;
		display: flex;
		min-width: 1000px;
		align-items: center;
		justify-content: space-between;
		align-items: center;
		box-shadow: 0px 4px 10px 0px rgba(220, 220, 220, 0.3);

		.left {
			display: flex;
			align-items: center;
			margin-left: 122px;

			.imgs {
				width: 230px;
				height: 87px;

			}

			.txt {
				font-size: 30px;
				color: #fff;
				font-weight: 400;
				margin-right: 30px;
			}

			.line {
				width: 0px;
				height: 32px;
				opacity: 1;
				border: 1px solid #FFFFFF;
				margin-right: 30px;
			}
		}

		.content {
			display: flex;
			align-items: center;
			min-width: 1300px;

			.tap {
				cursor: pointer;

				display: flex;
				margin-right: 80px;
				font-size: 16px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: 500;

				.name {
					padding: 8px 23px;
					color: #fff;
					/* width: 52px; */
					/* height: 27px; */
					/* line-height: 27px; */
					background-color: #FF6A00;
					border-radius: 6px 6px 6px 6px;
					margin-left: 60px;
					font-size: 16px;
					/* text-align: center; */
					/* min-width: 60px; */
				}

				.name1 {
					padding: 8px 23px;
					/* min-width: 60px; */
					color: #333333;
					background-color: #fff;
					border-radius: 6px 6px 6px 6px;
					margin-left: 60px;
					font-size: 16px;
				}
			}

			.search {
				/* padding: 0 10px; */
				height: 42px;
				margin-right: 40px;

			}

			.right {
				display: flex;
				align-items: center;
				justify-items: center;



			}
		}
	}

	/* ::v-deep .el-dialog__title {
		color: #0093D2;
	} */

	.Tips {
		color: #141923;
		font-size: 20px;
		margin: 0 auto;
		width: 80%;
		text-align: center;

	}

	.topicon {
		width: 40px;
		height: 20px;
		position: relative;
		top: -22px;
		right: 455px;

	}

	.topserach {
		height: 83px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.searview {
		border: 1px solid #FF6A00;
		border: 5px;
		display: flex;
		line-height: 43px;
	}

	.codebtn {
		font-size: 14px;
		width: 92px;
		text-align: center;
		/* height: ; */
		color: #fff;
		background: linear-gradient(90deg, #FF922B 0%, #FF6A00 100%);
	}

	::v-deep .el-button el-button--primary {
		background-color: #FF6A00;
	}
</style>