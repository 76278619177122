<template>
	<div class='box'>
		<heads title="首页" :showindex='Sindex'></heads>
		<div class="banner">
			<div>
				<el-carousel height="600px">
					<el-carousel-item v-for="(data,index) of homedata.pc_banner" :key="item">
						<img @click="bannerclick(data)" style="width:100%;height: 100%;" :src='data.img' :fit="fill"></img>
					</el-carousel-item>
				</el-carousel>
				<div class="creathuodong">
					<img class="topicon" src="../../src/assets/home12.png" alt="" />
					<div class="list">
						<el-form :model="formInline" class="demo-form-inline" label-width="100">
							<el-form-item label="活动名称：">
								<el-input class="shuru" v-model="formInline.name" placeholder="请输入" clearable />
							</el-form-item>
							<el-form-item label="举办城市：">
								<el-cascader popper-class="pc-sel-area-cascader" style="width: 350px;"
									ref="articleOrgRef" placeholder="请选择地址" v-model="formInline.citys" :props='props'
									:options="mapJson.data" />
							</el-form-item>
							<el-form-item v-if="open==2" label="活动类型：">
								<el-select popper-class="dropdown" filterable v-model="formInline.class_id"
									placeholder="请选择" clearable>
									<el-option v-for="item in Acclass" :label="item.name" :value="item.id" />
								</el-select>
							</el-form-item>
							<el-form-item v-if="open==2" label="起止日期：">
								<el-date-picker v-model="times" type="daterange" range-separator="到"
									start-placeholder="开始时间" end-placeholder="结束时间" :size="size" />
							</el-form-item>
							<el-form-item v-if="open==2" label="参加人数：">
								<el-input v-model="formInline.number" placeholder="请输入" clearable />
							</el-form-item>
							<el-form-item v-if="open==2" label="大致预算：">
								<el-input v-model="formInline.budget" placeholder="请输入" clearable />
							</el-form-item>
						</el-form>

						<div @click="serviceclick" v-if="open==2"
							style="display: flex;align-items: center;margin-left: 20px;">
							<img v-if="formInline.meeting_service!=1" class="serveIcon" src="../assets/my/sleced2.png"
								alt="" />
							<img v-if="formInline.meeting_service==1" class="serveIcon" src="../assets/my/sleced.png"
								alt="" />

							<div>同时需要搭建、用车等其他会议服务。</div>
						</div>
						<div @click="creatclick" class="subbtn" v-if="open==2">
							提交
						</div>

						<div class="openorclosed">
							<div>

							</div>
							<div class="open" @click="openclick">
								{{type}}
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- islogin -->
			<div class="center">
				<el-carousel style="box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);" height="63px" direction="vertical"
					:autoplay="true">
					<el-carousel-item v-for="item in homedata.announcement" :key="item">
						<div class="gonggao">
							<div style="display: flex;align-items: center;">
								<img class="icon" src="../assets/home/icon.png" alt="" />
								<div>{{item.name}}</div>
							</div>
							<div @click="mordegonggao" style="display: flex;align-items: center;">
								<div style="color: #FF6A00;font-size: 16px;">更多</div>
								<img class="icon1" src="../assets/home/right.png" alt="" />
							</div>
						</div>
					</el-carousel-item>
				</el-carousel>
				<div class="title" style="display: flex;justify-content: space-between;">
					<div></div>
					<div class="Tcenter">
						<img class="img" src="../assets/home/img.png" alt="" />
						<div class="biaoti">会议服务</div>
						<img class="img" src="../assets/home/img.png" alt="" />
					</div>
					<div @click="moreconf" style="display: flex;align-items: center;">
						<div class="moreT" style="color: #FF6A00;">更多</div>
						<img class="icon1" src="../assets/home/right.png" alt="" />
					</div>
				</div>
				<div style="display: flex;align-items: center;justify-content: space-around;">
					<div v-for="item in meetingServeArry" class="ceshi" @click="confClick(item)">
						<img class="conf" :src="item.background_img" alt="" />
						<div class="confcon">
							<div class="servename" style="font-size: 20px;color:#333333">
								{{item.name}}
							</div>
							<div class="servename" style="color:#666">
								查看更多》
							</div>
						</div>
					</div>
				</div>
				<div class="title" style="display: flex;justify-content: space-between;">
					<div></div>
					<div class="Tcenter">
						<img class="img" src="../assets/home/img.png" alt="" />
						<div class="biaoti">近期活动会议</div>
						<img class="img" src="../assets/home/img.png" alt="" />
					</div>
					<div @click="morehuodong" style="display: flex;align-items: center;">
						<div class="moreT" style="color: #FF6A00;">更多</div>
						<img class="icon1" src="../assets/home/right.png" alt="" />
					</div>
				</div>
				<div class="taglist">
					<div @click="Acclassclick(item)" v-for="item in Acclass" :class="item.slected?'tag1':'tag'">
						{{item.name}}
					</div>
				</div>
				<div class="taglist">
					<div @click="Actradeclick(item)" v-for="item in Actrade" :class="item.slected?'tag1':'tag'">
						{{item.name}}
					</div>
				</div>
				<div class="huiyilist">
					<div class="huiyiitem" v-for="item in showmeetingS" @click="activedescclick(item)">
						<img class="huiyiimg" :src="item.cover_img" alt="" />
						<div class="huiname">{{item.name}}</div>
						<div style="display: flex;justify-content: space-between;margin-top: 10px;">
							<div class="huiyitime">{{item.start_time_str}} {{item.end_time_str}} </div>
							<div v-show="item.activity_schedule_status==1" style="color:#54C968;" class="huiyitype">筹备中</div>
							<div v-show="item.activity_schedule_status==2" class="huiyitype">进行中</div>
							<div v-show="item.activity_schedule_status==3" style="color:#999999;" class="huiyitype">已结束</div>
						</div>
					</div>
				</div>

				<div class="title" style="display: flex;justify-content: space-between;">
					<div></div>
					<div class="Tcenter">
						<img class="img" src="../assets/home/img.png" alt="" />
						<div class="biaoti">精选推荐活动场地</div>
						<img class="img" src="../assets/home/img.png" alt="" />
					</div>
					<div @click="mordesite" style="display: flex;align-items: center;">
						<div class="moreT" style="color: #FF6A00;">更多</div>
						<img class="icon1" src="../assets/home/right.png" alt="" />
					</div>
				</div>
				<div class="taglist">
					<div @click="cityClick(item)" v-for="item in citys" :class="item.slected?'tag1':'tag'">{{item.name}}
					</div>
				</div>
				<div class="huiyilist">
					<div class="huiyiitem" @click="sitedesc(item)" v-for="item in sitelist">
						<img class="huiyiimg" :src="item.cover_img" alt="" />
						<div class="huiname">{{item.name}}</div>
						<div style="display: flex;justify-content: space-between;margin-top: 10px;">
							<div class="huiyitime">{{item.area}}</div>
							<!-- <div class="huiyitype">进行中</div> -->
						</div>
					</div>
				</div>

				<!-- //广告 -->
				<div>
					<el-carousel>
						<el-carousel-item @click="advertisementclick(data)"
							v-for="(data,index) of homedata.advertisement" :key="item">
							<img style="width:100%;height: 100%;border-radius: 10px;" :src='data.cover_str'
								:fit="fill"></img>
						</el-carousel-item>
					</el-carousel>
				</div>
				<div class="title" style="display: flex;justify-content: space-between;">
					<div></div>
					<div class="Tcenter">
						<img class="img" src="../assets/home/img.png" alt="" />
						<div class="biaoti">积分商城</div>
						<img class="img" src="../assets/home/img.png" alt="" />
					</div>
					<div @click="morshop" style="display: flex;align-items: center;">
						<div class="moreT" style="color: #FF6A00;">更多</div>
						<img class="icon1" src="../assets/home/right.png" alt="" />
					</div>
				</div>
				<div class="huiyilist">
					<div class="huiyiitem" style="width: 220px;font-size: 24px;" v-for="item in goodsdata"
						@click="gooddescclick(item)">
						<img class="huiyiimg" :src="item.cover_img" alt="" />
						<div class="huiname">{{item.name}}</div>
						<div v-show="item.showtype==3"
							style="display: flex;margin-top: 10px;color: #FF6A00;align-items: center;">
							<div><span style="font-size: 14px;">￥</span>{{item.price}}</div>
							<div>+</div>
							<img class="jifen" src="../assets/my/jifen.png" alt="" />
							<div>{{item.point}}</div>
						</div>
						<div v-show="item.showtype==2"
							style="display: flex;margin-top: 10px;color: #FF6A00;align-items: center;">
							<div><span style="font-size: 14px;">￥</span>{{item.price_all}}</div>
						</div>
						<div v-show="item.showtype==1"
							style="display: flex;margin-top: 10px;color: #FF6A00;align-items: center;">
							<img class="jifen" src="../assets/my/jifen.png" alt="" />
							<div>{{item.spec[0].point_all}}</div>
						</div>
					</div>
				</div>
				<div class="title">
					<div class="Tcenter">
						<img class="img" src="../assets/home/img.png" alt="" />
						<div class="biaoti">服务企业</div>
						<img class="img" src="../assets/home/img.png" alt="" />
					</div>
				</div>
				<div style="display: flex;align-items: center;justify-content: start;">
					<img @click="openweb(item)" v-for="item in homedata.business" class="businessitem" :src="item.img"
						alt="" />
				</div>
			</div>
			<footerview style="width: 100%;"></footerview>
		</div>

		<img class="baoming" @click="dialogVisible = true" src="../assets/home/baoming.png" alt="" />

		<el-dialog v-model="dialogVisible" title="" width="690" :before-close="handleClose">
			<div class="addaddress" style="height: 100px;">
				<el-form :model="form" class="demo-form-inline" label-width="100">
					<el-form-item label="搜索">
						<div style="display: flex;align-items: center;">
							<!-- <img style="width: 20px;height: 20px;margin-left: 20px;" src="../assets/search1.png"
								alt="" /> -->
							<el-select @change="meetingclick" style="width: 500px;" filterable remote reserve-keyword
								v-model="form.class_id" placeholder="请输入" clearable :remote-method="remoteMethod">
								<template #prefix>
									<el-icon color="#666666" style="margin-right:5px;">
										<Search />
									</el-icon>
								</template>
								<el-option v-for="item in showmeetingS" :label="item.name" :value="item.id">
									<span style="float: left">{{ item.name }}</span>
									<span style="
									          float: right;
									          color: var(--el-text-color-secondary);
									          font-size: 13px;
									        ">{{ item.no }}</span>
								</el-option>
							</el-select>
						</div>

					</el-form-item>
				</el-form>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		login,
		sendcode,
		pagehome,
		meetingList,
		sitelist,
		activitytrade,
		activityclass,
		meetingServe,
		meetingServeDetail,
		advertisement,
		activitycreate,
		activitylist,
		config,
		getgoodslist
	} from "@/utils/api"
	import heads from '@/components/head'
	import footerview from '@/components/footer'
	import loginview from '@/components/login.vue'
	import myCollection from '@/components/myCollection.vue'
	import {
		ArrowRight
	} from '@element-plus/icons-vue'
	import mapdata from '@/utils/city.json'
	import {
		ElMessage
	} from 'element-plus'
	export default {
		// 组件名
		name: 'hoMe',
		// 注册组件
		components: {
			heads,
			loginview,
			footerview
		},
		// 接收父组件数据
		// props: {},
		// 组件数据
		data() {
			return {
				ArrowRight: ArrowRight,
				advertisement: [],
				mapJson: mapdata,
				props: {
					label: "name",
					value: "name"
				},
				dialogVisible: false,
				islogin: false,
				open: 1,
				form: {},
				type: "展开",
				formInline: {
					meeting_service: 2
				},
				times: [],
				lang: 0,
				Sindex: 0,
				Actrade: [{
					"name": '全部行业',
					"id": '',
					'slected': true
				}],
				Acclass: [{
					"name": '全部类型',
					"id": '',
					'slected': true
				}],
				meetingS: [],
				// '', '培训', '推介会', '经销商会议', '招商会', '答谢会', '团队建设'],
				goods: [{
						name: '全部',
						slected: false
					},
					{
						name: '推介会',
						slected: false
					},
					{
						name: '经销商会议',
						slected: false
					},
					{
						name: '年会',
						slected: false
					}
				],
				tabList: [],
				banner: [],
				classid: '',
				homedata: {},
				sitelist: [],
				citys: [],
				goodsdata: [],
				meetingServeArry: [],
				pageSize: {
					// count: 0,
					limit: 8,
					page: 1,
					class_id: '',
					trade_id: '',
					recommend: '1'
				},
				showmeetingS: []
			}
		},
		// 计算属性
		computed: {},
		// 监听属性
		watch: {},
		// 生命周期钩子函数
		created() {

		},
		mounted() {
			console.log("mapJson====", this.mapJson);
			this.getdata()
			this.getactives()
		},
		// 组件方法
		methods: {
			bannerclick(data){
				var openUrl = data.pc_link
				window.open(openUrl);
			},
			openweb(data) {
				var openUrl = data.link
				window.open(openUrl);
			},
			remoteMethod(searchstring) {

				var form = {
					"search": searchstring,

				};
				activitylist(form).then(res => {
					if (res.code == 200) {
						for (var item of res.data.data) {
							item.start_time_str = this.shortMessage(item.start_time_str)
							item.end_time_str = this.shortMessage(item.end_time_str)
						}
						this.showmeetingS = res.data.data
					}
				})
			},
			advertisementclick(data) {
				console.log("2222222222222");
				this.openUrl = data.link
				window.open(this.openUrl);
			},
			moreconf() {

				this.$router.push({
					name: 'meeting',

				})
			},
			morehuodong() {
				this.$router.push({
					name: 'activities',

				})
			},
			morshop() {
				this.$router.push({
					name: 'shop'
				})
			},
			meetingclick(e) {
				console.log("e====", e);

				this.$router.push({
					name: 'activitiesDesc',
					query: {
						'activity_id': e
					}
				})

			},
			gooddescclick(item) {

				this.$router.push({
					name: 'shopdesc',
					query: {
						'shop_id': item.id
					}
				})
			},
			activedescclick(item) {
				console.log(item, 'item======')
				this.$router.push({
					name: 'activitiesDesc',
					query: {
						'activity_id': item.id
					}
				})
			},
			sitedesc(item) {
				this.$router.push({
					name: 'siteDesc',
					query: {
						desc: JSON.stringify(item),
					}
				})
			},
			//会议配置详情
			confClick(item) {
				this.$router.push({
					name: 'confDesc',
					query: {
						'id': item.id
					}
				})
			},
			serviceclick() {
				if (this.formInline.meeting_service == 1) {
					this.formInline.meeting_service = 2
				} else {
					this.formInline.meeting_service = 1
				}
			},
			creatclick() {
				console.log("this.formInline======", this.formInline)
				if (!this.formInline.citys) {
					ElMessage({
						message: '请选择城市',
						type: 'error',
					})
					return
				}
				var city = this.formInline.citys.join('-')

				if (this.times.length > 0) {
					this.formInline.start_time = this.times[0]
					this.formInline.end_time = this.times[1]
				}

				this.formInline.city = city

				activitycreate(this.formInline).then(res => {
					if (res.code == 200) {
						this.formInline = {}
						this.times = []
						ElMessage({
							message: '提交成功',
							type: 'success',
						})
					} else {
						ElMessage({
							message: res.message,
							type: 'error',
						})
					}
				})
			},
			shortMessage(message) {
				return message.substring(0, 10);
			},
			getactives() {
				//活动行业
				activitytrade().then(res => {
					if (res.code == 200) {
						console.log('res.Actrade===', res.data.data);
						// this.Actrade = res.data.data
						this.Actrade = [...this.Actrade, ...res.data.data];
					}
				})
				//活动分类
				activityclass().then(res => {
					if (res.code == 200) {
						console.log('res.Acclass===', res.data);
						// var data = {
						// 	"name": '全部分类',
						// 	"id": ''
						// }
						this.Acclass = [...this.Acclass, ...res.data.data];
					}
				})
				//活动分类
				activitylist(this.pageSize).then(res => {
					if (res.code == 200) {
						for (var item of res.data.data) {
							item.start_time_str = this.shortMessage(item.start_time_str)
							item.end_time_str = this.shortMessage(item.end_time_str)
						}
						this.showmeetingS = res.data.data
						this.meetingS = res.data.data
					}
				})

				//服务列表
				meetingServe({
					'limit': '4',
					'page': '1'
				}).then(res => {
					if (res.code == 200) {
						this.meetingServeArry = res.data.data
					}
				})

				sitelist().then(res => {
					this.citys = []
					if (res.code == 200) {

						this.sitelist = res.data.data
						if(res.data.area[0]){
							for (var item of res.data.area[0]) {
								var data = {
									"name": item,
									'slected': false
								};
								this.citys.push(data)
							}
						}
						
					}
				})



			},
			getsitelist() {
				sitelist().then(res => {
					if (res.code == 200) {
						this.sitelist = res.data.data
					}
				})
			},
			//点击城市
			cityClick(data) {
				console.log(data);
				console.log(this.citys);
				for (var item of this.citys) {
					item.slected = false
				}
				data.slected = true

				this.choosecity(data.name)
			},
			choosecity(name) {
				var form = {
					'search': name
				}
				// this.sitelist  = []
				sitelist(form).then(res => {
					if (res.code == 200) {
						this.sitelist = res.data.data
						// this.pageSize.count = res.data.count
					}
				})
			},
			itemclick(data) {
				console.log(data);
				for (var item of this.goods) {
					item.slected = false
				}
				data.slected = true
			},
			Acclassclick(data) {
				console.log(data);
				for (var item of this.Acclass) {
					item.slected = false
				}
				data.slected = true
				this.pageSize.class_id = data.id + ''
				this.changemeeting()
			},
			Actradeclick(data) {
				for (var item of this.Actrade) {
					item.slected = false
				}
				data.slected = true
				this.pageSize.trade_id = data.id + ''
				this.changemeeting()
			},
			changemeeting() {
				//活动分类
				activitylist(this.pageSize).then(res => {
					if (res.code == 200) {
						this.meetingS = []
						for (var item of res.data.data) {
							item.start_time_str = this.shortMessage(item.start_time_str)
							item.end_time_str = this.shortMessage(item.end_time_str)
						}
						this.showmeetingS = res.data.data
						// this.meetingS = res.data.data
					}
				})
			},
			mordegonggao() {

				this.$router.push({
					name: 'announcement'
				})
			},
			mordesite() {
				this.$router.push({
					name: 'site'
				})
			},
			// morsite() {

			// 	this.$router.push({
			// 		name: 'site'
			// 	})
			// },
			getdata() {

				pagehome().then(res => {
					if (res.code == 200) {
						this.homedata = res.data
						this.advertisement = res.data.advertisement
					}
				})
				getgoodslist(this.pageSize).then(res => {
					if (res.code == 200) {

						for (var item of res.data.data) {
							if (item.point_price_arr.includes('1')) {
								item.showtype = 1

							} else if (item.point_price_arr.includes('2')) {
								item.showtype = 2

							} else if (item.point_price_arr.includes('3')) {
								item.showtype = 3

							}

						}
						this.goodsdata = res.data.data
						console.log('res.this.goods===', this.goods);
						this.pageSize.count = res.data.count
					}
				})
				config().then(res => {
					if (res.code == 200) {
						for (var item of res.data.data) {
							if (item.key == 'pc_goods_def_cover') {
								localStorage.setItem("pc_goods_def_cover", item.img)
							}
							if (item.key == 'pc_activity_def_cover') {
								localStorage.setItem("pc_activity_def_cover", item.img)
							}
							if (item.key == 'pc_child_def_cover') {
								localStorage.setItem("pc_child_def_cover", item.img)
							}
						}
					}
				})
			},

			sendcodeclick() {
				// this.$refs.login.open()
				// console.log('this.$refs.login',this.$refs.login.open());
				this.islogin = true
				console.log(111111111);
				// sendcode({
				// 	"mobile": '135616326971'
				// }).then(res => {

				// })
			},
			getclasslist() {
				var from = {
					'place': '1'
				}
				hotClass().then(res => {})
			},
			changetab(index) {},
			getgoods() {

			},
			openclick() {
				if (this.open == 1) {
					this.open = 2
					this.type = '收起'
				} else {
					this.open = 1
					this.type = '展开'
				}
			},
			handleChange() {
				// await nextTick();
				// console.log(e)
				// const selectedOptions = articleOrgRef.value.getCheckedNodes();
			},
		},
	}
</script>
<style lang='scss'>
	.dropdown {

		.el-select__selected-item.selected {
			color: #FF6A00;
		}

		.el-select-dropdown__item.is-selected {
			color: #FF6A00!important;
		}


		.el-select__selected-item .el-select__placeholder {
			color: #FF6A00;
		}

		.el-select-dropdown__item.hover,
		.el-select-dropdown__item:hover {
			color: #FF6A00;
		}
	}

	.pc-sel-area-cascader {
		.el-cascader-node.in-active-path,
		.el-cascader-node.is-active,
		.el-cascader-node.is-selectable.in-checked-path {
			color: #FF6A00;
		}
	}
	.pc-sel-select-cascader {
		.el-select-node.in-active-path,
		.el-select-node.is-active,
		.el-select-node.is-selectable.in-checked-path {
			color: #FF6A00;
		}
	}

	.el-picker-panel .el-date-table.is-week-mode .el-date-table__row:hover div,
	.el-picker-panel .el-date-table td.in-range div,
	.el-picker-panel .el-date-table td.in-range div:hover {
		background-color: #FFF0E5;
		color: #333;
	}

	.el-picker-panel .el-date-table td.today span {
		color: #FF6A00;
		/* background-color: #fff; */
	}

	.el-picker-panel .el-date-table td.start-date span {
		color: #fff;
		background-color: #FF6A00;
	}

	.el-picker-panel .el-date-table td.end-date span {
		color: #fff;
		background-color: #FF6A00;
	}

	.el-date-table td:hover {
		color: #FF6A00 !important;
	}
</style>

<style scoped lang='scss'>
	.box {
		/* background-image: url('/src/assets/bacg2.png'); */
		/* background-repeat: round; */
		/* height: 100vh; */
		/* background-color: black; */

		.center {
			/* margin-top: -182px; */

			width: 1200px;
			margin-top: 150px;
			/* height: 1000px; */
			padding-bottom: 100px;
			margin-left: auto;
			margin-right: auto;
			/* display: flex; */
			font-size: 14px;
			/* background-color: antiquewhite; */
			/* justify-content: center; */

			.gonggao {
				display: flex;
				justify-content: space-between;
				background-color: beige;
				/* width: 1160px; */
				padding-left: 20px;
				/* padding-right: 20px; */
				height: 63px;
				background: #FFFFFF;
				line-height: 63px;
				box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
				border-radius: 10px 10px 10px 10px;

				.icon {
					width: 30px;
					height: 30px;
					margin-right: 10px;
				}

				.icon1 {
					width: 10px;
					height: 12px;
					margin-right: 20px;
					margin-left: 10px;
				}
			}


		}

		.banner {
			width: 100%;
			height: 600px;

			.creathuodong {
				position: absolute;
				z-index: 99;
				width: 520px;
				left: 360px;
				top: 600px;
				/* bottom: 100px; */
				/* background-color: #ffffff; */

				.topicon {
					width: 90px;
					height: 90px;
					z-index: 55;
					position: static;
					z-index: 100;
					position: relative;
				}

				.list {

					/* width: 520px; */
					top: -94px;
					border-radius: 10px;
					position: relative;
					background-color: #fff;
					padding: 40px;
					box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
				}

				.openorclosed {
					display: flex;
					justify-content: space-between;

					.open {

						color: #FF6A00;

						font-size: 16px;

					}
				}
			}
		}

		.banner image {
			width: 100%;
			height: 100%;
		}

		.icon {
			width: 258px;
			height: 258px;
			border-radius: 10px;
		}
	}

	.title {
		/* display: flex; */
		color: #333333;
		font-size: 32px;
		margin-top: 60px;
		margin-bottom: 20px;

		.biaoti {
			margin-left: 20px;
			margin-right: 20px;
		}

		.img {
			width: 28px;
			height: 20px;
		}

		.Tcenter {
			display: flex;
			align-items: center;
			justify-content: center;
			/* margin-bottom: 40px; */
		}
	}

	.ceshi {
		width: 285px;
		height: 120px;
		/* background-color: lightgoldenrodyellow; */
	}

	.taglist {
		display: flex;
		flex-wrap: wrap;

		.tag {
			padding: 10px 20px;
			color: #333333;
			margin-right: 20px;
			margin-bottom: 20px;
			font-size: 16px;
			border-radius: 10px 10px 10px 10px;
			border: 1px solid #999999;
		}

		.tag1 {
			padding: 10px 20px;
			color: #fff;
			background-color: #FF6A00;
			margin-right: 20px;
			margin-bottom: 20px;
			font-size: 16px;
			border-radius: 10px 10px 10px 10px;
			/* border: 1px solid #999999; */
		}
	}

	.huiyilist {
		display: flex;
		flex-wrap: wrap;

		.huiyiitem {
			width: 280px;
			height: 260px;

			margin-right: 20px;
			margin-bottom: 30px;
			margin-top: 10px;

			.huiyiimg {
				width: 100%;
				height: 190px;
				border-radius: 10px;

			}

			.huiname {
				margin-top: 10px;
				color: #333333;
				font-size: 20px;
				max-height: 52px;
			}

			.huiyitime {
				color: #666666;
				font-size: 16px;
			}

			.huiyitype {
				color: #FF6A00;
				font-size: 16px;
			}
		}
	}

	.jifen {
		width: 20px;
		height: 20px;
		margin-left: 5px;
		margin-right: 5px;
	}

	::v-deep .el-carousel__indicator--horizontal .el-carousel__button {
		width: 10px;
		height: 10px;
		background: #393B41;
		/* border: 1px solid #00F6FF; */
		border-radius: 50%;
		opacity: 0.3;
	}

	::v-deep .el-carousel__indicator--horizontal.is-active .el-carousel__button {
		width: 10px;
		height: 10px;
		background: #ffffff;
		border-radius: 50%;
		opacity: 1;
	}

	.shuru {
		width: 350px;
	}

	.codebtn {
		font-size: 14px;
		color: #FF6A00;
	}

	.btn {
		margin-top: 20px;
		width: 360px;
		height: 40px;
		border-radius: 5px;
		color: #fff;
		background-color: #FF6A00;
		text-align: center;
		line-height: 40px;
		/* margin-right: 20px; */
	}

	.loginIcon {
		margin-top: 40px;
		width: 60px;
		height: 60px;
	}

	.tabL {
		margin-top: 10px;
		font-size: 14px;
		color: #333333;
		text-align: center;
		padding-bottom: 40px;
	}

	.businessitem {
		width: 184px;
		height: 92px;
		margin-right: 20px;
		border-radius: 10px;
	}

	.moreT {
		font-size: 16px;
	}

	.icon1 {
		width: 10px;
		height: 12px;
		margin-right: 20px;
		margin-left: 10px;
	}

	.topserach {
		height: 83px;
		display: none;
		display: flex;
		align-items: center;
	}

	/* ::v-deep .el-input__wrapper {
		
		border: 0 !important;
		box-shadow: 0px 0px 0px 0px skyblue !important;
	
	} */
	.subbtn {
		width: 440px;
		height: 50px;
		background-color: #54C968;
		color: #fff;
		line-height: 50px;
		margin-top: 20px;
		margin-bottom: 20px;
		text-align: center;
		border-radius: 5px;
	}

	.servename {
		padding-left: 25px;
		padding-top: 20px;
		font-size: 14px;
		color: #333333;

	}

	.serveIcon {
		width: 16px;
		height: 16px;
		margin-right: 10px;
	}

	.conf {
		width: 100%;
		height: 100%;
		/* bottom: 100px; */
	}

	.confcon {
		position: relative;
		color: #fff;
		bottom: 120px;
	}

	.baoming {
		width: 100px;
		/* height: 100px; */
		position: absolute;
		right: 0px;
	}

	.btn00 {
		width: 92px;
		height: 42px;
		border-radius: 26px;
		border: 1px solid #FF6A00;
		color: #FF6A00;
		text-align: center;
		line-height: 42px;
		margin-right: 20px;
	}

	.btn1 {
		width: 92px;
		height: 42px;
		border-radius: 26px;
		color: #fff;
		background-color: #FF6A00;
		text-align: center;
		line-height: 42px;
		margin-right: 20px;
	}
</style>