var baseURL;
import request from "./request";
if (process.env.NODE_ENV === 'development') {
	baseURL = process.env.VUE_APP_BASE_URL
} else {
	baseURL = process.env.VUE_APP_BASE_URL
}
import {url_config} from "./baseUrl"
// classList,
// goodsList,
// banner,
// hotClass,
// hotList
/**
 * @param
 */
let url = url_config
//配置信息
export function config(param) {

	return request.post(url+"/api/index/config", param);
}

//登录
export function login(param) {

	return request.post(url+"/api/index/mobileLogin", param);
}
//微信登录
export function wePubLogin(param) {

	return request.post(url+"/api/index/wePubLogin", param);
}
//退出登录
export function loginOut(param) {

	return request.post(url+"/api/index/loginOut", param);
}
//发送验证码
export function sendcode(param) {

	return request.post(url+"/api/index/sendCode", param);
}
//发送邮件
export function sendEmailCode(param) {

	return request.post(url+"/api/index/sendEmailCode", param);
}
//首页
export function pagehome(param) {

	return request.post(url+"/api/index/home", param);
}
//公告列表
export function announcementlist(param) {

	return request.post(url+"/api/index/announcement", param);
}
//广告列表
export function advertisement(param) {

	return request.post(url+"/api/index/advertisement", param);
}
//场地列表
export function sitelist(param) {

	return request.post(url+"/api/index/site", param);
}
//活动类型
export function activityclass(param) {

	return request.post(url+"/api/activity/class", param);
}
//活动行业
export function activitytrade(param) {

	return request.post(url+"/api/activity/trade", param);
}
//活动列表
export function activitylist(param) {

	return request.post(url+"/api/activity/list", param);
}
//活动配置详情
export function activityConfDetail(param) {

	return request.post(url+"/api/activity/activityConfDetail", param);
}
//活动详情
export function activityDetail(param) {
	return request.post(url+"/api/activity/activityDetail", param);
}
//创建活动
export function activitycreate(param) {

	return request.post(url+"/api/activity/create", param);
}
//活动统计
export function myActivityCount(param) {

	return request.post(url+"/api/activity/myActivityCount", param);
}

//活动删除
export function myActivitydel(param) {

	return request.post(url+"/api/activity/del", param);
}

//会议列表
export function meetingList(param) {

	return request.post(url+"/api/activity/meetingList", param);
}
//会议详情
export function meetingDetail(param) {
	return request.post(url+"/api/activity/meetingDetail", param);
}
//会议配置详情
export function meetingConfDetail(param) {

	return request.post(url+"/api/activity/meetingConfDetail", param);
}


//会议嘉宾列表
export function activityGuest(param) {
	return request.post(url+"/api/activity/activityGuest", param);
}
//嘉宾详情
export function activityGuestDetail(param) {
	return request.post(url+"/api/activity/activityGuestDetail", param);
}
//签到 会议签到
export function signIn(param) {
	return request.post(url+"/api/activity/signIn", param);
}
//收藏
export function collect(param) {
	return request.post(url+"/api/activity/collect", param);
}

//我的报名
export function myMeetingApplication(param) {
	return request.post(url+"/api/activity/myMeetingApplication", param);
}

//取消报名
export function meetingCancelApply(param) {
	return request.post(url+"/api/MeetingOrder/cancelMeetingOrderChild", param);
}

//我的报名 删除
export function meetingChildDel(param) {
	return request.post(url+"/api/MeetingOrder/deleteChildOrder", param);
}

//申请发票
export function meetingApplyInvoice(param) {
	return request.post(url+"/api/activity/meetingApplyInvoice", param);
}
//编辑 我的活动
export function editMyActivity(param) {
	return request.post(url+"/api/activity/create", param);
}
//商品 get列表
export function getgoodslist(param) {
	return request.get(url+"/api/goods/list", param);
}
//商品 列表
export function goodslist(param) {
	return request.post(url+"/api/goods/list", param);
}
//商品 详情
export function goodsdetail(param) {
	return request.post(url+"/api/goods/detail", param);
}
//商品 类型
export function goodsdclass(param) {
	return request.post(url+"/api/goods/class", param);
}
//商品 收藏
export function goodscollect(param) {
	return request.post(url+"/api/goods/collect", param);
}
//商品 评价
export function goodsEvaluate(param) {
	return request.post(url+"/api/goods/goodsEvaluate", param);
}
//商品 评价列表
export function goodsEvaluatelist(param) {
	return request.post(url+"/api/goods/evaluateList", param);
}
///商品 计算价格
export function goodscalculatePrice(param) {
	return request.post(url+"/api/GoodsOrder/calculatePrice", param);
}

///计算价格
export function calculatePrice(param) {
	return request.post(url+"/api/MeetingOrder/calculatePrice", param);
}
//创建订单 Copy Copy
export function createOrder(param) {
	return request.post(url+"/api/MeetingOrder/createOrder", param);
}
//拉起支付 Copy
export function MeetingOrderpay(param) {
	return request.post(url+"/api/MeetingOrder/pay", param);
}
export function cancelMeetingOrder(param) {
	return request.post(url+"/api/MeetingOrder/cancelMeetingOrder", param);
}

///商品 创建订单
export function goodscreateOrder(param) {
	return request.post(url+"/api/GoodsOrder/createOrder", param);
}

///商品 拉起支付
export function GoodsOrderpay(param) {
	return request.post(url+"/api/GoodsOrder/pay", param);
}

//会议服务 列表
export function meetingServe(param) {
	return request.post(url+"/api/index/meetingServe", param);
}
//会议服务 详情
export function meetingServeDetail(param) {
	return request.post(url+"/api/index/meetingServeDetail", param);
}
//会议 报名数据
export function meetingOrderData(param) {
	return request.post(url+"/api/activity/meetingOrderData", param);
}
// //会议 拉起支付
// export function MeetingOrderpay(param) {
// 	return request.post(url+"/api/MeetingOrder/pay", param);
// }
// 酒店列表
export function hotellist(param) {
	return request.post(url+"/api/hotel/list", param);
}
// 酒店详情
export function hoteldetail(param) {
	return request.post(url+"/api/hotel/detail", param);
}
// 酒店房型
export function hotelroom(param) {
	return request.post(url+"/api/hotel/room", param);
}

// 酒店创建订单
export function hotelraddOrder(param) {
	return request.post(url+"/api/hotel_order/addOrder", param);
}
// 酒店支付
export function hotelrpay(param) {
	return request.post(url+"/api/hotel_order/pay", param);
}
// 删除-酒店订单
// export function meetingOrderData(param) {
// 	return request.post(url+"/api/activity/meetingOrderData", param);
// }


export function fileUpload(data, callback) {
	return request({
		url: url+"/base/upload_image",
		method: 'post',
		headers: {
			"Content-Type": "multipart/form-data"
		},
		data,
	})
}