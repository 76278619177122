<template>
	<div style="background-color: #fff;padding: 20px;
				background: #fff;
				width: 1000px;">
		<div style="color: #333333;font-size: 24px;padding-left: 10px;border-left: 4px solid #FF6A00;">
			我的地址
		</div>
		<!-- <el-button round style=" background-color:#FF6A00;margin-top: 20px;margin-bottom: 40px;color: #fff;"
			@click="add">添加收货地址</el-button> -->
			<div class="btn1" style="width: 120px;margin-top: 20px;margin-bottom: 40px" @click="add">添加收货地址</div>
		<div>
			<div class="list">
				<div class="listitem" v-for="(item,index) in list" :key="index">
					<div class="top">
						<div class="toptitle">
							收货人：{{item.name}}
						</div>
						<div>
							<el-icon color="#FF6A00" @click="edit(item)" style="margin-right: 20px;">
								<EditPen />
							</el-icon>
							<el-icon color="#FF6A00" @click="chanel(item)">
								<Delete />
							</el-icon>
						</div>
					</div>
					<div class="desc">
						所在地区：{{item.address}}
					</div>
					<div class="desc">
						详细地址：{{item.area}}
					</div>
					<div class="desc">
						手机号码：{{item.mobile}}
					</div>
					<div class="desc">
						是否默认地址：
						<!-- {{item.is_default}} -->
						<el-switch :active-value="1" :inactive-value="2" v-model="item.is_default" active-color="#eee"
							inactive-color="#FF6A00" @change="addchange(item)">
						</el-switch>
					</div>
				</div>

			</div>
		</div>
		<el-dialog v-if="dialogVisible" v-model="dialogVisible" :title="formInline.id?'编辑地址':'新增地址'" width="590" :before-close="handleClose">
			<div class="addaddress">
				<el-form :model="formInline" class="demo-form-inline" label-width="100">
					<el-form-item label="姓名：">
						<el-input v-model="formInline.name" placeholder="请输入" clearable />
					</el-form-item>
					<el-form-item label="电话：">
						<el-input onkeyup="value=value.replace(/[^\d]/g,'')"
							@blur="formInline.mobile = $event.target.value"
							 v-model="formInline.mobile"
							placeholder="请输入" clearable />
					</el-form-item>
					<el-form-item label="所在地区：">
						<el-cascader popper-class="pc-sel-area-cascader" style="width: 380px;" ref="articleOrgRef" :placeholder="placeholderText"
							v-model="formInline.citys" :props='props' :options="mapJson.data" />
					</el-form-item>
					<el-form-item label="详细地址：">
						<el-input v-model="formInline.address" type="textarea" placeholder="请输入" clearable />
					</el-form-item>
				</el-form>
			</div>

			<template #footer>
				<div class="dialog-footer" style="display: flex;justify-content: space-between;">
					<div>

					</div>
					<div style="display: flex;">
						<div class="btn" @click="dialogVisible = false">取消</div>
						<div class="btn1" @click="subAddress">
							保存
						</div>
					</div>

				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		addressList,
		addressAddEdit,
		addressDel
	} from "@/utils/Myapi.js"
	import mapdata from '@/utils/city.json'
	import {
		ElMessage,
		ElMessageBox
	} from 'element-plus'
	export default {
		// 组件名
		name: 'heAd',
		// 注册组件
		components: {},
		// 接收父组件数据
		props: {},
		// 组件数据
		data() {
			return {
				titlename:'新增收货地址',
				props: {
					label: "name",
					value: "name"
				},
				mapJson: mapdata,
				formInline: {
					citys: []
				},
				activeName: '',
				FullShow: true,
				dialogVisible: false,
				status: '',
				lang: '',
				list: [],
				input3: "",
				titles: [],
				placeholderText:'请选择地址'
			}
		},
		// 计算属性login
		computed: {},
		// 监听属性
		watch: {},
		// 生命周期钩子函数
		created() {


		},
		mounted() {
			this.getdata()
		},
		// 组件方法
		methods: {
			chanel(item) {

				ElMessageBox.confirm(
						'您确定要删除地址', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							confirmButtonClass: 'btnConfirm',
							center: true,
						}
					)
					.then(() => {
						this.cancelOrder(item.id)
					})
					.catch(() => {})

			},
			cancelOrder(id) {
				addressDel({
					'id': id
				}).then(res => {
					if (res.code == 200) {
						ElMessage({
							message: '删除成功',
							type: 'success',
						})
						this.getdata()
					}
				})
			},
			addchange(item) {

				console.log("item=====", item.is_default)
				
				if (item.is_default == 2) {

					item.is_default = 2
					console.log("item=====111")
				} else {
					console.log("item=====222")
					item.is_default = 1

				}
				console.log("item=====222", item)
				this.formInline = item;

				addressAddEdit(this.formInline).then(res => {
					if (res.code == 200) {
						ElMessage({
							message: '修改成功',
							type: 'success',
						})
						this.dialogVisible = false
						this.getdata()
					}
				})
			},
			edit(item) {
				if (item) {
					this.formInline = item;
					this.formInline.citys = item.area.split('-');
					this.placeholderText = item.area.split('-');
				}

				this.dialogVisible = true;
			},
			dele(index) {

			},
			subAddress() {
					if(this.formInline.mobile.length!=11){
						ElMessage({
							message:'请输入正确的手机号',
							type: 'error',
						})
						return
					}
				console.log("this.formInline", this.formInline)
				
				if(!this.formInline.citys){
					ElMessage({
						message:'请选择地址',
						type: 'error',
					})
					return
				}
				
				var city = this.formInline.citys.join('-')
				var user = JSON.parse(localStorage.getItem("user"));

				this.formInline.area = city
				this.formInline.uid = user.id
				addressAddEdit(this.formInline).then(res => {
					if (res.code == 200) {
						ElMessage({
							message: '提交成功',
							type: 'success',
						})
						this.dialogVisible = false
						this.getdata()
					}
				})

			},

			getdata() {
				//我的地址
				addressList().then(res => {
					if (res.code == 200) {
						this.list = res.data.data
					}
				})

			},
			add() {
				this.formInline ={};
				this.dialogVisible = true
			},
			handleClick(index) {
				for (let var1 in this.titles) {
					this.titles[var1].ishow = false
				}
				this.titles[index].ishow = true

				switch (index) {}
			},
		},
	}
</script>
<style lang='scss'>
	.btnConfirm {
		background: #FF6A00 !important;
		color: #fff !important;
		border: 1px solid #FF6A00 !important;
	}

	.btnCancel {
		background: #009BF4 !important;
		color: #FFFFFF !important;
	}

	.el-button:first-child:hover {
		color: #333 !important;
		background-color: #fff !important;
		border-color: #999 !important;
	}

	.el-button:nth-child(2):hover {
		color: #FFFFFF !important;
		border-color: #FF6A00 !important;
		background-color: #FF6A00 !important;
	}
</style>
<style lang='scss'>
	

	.pc-sel-area-cascader {
		.el-cascader-node.in-active-path,
		.el-cascader-node.is-active,
		.el-cascader-node.is-selectable.in-checked-path {
			color: #FF6A00;
		}
	}
	.pc-sel-select-cascader {
		.el-select-node.in-active-path,
		.el-select-node.is-active,
		.el-select-node.is-selectable.in-checked-path {
			color: #FF6A00;
		}
	}

	.el-picker-panel .el-date-table.is-week-mode .el-date-table__row:hover div,
	.el-picker-panel .el-date-table td.in-range div,
	.el-picker-panel .el-date-table td.in-range div:hover {
		background-color: #FFF0E5;
		color: #333;
	}

	.el-picker-panel .el-date-table td.today span {
		color: #FF6A00;
		/* background-color: #fff; */
	}

	.el-picker-panel .el-date-table td.start-date span {
		color: #fff;
		background-color: #FF6A00;
	}

	.el-picker-panel .el-date-table td.end-date span {
		color: #fff;
		background-color: #FF6A00;
	}

	.el-date-table td:hover {
		color: #FF6A00 !important;
	}
</style>
<style scoped lang="scss">
	.list {
		display: flex;

		.listitem {
			width: 430px;
			padding: 20px;
			height: 210px;
			margin-right: 20px;
			margin-top: 20px;
			border: 1px solid #EEEEEE;

			.top {
				display: flex;
				justify-content: space-between;

				.toptitle {
					color: #333333;
					font-size: 16px;
				}
			}

			.desc {
				margin-top: 20px;

				color: #333333;
				font-size: 16px;
			}
		}
	}

	.btn {
		width: 92px;
		height: 42px;
		border-radius: 26px;
		border: 1px solid #FF6A00;
		color: #FF6A00;
		text-align: center;
		line-height: 42px;
		margin-right: 20px;
	}

	.btn1 {
		width: 92px;
		height: 42px;
		border-radius: 26px;
		color: #fff;
		background-color: #FF6A00;
		text-align: center;
		line-height: 42px;
		margin-right: 20px;
	}

	.addaddress {
		padding: 40px;
	}
</style>