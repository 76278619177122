import request from "./request";
import {url_config} from "./baseUrl"
// export function newfileUpload (data, callback) {
//     return request({
//         url: '/admin/file/upload',
//         method: 'post',
//         headers: { "Content-Type": "multipart/form-data" },
//         data,
//     })
// }
let url = url_config
//上传图片
export function newfileUpload(param) {
	return request.sendData(url+"/base/upload_image", param);
}
//上传视频
export function newfileUploadvideo(param) {
	return request.sendData(url+"/base/upload_video", param);
}
//团队认证 导入
export function authGroupImport(param) {
	return request.sendData(url+"/api/user/authGroupImport", param);
}

//团队认证 导出
export function authGroupExport(param) {
	return request.get(url+"/api/user/authGroupExport", param);
}


