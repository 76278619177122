import axios from "axios";
import loginview from '@/components/login.vue'
//  var qs = require('qs');
// import { useStore } from '../store/index.js'

import stringUtil from './stringUtil'
// import qs from 'qs'
 import router from '../router'
//  import {
//    Toast
//  } from 'vant';
/* axios 配置 */
axios.defaults.timeout = 500000;
axios.defaults.retry = 4;
axios.defaults.retryDelay = 1000;
//  axios.defaults.baseURL = env.prod.baseUrl;

axios.interceptors.request.use(
	config => {

		// let auth = 'Bearer' + ' ' + stringUtil.getLocalStorage("token");
		let auth = localStorage.getItem("token");

		console.log('auth========', auth)
		if (auth != undefined) {
			// config.headers.common['token'] = auth
			config.headers['token'] = auth
		}
		return config


	},
	err => {
		return Promise.reject(err)
	});


axios.interceptors.response.use(
	response => {
		stringUtil.loading(false);
		if (response.data.code == 412) {
			window.dispatchEvent(
				new CustomEvent('loginOut', {})
			)
			router.push('/login')
			//登录页
			
			
			// const store = useStore()
			// store.commit('add', num)
			// store.commit("CHANGE_TXT", true)

			//    stringUtil.clearLocalStorage('Authorization')
			//    Toast({
			//      message: '登录过期，请重新登录',
			//      onClose: () => {
			//        if (window.location.href.indexOf('/user') !== -1) {
			//          window.location.reload()
			//        } else {
			         // router.push('/login')
			//        }
			//      }
			//    });

		}
		return response;
	},
	error => {
		if (error) {
			// stringUtil.loading(false);
			// alert('请求接口失败,' + error);
		}
		// console.log(error)
		// return Promise.reject(error) // 返回接口返回的错误信息
	});

export default {
	get(url, params) {
		let queryString = [];
		for (let key in params) {
			queryString.push(`${key}=${params[key]}`);
		}
		if (queryString.length > 0) {
			queryString = queryString.join('&');
			url += `?${queryString}`;
		}
		return new Promise((resolve, reject) => {
			axios.get(url).then(res => {
				resolve(res.data);
			}).catch(err => {
				reject(err)
			})
		})
	},

	post(url, params) {
		return new Promise((resolve, reject) => {
			url = url.toString();
			axios.post(url, params, {
					headers: {
						'Content-Type': 'application/json'
					}
				}).then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err)
				})
		});
	},
	postPhoto(url, params) {
		return new Promise((resolve, reject) => {
			url = url.toString();
			axios.post(url, params, {})
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err)
				})
		});
	},
	sendData(url, params = {}) {
		return new Promise((resolve, reject) => {
			axios.post(url, params, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				})
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err)
				})
		});
	},
	downData(url, params = {}) {
		return new Promise((resolve, reject) => {
			axios.post(url, params, {
				responseType: 'blob',
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			
				.then(res => {
					// resolve(res.data);
					const blob = res.data
					if (blob && blob.size === 0) {
						this.$notify.error('内容为空，无法下载')
						return
					}
					const link = document.createElement('a')
					link.href = window.URL.createObjectURL(blob)
					link.download = "团队成员.xlsx"
					document.body.appendChild(link)
					link.click()
					window.setTimeout(function() {
						window.URL.revokeObjectURL(blob)
						document.body.removeChild(link)
					}, 0)
				})
				.catch(err => {
					reject(err)
				})
		});
	},
}