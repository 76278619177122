<template>
	<div style="background-color: #fff;padding: 20px;
				background: #fff;
				width: 1000px;">
		<div style="color: #333333;font-size: 24px;padding-left: 10px;border-left: 4px solid #FF6A00;margin-bottom: 20px;">
			报名订单
		</div>
		<el-tabs v-model="activeName" @tab-click="handleClick">
			<el-tab-pane label="全部" name="first"></el-tab-pane>
			<el-tab-pane label="待支付" name="second"></el-tab-pane>
			<el-tab-pane label="支付审核中" name="third"></el-tab-pane>
			<el-tab-pane label="已完成" name="fourth"></el-tab-pane>
			<el-tab-pane label="已取消" name="five"></el-tab-pane>
		</el-tabs>
		<div>
			<div class="list">
				<div class="listitem" v-for="(item,index) in list">
					<div class="top">
						<!--   `status` tinyint(4) DEFAULT NULL COMMENT '1.待支付 2.已支付 3.已退款 4.已取消 5.已关闭 6.已报名',
 -->
						<div>
							所属活动：{{item.activity.name}}
						</div>
						<div v-show="item.pay_status==1&&item.status==1" style="color: #FF6A00;">
							未支付
						</div>
						<div v-show="item.pay_status==2&&item.status==1" style="color: #FF6A00;">
							支付审核中
						</div>
						<div v-show="item.pay_status==3&&item.status==2" style="color: #FF6A00;">
							已完成
						</div>
						<div v-show="item.status==4" style="color: #FF6A00;">
							已取消
						</div>
						<div v-show="item.status==3" style="color: #FF6A00;">
							已退款
						</div>
						<div v-show="item.status==5" style="color: #FF6A00;">
							已关闭
						</div>
					</div>
					<div class="desc">
						报名类型：{{item.activity.class_name}}
					</div>
					<div class="desc">
						提交时间：{{item.create_time}}
					</div>
					<el-divider v-show="item.status!=4"></el-divider>
					<div style="display: flex;justify-content: space-between;">
						<div>

						</div>
						<div class="btnright">
							<div v-show="item.pay_status==2&&item.status==1" class="btn1" @click.stop="gopingzheng(item)">
								查看凭证
							</div>
							<div v-show="item.pay_status==1&&item.status==1" class="btn1" @click.stop="payclick(item)">
								去支付
							</div>
							<div v-show="item.pay_status!=3&&item.status==1" class="btn" @click.stop="cancelclick(item)">
								取消报名
							</div>
							<div v-show="item.pay_status==3" class="btn" @click.stop="clickitem(item)">
								查看详情
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<el-pagination v-model:current-page="pagesize.page" :page-size="pagesize.limit" :background="background"
			layout="total, prev, pager, next" :total="pagesize.count" @size-change="handleSizeChange"
			@current-change="getdata" />
	</div>
</template>


<script>
	import {
		meetingOrderDetail,
		meetingOrderList
	} from "@/utils/Myapi.js"
	import {
		cancelMeetingOrder
	} from "@/utils/api.js"

	import {
		ElMessage,
		ElMessageBox
	} from 'element-plus'
	import {
		Action
	} from 'element-plus'
	export default {
		// 组件名
		name: 'heAd',
		// 注册组件
		components: {},
		// 接收父组件数据
		props: {},
		// 组件数据
		data() {
			return {
				activeName: 'first',
				FullShow: true,
				dialogVisible: false,
				status: '',
				lang: '',
				input3: "",
				list: [],
				titles: [],
				auth: {},
				pagesize: {
					count: 0,
					limit: 5,
					page: 1,
					pay_status: ''
				},
			}
		},
		// 计算属性login
		computed: {},
		// 监听属性
		watch: {},
		// 生命周期钩子函数
		created() {


		},
		mounted() {
			this.getdata()
		},
		// 组件方法
		methods: {
			// pingzheng
			gopingzheng(item) {

				this.openUrl = 'http://exhibition-pc.huijik.com/#/pingzheng?from=2&id=' + item.id;

				window.open(this.openUrl);
				// this.$router.push({
				// 	name: 'pingzheng',
				// 	query: {
				// 		'id': item.id,
				// 		'from': '2'
				// 	}
				// })



			},
			payclick(item) {
				this.$router.push({
					name: 'orderpay',
					query: {
						//money_type 1.全积分 2.全现金 3.积分+现金
						'order_id': item.id,
						"order_no": item.orderno,
						"pay_price": item.pay_price,
						"from": '2',
						offline:JSON.stringify(item.offline)
					}
				})

			},
			cancelclick(item) {
				ElMessageBox.confirm(
						'您确定要，取消订单', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							confirmButtonClass: 'btnConfirm',
							center: true,
						}
					)
					.then(() => {
						// ElMessage({
						//   type: 'success',
						//   message: 'Delete completed',
						// })
						this.cancelOrder(item.id)
					})
					.catch(() => {
						// ElMessage({
						//   type: 'info',
						//   message: 'Delete canceled',
						// })
					})
			},
			cancelOrder(id) {
				cancelMeetingOrder({
					'order_id': id
				}).then(res => {
					if (res.code == 200) {
						ElMessage({
							type: 'success',
							message: '取消成功',
						})
						this.getdata()
					} else {
						ElMessage({
							type: 'error',
							message: '取消失败',
						})
					}
				})
			},
			getdata() {
				//
				meetingOrderList(this.pagesize).then(res => {
					if (res.code == 200) {
						this.list = res.data.data
						this.pagesize.count = res.data.count

					}
				})
			},
			clickitem(item) {

				http: //localhost:8080/registration

					this.openUrl = 'http://exhibition-pc.huijik.com/#/registration?id=' + item.id;

				window.open(this.openUrl);

				// this.$router.push({
				// 	name: 'registration',
				// 	query: {
				// 		'id':item.id
				// 	}
				// })
			},

			handleClick(e) {
				// console.log(e.index)
				if (e.index == 0) {
					this.pagesize.pay_status = ''
				} else {
					this.pagesize.pay_status = e.index
				}

				this.getdata()
				// console.log(this.activeName,"=====activeName")
				// console.log(e.index,"=====e")
			},
		},
	}
</script>
<style lang='scss'>
	.btnConfirm {
		background: #FF6A00 !important;
		color: #fff !important;
		border: 1px solid #FF6A00 !important;
	}

	.btnCancel {
		background: #009BF4 !important;
		color: #FFFFFF !important;
	}

	.el-button:first-child:hover {
		color: #333 !important;
		background-color: #fff !important;
		border-color: #999 !important;
	}

	.el-button:nth-child(2):hover {
		color: #FFFFFF !important;
		border-color: #FF6A00 !important;
		background-color: #FF6A00 !important;
	}
</style>
<style scoped lang="scss">
	.list {

		.listitem {
			width: 920px;
			padding: 20px;
			margin-top: 20px;
			// height: 290px;

			border: 1px solid #EEEEEE;

			.top {
				display: flex;
				justify-content: space-between;
			}

			.desc {
				margin-top: 20px;
				color: #333333;
				font-size: 16px;
			}
		}
	}

	.btn {
		width: 112px;
		height: 42px;
		border-radius: 26px;
		border: 1px solid #FF6A00;
		color: #FF6A00;
		text-align: center;
		line-height: 42px;
	}

	.grop {
		display: flex;
		flex-wrap: wrap;

		.gropitem {
			width: 240px;
			height: 70px;
			padding: 20px;
			display: flex;

			border-radius: 10px 10px 10px 10px;
			border: 1px solid #EEEEEE;

			.icon {
				width: 70px;
				height: 70px;
				margin-right: 20px;
				margin-right: 20px;
			}

			.number {

				font-size: 24px;
				color: #333333;
			}

			.title {
				margin-top: 12px;
				color: #666666;

				font-size: 16px;
			}
		}
	}

	::v-deep .el-tabs__item.is-active {
		color: #FF6A00; //选中
		opacity: 1;
	}

	::v-deep .el-tabs__item:hover {
		color: #FF6A00; //悬浮
		cursor: pointer;
		opacity: 1;
	}

	/* 下划线颜色 */
	::v-deep .el-tabs__active-bar {
		background-color: #FF6A00;
	}

	::v-deep .el-tabs__nav-wrap::after {

		position: static !important;

	}

	.btn1 {
		width: 112px;
		height: 42px;
		border-radius: 26px;
		color: #fff;
		background-color: #FF6A00;
		text-align: center;
		line-height: 42px;
		margin-right: 20px;
	}

	.btnright {
		display: flex;
		justify-content: space-around;
	}

	::v-deep .el-button:first-child:hover {
		color: #fff !important;
		background-color: #409eff !important;
	}

	::v-deep .el-button:nth-child(2):hover {
		color: #FF6A00 !important;
		border-color: #c6e2ff !important;
		background-color: #ecf5ff !important;
	}
	/* 修改前后箭头未点击时的背景颜色 */
	::v-deep .el-pagination .btn-prev,
	::v-deep .el-pagination .btn-next {
		/* background-color: #0e3a90 !important; */
	}
	
	/* 修改未点击时的数字方块背景颜色 */
	:v-deep .el-pagination .el-pager li:not(.active):not(.disabled):hover {
		/* background-color: #75fbff !important; */
	}
	
	/* 未点击时的数字方块背景颜色 */
	::v-deep .el-pager li.is-active {
		/* background-color: #0e3a90 !important; */
		color: #FF6A00;
	}
	
	::v-deep .el-pagination.is-background .el-pager li:not(.is-disabled).is-active {
		/* background-color: #75fbff !important; //修改默认的背景色 */
		color: #01133c;
	}
</style>