import loadimg from '../assets/loading1.gif';


export default {

  /**
   * null转换
   * @param str
   * @returns {string}
   */
  isNull: function (str) {
    return str == null || str == undefined ? "" : str;
  },

  /**
   * 验证是否是pc还是移动
   * @returns {boolean}
   */
  isPC: function () {
    var userAgentInfo = navigator.userAgent;
    var Agents = ["Android", "iPhone",
      "SymbianOS", "Windows Phone",
      "iPad", "iPod"];
    var flag = true;
    for (var v = 0; v < Agents.length; v++) {
      if (userAgentInfo.indexOf(Agents[v]) > 0) {
        flag = false;
        break;
      }
    }
    return flag;
  },

  /**
   * 判断移动端是否竖屏还是横屏
   * @returns {boolean}
   */
  isHorizontalScreen: function () {
    var flag;//IOS  Android
    var _this = this;

    if (_this.isAndroid()) {//Android
      if (window.orientation == 0 || window.orientation == 180) {
        flag = true;
      } else if (window.orientation == 90 || window.orientation == -90) {
        flag = false;
      }
    } else {//IOS
      if (window.orientation == 90 || window.orientation == -90) {
        flag = true;
      } else if (window.orientation == 0 || window.orientation == 180) {
        flag = false;
      }
    }

    window.onorientationchange = function () {//手动旋转屏幕触发
      if (_this.isAndroid()) {//Android
        if (window.orientation == 0 || window.orientation == 180) {
          flag = true;
        } else if (window.orientation == 90 || window.orientation == -90) {
          flag = false;
        }
      } else {//IOS
        if (window.orientation == 90 || window.orientation == -90) {
          flag = true;
        } else if (window.orientation == 0 || window.orientation == 180) {
          flag = false;
        }
      }
    };
    return flag;
  },

  /**
   * 设置过期localStorage
    * @param flag
   */
  setLocalStorage: function (key, value, day) {
    let expirse = day * 24 * 60 * 60 * 1000;//毫秒
    let data = {
      value,
      expirse: new Date().getTime() + expirse
    }
    localStorage.setItem(key, JSON.stringify(data));
  },
  getLocalStorage: function (key) {
    let auth = localStorage.getItem(key);
    let data = '';
    if (auth && auth.indexOf('value') > -1) {
      data = JSON.parse(auth);
    }
    if (data !== null) {
      if (data.expirse != null && data.expirse < new Date().getTime()) {
        localStorage.removeItem(key);
      } else {
        return data.value;
      }
    }
    return null;
  },
  clearLocalStorage: function () {
    localStorage.clear();
    // localStorage.removeItem('Authorization');
  },

  loading: function (flag) {
    var loadingHTML = '<div class="up-toast up-toast-loading mask icon" style="position: fixed;">\n' +
      '        <div class="has-text-body up-toast-box">\n' +
      '            <div class="up-toast-icon">\n' +
      '                <img src="' + loadimg + '">\n' +
      '            </div> 加载中\n' +
      '        </div>\n' +
      '    </div>';
    var loading = document.createElement('div');
    loading.id = "loading";
    if (flag) {
      loading.innerHTML = loadingHTML;
      document.body.appendChild(loading);
    } else {
      var loadingbox = document.querySelector('#loading');

      if (!loadingbox) return;
      loadingbox.innerHTML = '';
      document.body.removeChild(loadingbox);
    }
  },
  //对象按照字母顺序排序
  objSort: function (obj) {
    var result = {};
    var arr = Object.keys(obj).sort();
    // console.log('---------',arr)
    arr.map((k) => {
      result[k] = obj[k];
    })
    // if(!obj[key]){
    //   obj[key] = null;
    // }
    // console.log('result',result)
    return result;
  },
  randomString: function (len) {//随机串
    len = len || 32;
    let chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';    /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
    let maxPos = chars.length;
    let pwd = '';
    for (let i = 0; i < len; i++) {
      pwd += chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return pwd;
  },
}


